import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import React from "react";
import { useField } from "react-final-form";
import { get } from "lodash";

const defaultFilterOptions = createFilterOptions();
export default function FormAutocomplete({ name, label, optionValueKey, ...props }) {
	const filterOptions = (options, state) => {
		return defaultFilterOptions(options, state).slice(0, 9);
	};

	const {
		input: { onChange, onFocus, onBlur, ...input },
		meta,
	} = useField(name);
	return (
		<Autocomplete
			disablePortal
			{...props}
			filterOptions={filterOptions}
			onFocus={onFocus}
			onBlur={onBlur}
			maxTags={2}
			onChange={(_, val) => {
				const value = get(val, optionValueKey, null);
				if (optionValueKey) return onChange(value);
				return onChange(val);
			}}
			renderInput={(params) => <TextField id={name} {...input} {...params} label={label} error={meta.error && meta.touched} helperText={meta?.error} />}
		/>
	);
}
