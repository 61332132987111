import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const sendLogin = createAsyncThunk("session/login",
    async ({ email, password }) => {
        const res = await fetch(`${process.env.REACT_APP_API_URI}/api/auth/login`, {
            method: "POST",
            body: JSON.stringify({ email, password }),
            headers: { "Content-Type": "application/json" }
        })
        if (res.ok) return await res.json()
    }
)

const sessionSlice = createSlice({
    name: 'session',
    initialState: {
        token: window.localStorage.getItem("sessionToken"),
    },
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload
        },
        logout: (state) => {
            state.token = ""
        }
    },
    extraReducers: builder => builder.addCase(sendLogin.fulfilled,
        (state, action) => {
            if (action.payload?.sessionToken) {
                state.token = action.payload.sessionToken
                window.localStorage.setItem("sessionToken", action.payload.sessionToken)
            }
        })
})

export const selectToken = (state) => state.session.token;

export const { logout } = sessionSlice.actions
export default sessionSlice.reducer;
