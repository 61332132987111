import ModalFrame from "../../../components/form/ModalFrame";
import { Sms } from "@mui/icons-material";

import { useDisclosure } from "react-use-disclosure";
import { Stack, Tooltip, IconButton, AvatarGroup, Avatar } from "@mui/material";
import { Form } from "react-final-form";
import { gql, useLazyQuery } from "@apollo/client";
import FormInput from "../../../components/form/FormInput";
import TableIconButton from "../../../components/TableIconButton";

export default function SendSMSAction({ selectedRows = [] }) {
	const { isOpen, open, close } = useDisclosure(false);

	const GQLSendSMS = gql`
		query sendSMS($users: [Int], $body: String!) {
			sendSMS(toUsersIds: $users, body: $body)
		}
	`;

	const [sendSMS] = useLazyQuery(GQLSendSMS, { variables: {}, fetchPolicy: "network-only" });

	const validate = (values) => {
		const errors = {};
		if (!values.body) errors.body = "Required";
		return errors;
	};

	return (
		<>
			<TableIconButton onClick={open} disabled={selectedRows.length < 1} icon={<Sms />} tooltip="Enviar SMS" />
			<Form
				onSubmit={async (values) => {
					sendSMS({ variables: values });
					close();
				}}
				initialValues={{ body: "", users: selectedRows.map((u) => u.id) }}
				validate={validate}
				render={({ handleSubmit }) => (
					<ModalFrame
						onSubmit={handleSubmit}
						{...{ isOpen, close }}
						title={
							<>
								Enviando SMS a:
								<AvatarGroup style={{ padding: "0 20px", display: "inline-flex" }} total={selectedRows.length}>
									{selectedRows
										.filter((_, i) => i < 5)
										.map((row) => (
											<Avatar alt={`${row.owner?.firstName} ${row.owner?.lastName}`} src={row.owner?.profilePicture} />
										))}
								</AvatarGroup>
							</>
						}
						buttonTexts={["Enviar", "Cancelar"]}>
						<Stack spacing={4}>
							{/* <FormAutocomplete required optionValueKey="value" options={templates} style={{ width: "100%" }} name="templateId" label="Template" type="text" autoFocus /> */}
							<FormInput style={{ width: "100%" }} name="body" label="Mensaje" type="text" autoFocus />
						</Stack>
					</ModalFrame>
				)}
			/>
		</>
	);
}
