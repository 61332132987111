import { useLazyQuery, useMutation } from "@apollo/client"
import { Edit } from "@mui/icons-material"
import { Modal, Box, Typography, Button, Tooltip, IconButton, MenuItem } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Form, Formik } from "formik"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { CustomSelect } from "../../../components"
import { setSnackBar } from "../../../redux/snackbar"
import { changeCommissionStatusMutation, getAllStatuses } from "../GQLQueries"
import TableIconButton from "../../../components/TableIconButton"

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    button: {
        margin: 1
    },
    field: {
        display: "block",
        margin: 1,
        marginBottom: 20
    }
}));

export default function ChangeCommissionStatus({ selectedRows }) {

    const classes = useStyles()
    const dispatch = useDispatch()
    const [modal, setModal] = useState(false)
    const [ChangeStatus] = useMutation(changeCommissionStatusMutation)
    const [runQuery, { data }] = useLazyQuery(getAllStatuses, { fetchPolicy: "network-only" });

    useEffect(() => {
        runQuery()
    }, [runQuery])

    const onSubmit = async ({ newStatus }, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        const res = await ChangeStatus({ variables: { commissionId: selectedRows[0].id, newStatus } })
        if (res.data.changeCommissionStatus) dispatch(setSnackBar({ text: "Se cambio el estado correctamente." }))
        else dispatch(setSnackBar({ text: "Ocurrio un error al cambiar el estado de la comision.", severity: "error" }))
        resetForm();
        setModal(false)
    }
    return <>
        <TableIconButton disabled={selectedRows.length !== 1} onClick={() => { setModal(true) }} icon={<Edit />} tooltip="Cambiar Estado" />
        <Modal open={modal} onClose={() => setModal(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "50%", bgcolor: 'background.paper', borderRadius: 1, boxShadow: 24, p: 4, }}>
                <Formik
                    initialValues={{ title: "", subtitle: "", ids: selectedRows.map(row => row.id || null).filter(r => !!r) }}
                    onSubmit={(onSubmit)}>
                    {({ values: { title, newStatus }, handleChange, handleBlur, isSubmitting }) => {
                        const fieldProps = { className: classes.field, onChange: handleChange, onBlur: handleBlur, fullWidth: true }
                        return <Form style={{ padding: 20 }}>
                            <Typography color="textPrimary" variant="h3">Cambiar estado de la comision: {selectedRows[0].id}</Typography>
                            <div style={{ width: "100%", marginTop: "20px", position: "relative" }}>
                                <CustomSelect containerStyle={{ width: "50%" }} {...fieldProps} label="Nuevo Estado" name="newStatus" value={newStatus || selectedRows[0].status}>
                                    {data?.getCommissionStatuses.map((col, index) => <MenuItem key={index} value={col}>{col}</MenuItem>)}
                                </CustomSelect>
                            </div>
                            <div style={{ width: "100%", marginTop: "20px", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
                                <Button disabled={isSubmitting} size="large" type="submit" variant="contained">Enviar</Button>
                            </div>
                        </Form>
                    }}
                </Formik >
            </Box>
        </Modal>
    </>
}