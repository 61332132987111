import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale'
import { Box, Card, Typography, Grid, } from '@mui/material';
import { CustomDataGrid } from '../../../components';
import { getAllProviderChips } from '../GQLQueries';
import { useLazyQuery } from '@apollo/client';
import customFilterPanelForTable from '../../../components/customFilterPanelForTable';
import AddForm from './AddForm';
// import DeleteConfirmation from './DeleteConfirmation';
// import EditForm from './EditForm';
import { severityPillRender } from '../../../components/customDataGrid/utils';
import { get } from 'lodash';
import EditForm from './EditForm';

export const CustomerListResults = (props) => {

  const [runQuery, { data: { chips = [] } = {}, loading }] = useLazyQuery(getAllProviderChips, { fetchPolicy: "network-only" });

  const loadData = () => { runQuery() }
  useEffect(loadData, [runQuery])

  const isoDateRenderer = field => ({
    type: "date",
    customValue: (row) => get(row, field),
    customRender: (row) => format(new Date(get(row, field)), "dd/MM/yyyy HH:mm", { locale: es })
  })
  // const dateRenderer = field => (format(new Date(Number(row.createdAt)), 'dd/MM/yyyy HH:mm', { locale: es }))
  // const dateStringValue = row => format(new Date(Number(row.createdAt)), "yyyy-MM-dd'T'HH:mm:ss.mmm'Z'", { locale: es })

  return (<>
    <Grid container spacing={3} >
      <Grid {...{ item: true, xl: 8 }} ><Typography sx={{ m: 1 }} variant="h4">Chips disponibles</Typography></Grid>
    </Grid>
    <Card {...props}>
      <Box sx={{ minWidth: 1050 }}>
        <CustomDataGrid
          initialFilters={[]}
          reload={loadData}
          loading={loading}
          filterPanel={customFilterPanelForTable}
          // actions={[AddForm, EditForm, DeleteConfirmation]}
          actions={[AddForm, EditForm]}
          hideFooterSelectedRowCount
          checkboxSelection
          rows={chips}
          showFooter={false}
          columns={[
            { id: "id", label: "ID", type: "number", hide: true },
            { id: "title", label: "Titulo" },
            { id: "enabled", label: "Habilitado", type: "boolean", customRender: (row) => severityPillRender("" + row.enabled, row.enabled ? "#4caf50" : "#f44336") },
            { id: "createdAt", label: "Fecha de creación", ...isoDateRenderer('createdAt') },
            { id: "updatedAt", label: "Fecha de actualización", ...isoDateRenderer('updatedAt') },
          ]} />
      </Box>
    </Card>
  </>);
};