import { useLazyQuery } from '@apollo/client';
import { Avatar, Box, Card, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { get } from "lodash";
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CustomDataGrid, CustomDateRangePicker } from '../../../components';
import CustomFilterPanelForTable from '../../../components/customFilterPanelForTable';
import { getInitials } from '../../../helpers/name';
import { tableCategorySbucategoryRender, tableTaskStatusRender, tableTaskStatusStringValue } from '../../../helpers/tableRenders';
import { selectCompleteDate } from '../../../redux/layout';
import SendPushAction from '../../providersList/components/SendPushAction';
import { getAllBudgets } from '../GQLQueries';
import { WhatsappActionFactory } from '../../../components/SendWhatsappAction';

export const CustomerListResults = (props) => {
  const [dateFrom, dateTo] = useSelector(selectCompleteDate);
  const [runQuery, { error, data, loading }] = useLazyQuery(getAllBudgets, { fetchPolicy: "network-only" });
  const rows = data?.getAllBudgets || []

  const loadData = () => {
    if (dateFrom && dateTo) {
      runQuery({ variables: { dateFrom, dateTo } })
    }
  }

  useEffect(loadData, [dateFrom, dateTo, runQuery])

  const avatarRenderer = (user) => {
    return <Box sx={{ alignItems: 'center', display: 'flex' }}>
      <Avatar src={user.profilePicture} sx={{ mr: 2 }}>{getInitials(`${user.firstName} ${user.lastName}`)}</Avatar>
      <Typography color="textPrimary" variant="body1">{user.firstName} {user.lastName}</Typography>
    </Box>
  }
  const dateRenderer = row => format(new Date(Number(row.createdAt)), 'dd/MM/yyyy HH:mm', { locale: es })
  const dateStringValue = row => format(new Date(Number(row.createdAt)), "yyyy-MM-dd'T'HH:mm:ss.mmm'Z'", { locale: es })
  const isHired = row => get(row, "hired") ? "Si" : "No"
  const isQualified = row => get(row, "qualification") ? "Si" : "No"

  if (error) return JSON.stringify(error)

  const userIdsMapper = (selectedRows) => selectedRows.map((row) => row.owner?.id);

  return (<>

    <Stack direction='row' justifyContent='space-between'>
      <Typography sx={{ m: 1 }} variant="h4">Reporte de presupuestos</Typography>
      <CustomDateRangePicker />
    </Stack>
    <Card {...props}>
      <Box sx={{ minWidth: 1050 }}>
        <CustomDataGrid
          filterPanel={CustomFilterPanelForTable}
          reload={loadData}
          loading={loading}
          actions={[
            WhatsappActionFactory(userIdsMapper, { tooltip: "Enviar Whatsapp al experto" }),
            SendPushAction
          ]}
          hideFooterSelectedRowCount
          checkboxSelection
          label=""
          rows={rows}
          columns={[
            { id: "id", label: "ID", type: "number", hide: true },
            { id: "task.id", label: "ID Tarea", type: "number", hide: true },
            { id: "task.title", label: "Titulo de la tarea" },
            { id: "status", label: "Estado", customValue: row => tableTaskStatusStringValue(row.task), customRender: row => tableTaskStatusRender(row.task) },
            { id: "task.owner.id", label: "ID Usuario", type: "number", hide: true },
            { id: "task.owner", label: "Usuario", customValue: row => `${get(row, "task.owner.firstName")} ${get(row, "task.owner.lastName")}`, customRender: row => avatarRenderer(row.task.owner) },
            { id: "owner.id", label: "ID Experto", type: "number", hide: true },
            { id: "owner", label: "Experto", customValue: row => `${get(row, "owner.firstName")} ${get(row, "owner.lastName")}`, customRender: row => avatarRenderer(row.owner) },
            { id: "category/subcategory", label: "Categoria", customValue: row => tableCategorySbucategoryRender(row.task), customRender: row => tableCategorySbucategoryRender(row.task) },
            { id: "amount", label: "Importe", type: "number" },
            { id: "availabilities", label: "Disponibilidad", customValue: row => row.availabilities.join(" - "), customRender: row => row.availabilities?.join(" - ") },
            { id: "comment", label: "Comentario" },
            { id: "createdAt", label: "Fecha de creación", type: "date", customValue: dateStringValue, customRender: dateRenderer },
            { id: "hired", label: "Contratado", customValue: isHired, customRender: isHired },
            { id: "qualified", label: "Calificado", customValue: isQualified, customRender: isQualified },
            { id: "warrantyMonths", label: "Garantia", type: "number", customRender: row => row.warrantyMonths ? `${row.warrantyMonths} meses` : "Sin garantia" },
          ]} />
      </Box>
    </Card>
  </>);
};