import ModalFrame from "../../../components/form/ModalFrame";
import { MarkUnreadChatAlt } from "@mui/icons-material";

import { useDisclosure } from "react-use-disclosure";
import { Stack, Tooltip, IconButton, AvatarGroup, Avatar } from "@mui/material";
import { Form } from "react-final-form";
import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../../../redux/snackbar";
import FormInput from "../../../components/form/FormInput";
import TableIconButton from "../../../components/TableIconButton";

export default function SendPushAction({ selectedRows = [] }) {
	const { isOpen, open, close } = useDisclosure(false);
	const dispatch = useDispatch();

	const GQLSendPush = gql`
		mutation sendNotifications($ids: [Int]!, $title: String!, $subtitle: String!) {
			sendCustomNotification(ids: $ids, title: $title, subtitle: $subtitle)
		}
	`;

	const [SendPushNotifications] = useMutation(GQLSendPush, { variables: {}, fetchPolicy: "network-only" });

	const validate = (values) => {
		const errors = {};
		if (!values.title) errors.title = "Required";
		return errors;
	};

	return (
		<>
			<TableIconButton onClick={open} disabled={selectedRows.length < 1} icon={<MarkUnreadChatAlt />} tooltip="Enviar notificacion Push" />
			<Form
				onSubmit={async ({ title, subtitle, ids }) => {
					const { data: { sendCustomNotification } = {} } = await SendPushNotifications({ variables: { title, subtitle: subtitle || " ", ids } });
					close();
					if (sendCustomNotification) dispatch(setSnackBar({ text: "Notificacion enviada correctamente." }));
					else dispatch(setSnackBar({ text: "Notificacion no enviada.", severity: "error" }));
				}}
				initialValues={{ title: "", subtitle: "", ids: selectedRows.map((row) => row.owner.id || null).filter((r) => !!r) }}
				validate={validate}
				render={({ handleSubmit }) => (
					<ModalFrame
						onSubmit={handleSubmit}
						{...{ isOpen, close }}
						title={
							<>
								Enviando notificaciones a:
								<AvatarGroup style={{ padding: "0 20px", display: "inline-flex" }} total={selectedRows.length}>
									{selectedRows
										.filter((_, i) => i < 5)
										.map((row) => (
											<Avatar alt={`${row.owner?.firstName} ${row.owner?.lastName}`} src={row.owner?.profilePicture} />
										))}
								</AvatarGroup>
							</>
						}
						buttonTexts={["Enviar", "Cancelar"]}>
						<Stack spacing={4}>
							<FormInput autoFocus required label="Título" name="title" />
							<FormInput label="Subtitulo" name="subtitle" />
						</Stack>
					</ModalFrame>
				)}
			/>
		</>
	);
}
