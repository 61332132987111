import { gql } from "@apollo/client"

export const getAllCommissions = gql`query($dateFrom: String!, $dateTo: String!) {
    getAllCommissions(dateFrom: $dateFrom, dateTo: $dateTo ) {
    id
    status
    budget {
        amount
        hired
        task {
            id
            title
            paid
            owner {
                id
                firstName
                lastName
                profilePicture
            }
        }
        id
        owner {
            id
            firstName
            lastName
            profilePicture
        }
    }
    amount
}
}`

export const getAllStatuses = gql`query {
    getCommissionStatuses
}`



export const changeCommissionStatusMutation = gql`mutation($commissionId: Int!, $newStatus: String!){
    changeCommissionStatus(commissionId: $commissionId, newStatus: $newStatus){
        id
    }
}`