import { gql, useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";
import SideTabs from "../../components/SideTabs";
import TaskInformation from "./components/TaskInformation";
import BudgetList from "./components/BudgetList";

export default function TaskDetail(params, props) {
	const routeParams = useParams();

	const GET_COMPLETE_TASK = gql`
		query getCompleteTask($id: Int!) {
			task: getCompleteTask(id: $id) {
				id
				title
				description
				status
				ownerId
				media {
					id
					uri
				}
				address {
					lat
					lng
					inline
					street
					number
					state
					locality
				}
				budgets {
					id
					amount
					comment
					createdAt
					hired
					warrantyMonths
					owner {
						id
						firstName
						lastName
						profilePicture
					}
				}
			}
		}
	`;

	const { data: { task = {} } = {}, error, refetch } = useQuery(GET_COMPLETE_TASK, { fetchPolicy: "network-only", variables: { id: parseInt(routeParams.id) } });

	return (
		<SideTabs
			tabs={[
				{ title: "Informacion de la tarea", component: <TaskInformation task={task} /> },
				{ title: "Presupuestos Recibidos", component: <BudgetList task={task} budgets={task.budgets} refresh={refetch} /> },
			]}
		/>
	);
}

const styles = {
	container: {
		// backgroundColor: "green",
	},
	avatar: {
		width: 80,
		height: 80,
		borderRadius: "10%",
		shadow: 2,
	},
};
