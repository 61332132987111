import { Avatar, Button, Grid } from "@mui/material";
import React from "react";
import Attribute from "./Attribute";
import { gql, useMutation } from "@apollo/client";
import { setSnackBar } from "../../../redux/snackbar";
import { useDispatch } from "react-redux";

export default function ProviderApplications({ pkg = {}, refresh }) {
	const dispatch = useDispatch();

	const setAmountPoints = (amount = 0) => {
		// transform 123456789 to 123.456.789
		return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	};

	const GQLAssignPackage = gql`
		mutation assignPackage($packageId: Int!, $packageApplicationId: Int!) {
			assignPackage(packageId: $packageId, packageApplicationId: $packageApplicationId)
		}
	`;

	const [assignPackage] = useMutation(GQLAssignPackage, { fetchPolicy: "network-only" });

	return (
		<div
			style={{
				alignItems: "center",
				overflowY: "scroll",
				height: "calc(90vh - 40px)",
			}}>
			{!pkg?.applications?.length ? (
				<h3>Esta tarea aun no ha recibido presupuestos</h3>
			) : (
				pkg?.applications?.map((application) => {
					return (
						<div style={styles.taskContainer}>
							<img alt={application.owner?.id} src={application.owner?.profilePicture} style={styles.taskMedia} />
							<Grid container mr={1}>
								<Grid xs={6} style={styles.budgetInfo}>
									<h3>
										{application.owner?.firstName} {application.owner?.lastName} {application.owner?.verified && "✅"}
									</h3>

									<p style={{ whiteSpace: "break-spaces" }}>{application.comment}</p>
									<p style={{ whiteSpace: "break-spaces" }}>{application.owner?.adminNotes}</p>
									<div style={styles.chipContainer}>{!!application.hired && <div style={styles.chip()}>Contratado</div>}</div>
								</Grid>
								<Grid item sx={6} style={styles.budgetInfo}>
									<Attribute label="Monto" value={`$${setAmountPoints(application?.price)}`} />
									<Attribute label="ID del presupuesto" value={application.id} />
									<Attribute label="ID del experto" value={application.owner?.id} />
									<Attribute label="Asignadas este mes" value={application.asignedApplicationsCount} />
								</Grid>
								{pkg.status === "PUBLISHED" && (
									<Grid
										xs={12}
										style={{
											display: "flex",
											justifyContent: "flex-end",
											padding: "10px",
										}}>
										<Button
											variant="contained"
											color="primary"
											onClick={async () => {
												const { data = {}, errors } = await assignPackage({ variables: { packageId: pkg.id, packageApplicationId: application.id } });
												refresh();
												// if (errors) dispatch(setSnackBar({ text: "Ocurrio un error al contratar el presupuesto", severity: "error" }));
											}}>
											Asignar
										</Button>
									</Grid>
								)}
							</Grid>
						</div>
					);
				})
			)}
		</div>
	);
}

const styles = {
	taskContainer: {
		display: "flex",
		marginBottom: "10px",
		flexDirection: "row",
		justifyContent: "space-between",
		borderRadius: "10px",
		overflow: "hidden",
		backgroundColor: "#5048E530",
		// alignItems: "center",
	},
	taskMedia: {
		width: "150px",
		height: "150px",
		// resize modes: "cover", "contain", "stretch", "repeat", "center"
		objectFit: "cover",
		// backgroundColor: "cover",
	},
	budgetInfo: {
		// backgroundColor: "green",
		padding: "10px",
		flex: 1,
		marginLeft: "10px",
	},
	chipContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		// backgroundColor: "blue",
	},
	chip: (backgroundColor = "#90DF30") => ({
		backgroundColor,
		padding: "4px",
		fontSize: "12px",
		borderRadius: "4px",
		marginRight: "5px",
	}),
};
