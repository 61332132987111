import ModalFrame from '../../../components/form/ModalFrame';
import { Add as AddIcon } from '@mui/icons-material'

import { useDisclosure } from "react-use-disclosure";
import { Stack, Tooltip, IconButton } from "@mui/material"
import { Form } from 'react-final-form'
import { useMutation } from '@apollo/client';
import { add } from '../GQLQueries';
import FormInput from '../../../components/form/FormInput';
import FormInputHTMLEditor from '../../../components/form/FormInputHTMLEditor';
import FormSwitch from '../../../components/form/FormSwitch';
import TableIconButton from '../../../components/TableIconButton';



export default function AddForm() {
    const { isOpen, open, close } = useDisclosure(false);

    const [Add] = useMutation(add, { fetchPolicy: "network-only" });

    const validate = (values) => {
        const errors = {};
        // if (!values.name) errors.name = 'Required';
        return errors;
    }

    return (<>
        <TableIconButton onClick={open} icon={<AddIcon />} tooltip='Nuevo registro' />
        <Form
            onSubmit={async (values) => {
                await Add({ variables: values })
                close()
            }}
            initialValues={{ code: '', description: '', enabled: true }}
            validate={validate}
            render={({ handleSubmit, form, errors, submitting, pristine, values }) => (
                <ModalFrame onSubmit={handleSubmit} {...{ isOpen, close }} title='Nuevo' buttonTexts={["Guardar", "Cancelar"]}>
                    <Stack spacing={4}>
                        <Stack spacing={4} direction='row' justifyContent='space-between'>
                            <FormInput style={{ width: '100%' }} name='code' label='Codigo' type='text' autoFocus />
                            <FormSwitch name="enabled" label="Habilitado" />
                        </Stack>
                        <FormInput name='description' label='Descripcion' type='text' />
                        <FormInputHTMLEditor name='details' label='Como funciona' />
                    </Stack>
                </ModalFrame>
            )} />
    </>
    )
}
