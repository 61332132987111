import React from 'react';
import { Box, Container } from '@mui/material';
import { CustomerListResults } from './components/customer-list-results';

export default function ProvidersList() {
    return <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
            <Box sx={{ mt: 3 }}>
                <CustomerListResults />
            </Box>
        </Container>
    </Box>
}