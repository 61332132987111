import { gql } from "@apollo/client"

export const getAllUsers = gql`query($dateFrom: String!, $dateTo: String!) {
    getAllUsers(dateFrom: $dateFrom, dateTo: $dateTo ) {
        id
        firstName
        lastName
        profilePicture
        email
        phone
        createdAt
        receibedBudgets
        receibedBudgetsSeen
        createdTasksCount
        deletedTasksCount
        hiredTasksCount
        qualifiedTasksCount
        totalPaidTasks
        userConversationsCount
        acquisitionMethod
        address {
            locality
        }
        deletedAt
    }

}`
export const MUTATION_deleteUser = gql`mutation deleteUsers($ids: [Int]!) {
    deleteUsers(ids: $ids)
}`
export const MUTATION_deleteImage = gql`mutation deleteImage($profilePictureIds: [Int]) {
    deleteMedia(userIds: $profilePictureIds)
}`

export const QUERY_getUser = gql`query($id: Int!) {
    getUser(id: $id) {
        id
        firstName
        lastName
        profilePicture
        email
        phone
        deleted
        createdAt
        deletedAt
        disabled
    }
}`

export const MUTATION_editUser = gql`mutation edit(
    $id: Int!,
    $firstName: String,
    $lastName: String,
    $email: String,
    $phone: String,
    $deletedAt: DateTime,
    $disabled: Boolean
    ) {
    editUser(
        id: $id,
        firstName: $firstName,
        lastName: $lastName,
        email: $email,
        phone: $phone,
        deletedAt: $deletedAt,
        disabled: $disabled
        ){
        id
    }
}`
