import { gql } from "@apollo/client"

export const getFilters = gql`query($reportPath: String) {
    getUserFilters(reportPath: $reportPath) {
        id
        reportPath
        filters
        columns
        name
        ownerId
    }

}`

export const saveFilters = gql`mutation($name: String!, $reportPath: String!, $filters: String!, $columns: String!, $global: Boolean!) {
    createFilter(name: $name, reportPath: $reportPath, filters: $filters, columns: $columns, global: $global) {
        id
        name
        reportPath
        filters
        columns
    }
}`

export const deleteFilterMutation = gql`mutation($filterId: Int!) {
    deleteFilter(filterId: $filterId)
}`