import { TableCell } from "@mui/material"
import { get } from 'lodash';

export const renderRow = (row, columns, labelId) => {
    try {
        const checkboxProps = { component: "th", id: labelId, scope: "row", padding: "none" }
        return columns.filter(c => !c.hide).map((col, index) => {
            let rowProps = {}
            if (index === 0) rowProps = { ...rowProps, ...checkboxProps }
            let cellContent = null;
            if (typeof col.customRender === "function") cellContent = col.customRender(row)
            else cellContent = String(get(row, col.id, col.customValue ? col.customValue(row) : ""))
            return <TableCell {...rowProps} key={`${row.id}-${col.id}-${index}`}>{cellContent}</TableCell>
        })
    } catch (error) {
        console.log(`Error rendering row: ${error}`, row)
        return <TableCell>Error</TableCell>
    }
}