import React, { useEffect } from 'react';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import DashboardDataCard from './components/DashboardDataCard';
import { useDispatch, useSelector } from 'react-redux';
import { selectCompleteDate, setTitle } from '../../redux/layout';
import { useLazyQuery } from '@apollo/client';
import { getDashboardData } from './GQLQueries';
import { AddTask, TaskAlt, Task, StarHalf, PersonAddAlt, Engineering, Delete, Chat, DoneAll } from "@mui/icons-material"
import { CustomDateRangePicker } from '../../components';

export default function Dashboard() {
    const [dateFrom, dateTo] = useSelector(selectCompleteDate);
    const dispatch = useDispatch()

    useEffect(() => dispatch(setTitle("Dashboard")), [dispatch])
    const [refreshData, { data }] = useLazyQuery(getDashboardData, { fetchPolicy: "network-only" });

    useEffect(() => {
        if (dateFrom && dateTo) {
            refreshData({ variables: { dateFrom: dateFrom, dateTo: dateTo } })
        }
    }, [dateFrom, dateTo, refreshData])

    let { getDashboardData: {
        tasksCount = 0,
        budgetedTasksCount = 0,
        hiredTasksCount = 0,
        qualifiedTasksCount = 0,
        historicTasksCount = 0,
        usersCount = 0,
        historicUsersCount = 0,
        providersCount = 0,
        historicProvidersCount = 0,
        tasksWithConverCount = 0,
        deletedTasksCount = 0,
        totalDeletedTasksCount = 0,
        taskActivities = [],
    } = {},
        getPromotionTasksActivities: promotionalCodeTasks = [] // actividad en las tareas con codigo de promocion
    } = (data || {})
    let ids = []
    const uniquePromotionalCodeTasksBudgeted = promotionalCodeTasks.filter(a => a.action === "TASK_STATUS_BUDGETED")
        .map((p) => {
            if (ids.includes(p.task.id)) return null
            ids.push(p.task.id)
            return p
        }).filter(Boolean)

    ids = []
    const uniquePromotionalCodeTasks = promotionalCodeTasks.map((p) => {
        if (ids.includes(p.task.id)) return null
        ids.push(p.task.id)
        return p
    }).filter(Boolean)
    const dashboardDataCardProps = { item: true, xl: 3, lg: 3, sm: 12, xs: 12 }

    return <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth={false}>
            <Stack direction='row' justifyContent='space-between'>
                <Typography sx={{ m: 1 }} variant="h4">Tareas nuevas</Typography>
                <CustomDateRangePicker />
            </Stack>
            <Grid container spacing={3} >
                <Grid {...{ item: true, xs: 12 }} />
                <Grid {...dashboardDataCardProps}><DashboardDataCard label="Nuevas tareas" mainValue={`${tasksCount}`} secondaryValue={`${historicTasksCount} tareas totales`} icon={AddTask} /></Grid>
                <Grid {...dashboardDataCardProps}><DashboardDataCard label="Tareas Eliminadas" mainValue={`${deletedTasksCount}`} secondaryValue={`${totalDeletedTasksCount} tareas eliminadas totales`} icon={Delete} /></Grid>

                <Grid {...dashboardDataCardProps}><DashboardDataCard label="Nuevos Usuarios" mainValue={`${usersCount}`} secondaryValue={`${historicUsersCount} usuarios totales`} icon={PersonAddAlt} /></Grid>
                <Grid {...dashboardDataCardProps}><DashboardDataCard label="Nuevos Expertos" mainValue={`${providersCount}`} secondaryValue={`${historicProvidersCount} expertos totales`} icon={Engineering} /></Grid>

                <Grid {...dashboardDataCardProps}><DashboardDataCard label="Tareas presupuestadas" mainValue={`${budgetedTasksCount}`} secondaryValue={`${tasksCount - deletedTasksCount - budgetedTasksCount} tareas sin presupuestar`} icon={TaskAlt} /></Grid>
                <Grid {...dashboardDataCardProps}><DashboardDataCard label="Tareas con Chat" mainValue={`${tasksWithConverCount || 0}`} secondaryValue={`${budgetedTasksCount - tasksWithConverCount} tareas sin chat`} icon={Chat} /></Grid>
                <Grid {...dashboardDataCardProps}><DashboardDataCard label="Tareas Contratadas" mainValue={`${hiredTasksCount}`} secondaryValue={`${budgetedTasksCount - hiredTasksCount} tareas sin contratar`} icon={Task} /></Grid>
                <Grid {...dashboardDataCardProps}><DashboardDataCard label="Tareas Calificadas" mainValue={`${qualifiedTasksCount}`} secondaryValue={`${hiredTasksCount - qualifiedTasksCount} tareas sin calificar`} icon={StarHalf} /></Grid>
                <Grid {...{ item: true, xs: 12 }}><h1>Actividad</h1></Grid>
                <Grid {...dashboardDataCardProps}><DashboardDataCard label="Presupuestos Creados" mainValue={`${taskActivities.filter(a => a.action === "TASK_STATUS_BUDGETED").length}`} secondaryValue="" icon={TaskAlt} /></Grid>
                <Grid {...dashboardDataCardProps}><DashboardDataCard label="Tareas Contratadas" mainValue={`${taskActivities.filter(a => a.action === "TASK_STATUS_HIRED").length}`} secondaryValue="" icon={Task} /></Grid>
                <Grid {...dashboardDataCardProps}><DashboardDataCard label="Tareas Finalizadas" mainValue={`${taskActivities.filter(a => a.action === "TASK_STATUS_FINALIZED").length}`} secondaryValue="" icon={DoneAll} /></Grid>
                <Grid {...dashboardDataCardProps}><DashboardDataCard label="Calificaciones" mainValue={`${taskActivities.filter(a => a.action === "TASK_STATUS_QUALIFIED").length}`} secondaryValue="" icon={StarHalf} /></Grid>
                <Grid {...{ item: true, xs: 12 }}><h1>Codigos Promocionales</h1></Grid>
                <Grid {...dashboardDataCardProps}><DashboardDataCard label="Tareas Creadas" mainValue={`${promotionalCodeTasks.filter(t => t.action === "TASK_CREATED").length}`} secondaryValue="" icon={AddTask} /></Grid>
                <Grid {...dashboardDataCardProps}><DashboardDataCard label="Tareas Eliminadas" mainValue={`${uniquePromotionalCodeTasks.filter(t => t.action === "TASK_CREATED" && t.task?.deletedAt).length}`} secondaryValue="" icon={AddTask} /></Grid>
                <Grid {...dashboardDataCardProps}><DashboardDataCard label="Tareas Presupuestadas" mainValue={`${uniquePromotionalCodeTasksBudgeted.length}`} secondaryValue="" icon={TaskAlt} /></Grid>
                <Grid {...dashboardDataCardProps}><DashboardDataCard label="Tareas Contratadas" mainValue={`${promotionalCodeTasks.filter(t => t.action === "TASK_STATUS_HIRED").length}`} secondaryValue="" icon={Task} /></Grid>
                <Grid {...dashboardDataCardProps}><DashboardDataCard label="Tareas Finalizadas" mainValue={`${promotionalCodeTasks.filter(t => t.action === "TASK_STATUS_FINALIZED").length}`} secondaryValue="" icon={DoneAll} /></Grid>
                <Grid {...dashboardDataCardProps}><DashboardDataCard label="Tareas Calificadas" mainValue={`${promotionalCodeTasks.filter(t => t.action === "TASK_STATUS_QUALIFIED").length}`} secondaryValue="" icon={StarHalf} /></Grid>
            </Grid>
        </Container>
    </Box>
}