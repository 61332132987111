import { isSameDay, isBefore, isAfter, isDate } from "date-fns"

export const StringOperators = [
    { id: "string-contains", label: "Contiene", filterFunction: (cellStringValue, filterStringValue) => RegExp(filterStringValue, "i").test(cellStringValue) },
    { id: "string-notContains", label: "No contiene", filterFunction: (cellStringValue, filterStringValue) => !RegExp(filterStringValue, "i").test(cellStringValue) },
    { id: "string-equals", label: "Es igual", filterFunction: (cellStringValue, filterStringValue) => RegExp(`^${filterStringValue}$`, "i").test(cellStringValue) },
    { id: "string-notEquals", label: "No es igual", filterFunction: (cellStringValue, filterStringValue) => !RegExp(`^${filterStringValue}$`, "i").test(cellStringValue) },
    { id: "string-startsWith", label: "Empieza con", filterFunction: (cellStringValue, filterStringValue) => RegExp(`^${filterStringValue}`, "i").test(cellStringValue) },
    { id: "string-endsWith", label: "Termina con", filterFunction: (cellStringValue, filterStringValue) => RegExp(`${filterStringValue}$`, "i").test(cellStringValue) },
    { id: "string-isEmpty", label: "Es vacio", filterFunction: cellStringValue => !cellStringValue, disableValue: true },
    { id: "string-notEmpty", label: "No es vacio", filterFunction: cellStringValue => !!cellStringValue, disableValue: true },
]

export const DateOperators = [
    { id: "date-is", label: "Es", filterFunction: (cellDateValue, filterDateValue) => isSameDay(new Date(cellDateValue), new Date(filterDateValue)) },
    { id: "date-notIs", label: "No es", filterFunction: (cellDateValue, filterDateValue) => !isSameDay(new Date(cellDateValue), new Date(filterDateValue)) },
    { id: "date-isBefore", label: "Es antes", filterFunction: (cellDateValue, filterDateValue) => isBefore(new Date(cellDateValue), new Date(filterDateValue)) },
    { id: "date-isAfter", label: "Es despues", filterFunction: (cellDateValue, filterDateValue) => isAfter(new Date(cellDateValue), new Date(filterDateValue)) },
    { id: "date-isEmpty", label: "Es vacio", filterFunction: (cellDateValue, filterDateValue) => !cellDateValue || isDate(cellDateValue), disableValue: true },
    { id: "date-notEmpty", label: "No es vacio", filterFunction: cellDateValue => !!cellDateValue && !isDate(cellDateValue), disableValue: true },
]

export const NumberOperators = [
    { id: "number-eq", label: "Es igual", filterFunction: (cellNumberValue, filterNumberValue) => cellNumberValue === filterNumberValue },
    { id: "number-ne", label: "No es igual", filterFunction: (cellNumberValue, filterNumberValue) => cellNumberValue !== filterNumberValue },
    { id: "number-gt", label: "Mayor que", filterFunction: (cellNumberValue, filterNumberValue) => cellNumberValue > filterNumberValue },
    { id: "number-gte", label: "Mayor o igual que", filterFunction: (cellNumberValue, filterNumberValue) => cellNumberValue >= filterNumberValue },
    { id: "number-lt", label: "Menor que", filterFunction: (cellNumberValue, filterNumberValue) => cellNumberValue < filterNumberValue },
    { id: "number-lte", label: "Menor o igual que", filterFunction: (cellNumberValue, filterNumberValue) => cellNumberValue <= filterNumberValue },
    { id: "string-empty", label: "Es vacio", filterFunction: cellNumberValue => !cellNumberValue && isNaN(cellNumberValue), disableValue: true },
    { id: "number-notEmpty", label: "No es vacio", filterFunction: cellNumberValue => !isNaN(cellNumberValue), disableValue: true },
]

export const BooleanOperators = [
    { id: "boolean-true", label: "Es verdadero", filterFunction: (cellBooleanValue) => !!cellBooleanValue, disableValue: true },
    { id: "boolean-false", label: "Es falso", filterFunction: (cellBooleanValue) => !cellBooleanValue, disableValue: true },
]

export const getOperators = (columnType = "string") => {
    if (columnType === "date") return DateOperators
    if (columnType === "number") return NumberOperators
    if (columnType === "boolean") return BooleanOperators
    return StringOperators
}

export const findOperator = (columnType, operatorId) => getOperators(columnType).find(op => op.id === operatorId) || getOperators(columnType)[0]