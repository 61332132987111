import { Add, Edit } from "@mui/icons-material";
import ModalFrame from "../../../components/form/ModalFrame";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Stack } from "@mui/material";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useDisclosure } from "react-use-disclosure";
import TableIconButton from "../../../components/TableIconButton";
import FormInput from "../../../components/form/FormInput";
import FormSubcategorySelect from "../../../components/form/FormSubcategorySelect";
import FormSelect from "../../../components/form/Select";
import { TableContext } from "../../../components/customDataGrid";
import { useContext, useEffect, useRef } from "react";
import ModalForm from "../../../components/form/ModalForm";
import useSnackbar from "../../../hooks/useSnackbar";
import FormCategorySelect from "../../../components/form/FormCategorySelect";
import ImgUploader from "../../../components/form/ImageUploader";
import FormAcquisitionMethodSelect from "../../../components/form/FormAcquisitionMethodSelect";
import BundleTaskForm from "./BundleTaskForm";

const GQLGetBundleTask = gql`
	query getBundleTask($id: Int!) {
		bundleTask: getBundleTask(id: $id) {
			id
			taskData
		}
	}
`;
const GQLSaveBundleTask = gql`
	mutation saveBundleTask($id: Int, $taskData: JSON!) {
		bundleTaskSaved: saveBundleTask(id: $id, taskData: $taskData)
	}
`;

const mapBundleTaskToForm = (bundleTask) => ({
	title: bundleTask?.taskData?.title,
	description: bundleTask?.taskData?.description,
	categoryId: bundleTask?.taskData?.categoryId,
	subcategoryId: bundleTask?.taskData?.subcategoryId,
	price: bundleTask?.taskData?.price,
	totalPrice: bundleTask?.taskData?.totalPrice,
	userTerms: bundleTask?.taskData?.userTerms,
	providerTerms: bundleTask?.taskData?.providerTerms,
	acquisitionMethodId: bundleTask?.taskData?.acquisitionMethodId,
	image: bundleTask?.taskData?.media?.[0]?.uri,
});

export default function CreateAction({ selectedRows = [] }) {
	const formRef = useRef(null);
	const { reload } = useContext(TableContext);
	const setSnackbar = useSnackbar();
	const isEdit = selectedRows.length === 1;

	const [getBundleTask, { data: { bundleTask = {} } = {} }] = useLazyQuery(GQLGetBundleTask, { fetchPolicy: "network-only" });

	useEffect(() => {
		if (selectedRows[0]?.id) getBundleTask({ variables: { id: selectedRows[0]?.id } });
	}, [selectedRows]);

	const openModal = async () => {
		formRef.current.open();
		// if (selectedRows[0]?.id) await getBundleTask({ variables: { id: selectedRows[0]?.id } });
	};

	const [SaveBundleTask] = useMutation(GQLSaveBundleTask, { fetchPolicy: "network-only" });

	const onSubmit = async (formValues) => {
		const variables = {
			taskData: {
				media: [{ uri: formValues.image, mediaType: "IMAGE", thumbnail: formValues.image }],
				title: formValues.title,
				description: formValues.description,
				categoryId: formValues.categoryId,
				subcategoryId: formValues.subcategoryId,
				price: parseFloat(formValues.price),
				totalPrice: parseFloat(formValues.totalPrice),
				userTerms: formValues.userTerms,
				providerTerms: formValues.providerTerms,
				acquisitionMethodId: formValues.acquisitionMethodId,
				revenueRate: (parseFloat(formValues.revenueRate) || 0) / 100,
			},
		};
		const { data: { bundleTaskSaved = false } = {}, errors } = await SaveBundleTask({ variables });
		if (errors || !bundleTaskSaved) setSnackbar("Error al crear.", "error");
		else if (bundleTaskSaved) {
			setSnackbar("Creacion exitosa.");
			// close();
			reload();
		}
	};

	const validate = (values) => {
		let errors = {};
		if (!values.title) errors.title = "Required";
		return errors;
	};

	return (
		<>
			<TableIconButton onClick={openModal} icon={<Add />} tooltip={"Crear nuevo paquete"} />
			<BundleTaskForm ref={formRef} onSubmit={onSubmit} validate={validate} />
		</>
	);
}
