import ModalFrame from "../../../components/form/ModalFrame";
import { Add as AddIcon } from "@mui/icons-material";

import { useDisclosure } from "react-use-disclosure";
import { Stack, Tooltip, IconButton } from "@mui/material";
import { Form } from "react-final-form";
import { useMutation } from "@apollo/client";
import { add } from "../GQLQueries";
import FormInput from "../../../components/form/FormInput";
import FormSwitch from "../../../components/form/FormSwitch";
import TableIconButton from "../../../components/TableIconButton";

export default function AddForm() {
	const { isOpen, open, close } = useDisclosure(false);

	const [Add] = useMutation(add, { fetchPolicy: "network-only" });

	const validate = (values) => {
		const errors = {};
		// if (!values.name) errors.name = 'Required';
		return errors;
	};

	return (
		<>
			<TableIconButton onClick={open} icon={<AddIcon />} tooltip="Nuevo registro" />
			<Form
				onSubmit={async (values) => {
					await Add({ variables: values });
					close();
				}}
				initialValues={{
					enabled: true,
					title: { es: "", en: "", pt: "" },
				}}
				validate={validate}
				render={({ handleSubmit, form, errors, submitting, pristine, values }) => (
					<ModalFrame onSubmit={handleSubmit} {...{ isOpen, close }} title="Nuevo chip de experto" buttonTexts={["Guardar", "Cancelar"]}>
						<Stack spacing={2} direction={{ xs: "column", sm: "row" }} justifyContent="space-between">
							<FormInput name="title.es" label="Título en español" />
							<FormInput name="title.en" label="Título en inglés" />
							<FormInput name="title.pt" label="Título en portugués" />
							<FormSwitch name="enabled" label="Habilitado" />
						</Stack>
					</ModalFrame>
				)}
			/>
		</>
	);
}
