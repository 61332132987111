import { Collapse, Icon, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactDOM from "react-dom";

export default function ExpandibleContainer({ title, children }) {
	const [expanded, setExpanded] = useState(false);
	const toggleExpanded = () => setExpanded((e) => !e);

	return (
		<div
			style={{
				display: "flex",
				height: "100%",
				alignItems: "center",
			}}>
			<Collapse style={styles.collapseContainer} in={expanded} orientation="horizontal">
				<div style={{ display: "flex", overflow: "hidden", justifyContent: "center" }}>{children}</div>
			</Collapse>
			<IconButton
				style={{
					transform: `rotate(${expanded ? 180 : 0}deg)`,
					transition: "transform 0.5s",
				}}
				onClick={toggleExpanded}>
				<ArrowForwardIosIcon />
			</IconButton>
		</div>
	);
}

const styles = {
	hiddenContent: {
		paddingHorizontal: "10px",
		position: "absolute",
		height: "0px",
		overflow: "hidden",
	},
	collapseContainer: {
		display: "flex",
		flexDirection: "row",
		transition: "width 0.5s",
	},
	whiteOverlay: {
		position: "absolute",
		backgroundColor: "white",
		width: "100%",
		height: "100%",
		zIndex: 1,
	},
};
