import { IconButton, Tooltip } from "@mui/material";

export default function TableIconButton({ icon, onClick, disabled, tooltip, ...rest }) {
	return (
		<Tooltip title={tooltip} rounded={5} fontSize="sm">
			<span>
				<IconButton onClick={onClick} disabled={disabled} {...rest}>
					{icon}
				</IconButton>
			</span>
		</Tooltip>
	);
}
