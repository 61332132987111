import { get } from "lodash";
import { SeverityPill } from "../../screens/dashboardTemplate/components/severity-pill";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const getvalueForComparator = (row, column) => {
    let value = "";
    if (typeof column.customRender === "function") value = column.customValue ? column.customValue(row) : get(row, column.id, column.customRender(row))
    else value = get(row, column.id, "")
    return value
}

const descendingComparator = (a, b, orderBy) => {
    const aValue = getvalueForComparator(a, orderBy)
    const bValue = getvalueForComparator(b, orderBy)
    if (bValue < aValue) return -1;
    if (bValue > aValue) return 1;
    return 0;
}

export const getComparator = (order, orderBy) => {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}



// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


export const stringToColour = function (str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}


export const severityPillRender = (label = "", color) => <SeverityPill style={{ backgroundColor: color ? color : stringToColour(label) }}>{label}</SeverityPill>
