import { Add as AddIcon } from "@mui/icons-material";

import { useRef } from "react";
import TableIconButton from "../../../components/TableIconButton";
import AcquisitionMethodForm from "./AcquisitionMethodForm";

export default function AddForm({reload}) {
	const formRef = useRef(null);
	return (
		<>
			<TableIconButton icon={<AddIcon />} onClick={formRef?.current?.open} tooltip="Nuevo Registro" />
			<AcquisitionMethodForm ref={formRef} afterSubmit={reload}/>
		</>
	);
}
