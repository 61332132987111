import { createSlice } from '@reduxjs/toolkit'

const counterSlice = createSlice({
    name: 'layout',
    initialState: {
        drawer: true,
        title: "Home",
        drawerRoutes: [],
        rangeValue: [0, 0],
        fromDateFilter: new Date(new Date().setHours(0, 0, 0, 0)),
        toDateFilter: new Date(new Date().setHours(23, 59, 59, 999))
    },
    reducers: {
        addDrawerRoute: (state, action) => {
            state.drawerRoutes.push(action.payload)
        },
        toggleDrawer: state => {
            state.drawer = !state.drawer
        },
        setTitle: (state, action) => {
            state.title = action.payload
        },

        setRangeValue: (state, action) => {
            if (action) state.rangeValue = action.payload
        },
        setDateFrom: (state, action) => {
            if (action) state.fromDateFilter = action.payload
        },
        setDateTo: (state, action) => {
            if (action) state.toDateFilter = new Date(action.payload.setHours(23, 59, 59, 999))
        }
    }
})

export const selectTitle = (state) => state.layout.title;
export const selectDrawerRoutes = (state) => state.layout.drawerRoutes;

export const selectCompleteDate = (state) => [state.layout.fromDateFilter, state.layout.toDateFilter];
export const selectDateFrom = (state) => state.layout.fromDateFilter;
export const selectDateTo = (state) => state.layout.toDateFilter;
export const selectRangeValue = (state) => state.layout.rangeValue;

export const { setTitle, setDateFrom, setDateTo, setRangeValue } = counterSlice.actions
export default counterSlice.reducer;
