import { Avatar, Grid } from "@mui/material";
import React from "react";
import Attribute from "./Attribute";
import { useNavigate } from "react-router-dom";

export default function TaskList({ tasks }) {
	const navigate = useNavigate();
	// tasks = [tasks[0]]
	return (
		<div
			style={{
				alignItems: "center",
				overflowY: "scroll",
				height: "calc(90vh - 40px)",
			}}>
			{!tasks.length ? (
				<h3>El usuario no creo tareas</h3>
			) : (
				tasks.map((task) => {
					return (
						<div style={styles.taskContainer}>
							<img alt={task.title} src={task.media[0]?.uri} style={styles.taskMedia} />
							<Grid container mr={1}>
								<Grid style={styles.taskInfo}>
									<h3>{task.title}</h3>
									<p>{task.description}</p>
									<div style={styles.chipContainer}>
										<div style={styles.chip()}>{task.status}</div>
										{!!task.promotionCode && <div style={styles.chip("red")}>{task.promotionCode?.code}</div>}
									</div>
								</Grid>
								<Grid item sx={3} style={styles.taskInfo}>
									<div
										style={{
											textAlign: "right",
											width: "100%",
										}}>
										<span
											onClick={() => navigate(`/task/${task.id}`)}
											style={{
												textAlign: "right",
												color: "blue",
												cursor: "pointer",
												fontSize: "14px",
												right: "10px",
											}}>
											Ver detalles
										</span>
									</div>
									<Attribute label="ID" value={task.id} />
									<Attribute label="Presupuestos" value={task.budgetsCount} />
								</Grid>
							</Grid>
						</div>
					);
				})
			)}
		</div>
	);
}

const styles = {
	taskContainer: {
		display: "flex",
		marginBottom: "10px",
		flexDirection: "row",
		justifyContent: "space-between",
		borderRadius: "10px",
		overflow: "hidden",
		backgroundColor: "#5048E530",
		// alignItems: "center",
	},
	taskMedia: {
		width: "150px",
		height: "150px",
		// resize modes: "cover", "contain", "stretch", "repeat", "center"
		objectFit: "cover",
		// backgroundColor: "cover",
	},
	taskInfo: {
		// backgroundColor: "green",
		padding: "10px",
		flex: 1,
		marginLeft: "10px",
	},
	chipContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		// backgroundColor: "blue",
	},
	chip: (backgroundColor = "#90DF30") => ({
		backgroundColor,
		padding: "4px",
		fontSize: "12px",
		borderRadius: "4px",
		marginRight: "5px",
	}),
};
