import React, { useState, useEffect } from "react";
import { CardMedia } from "@mui/material";

export default function CardMediaWithImgSet({ image, srcSet: originalSrcSet, ...props }) {
	const [srcSet, setSrcSet] = useState(originalSrcSet ? originalSrcSet : [image]);
	const [currentSrcIndex, setCurrentSrcIndex] = useState(0);
	useEffect(() => {
		if (JSON.stringify(originalSrcSet) !== JSON.stringify(srcSet)) {
			setSrcSet(originalSrcSet);
			setCurrentSrcIndex(0);
		}
	}, [JSON.stringify(originalSrcSet)]);


	try {
		return <CardMedia {...props} component={"img"} onError={(event) => setCurrentSrcIndex((i) => i + 1)} image={srcSet[currentSrcIndex]} alt="" />;
	} catch (e) {
		console.log(e);
		return null;
	}
}
