import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { useField } from "react-final-form";

export default function FormSelect({ name, label, options = [], ...props }) {
	const {
		input: { onChange, onFocus, onBlur, ...input },
	} = useField(name);
	return (
		<FormControl fullWidth>
			<InputLabel id={`form-${name}`}>{label}</InputLabel>
			<Select
				{...props}
				{...input}
				labelId={`form-${name}`}
				onFocus={onFocus}
				onBlur={onBlur}
				maxTags={2}
				label={label}
				renderValue={(selected) => {
					const option = options.find((option) => option?.value === selected);
					return option?.label;
				}}
				// onChange={(e) => {
				// 	onChange(e.target.value)
				// 	// console.log(_, val);
				// 	// const value = get(val, optionValueKey, null);
				// 	// if (optionValueKey) return onChange(value);
				// 	// return onChange(val);
				// }}
				// renderInput={(params) => <TextField id={name} {...input} {...params} label={label} error={meta.error && meta.touched} helperText={meta?.error} />}
			>
				{options.map((option) => (
					<MenuItem key={option?.value} value={option?.value} onClick={() => onChange(option.value)}>
						{option?.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
