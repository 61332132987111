import { Box, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import useWindowDimensions from "../hooks/useDimentions";

export default function MediaDisplayModal({ src, isVideo = false, ...props }) {
	const { width, height } = useWindowDimensions();
	const [media, setMedia] = useState(src);

	useEffect(() => {
		setMedia(src);
	}, [src]);

	const handleClose = () => {
		if (typeof props.onClose === "function") props.onClose();
		setMedia(null);
	};

	return (
		<Modal open={!!media} onClose={handleClose}>
			<Box
				onClick={handleClose}
				sx={{
					position: "absolute",
					top: 0,
					left: 0,
					height: height,
					width: width,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}>
				<Box onClick={() => {}} sx={styles.media} >
					{isVideo ? <video src={media} controls autoPlay /> : <img onClick={() => {}} src={media} />}
				</Box>
			</Box>
		</Modal>
	);
}

const styles = {
	mediaContainer: {
		height: "100%",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	media: {
		pointerEvents: "none",
		justifyContent: "center",
		// width: "80%",
		// height: "80%",
		objectFit: "contain",
	},
};
