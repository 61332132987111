import { gql, useQuery } from "@apollo/client";
import { Box, Card, Stack, Typography } from "@mui/material";
import React from "react";
import { CustomDataGrid, CustomDateRangePicker } from "../../../components";
import { severityPillRender, stringToColour } from "../../../components/customDataGrid/utils";
import CustomFilterPanelForTable from "../../../components/customFilterPanelForTable";
import { isHiddenRenderer, isoDateRenderer } from "../../../tableUtils";

import CreateAction from "./CreateAction";
import DeleteAction from "./DeleteAction";
import EditAction from "./EditAction";

export const ResultList = (props) => {
	const query = gql`
		query getAllCategories {
			categories: getAllCategories {
				id
				title {
					id
					es
					en
					pt
				}
				hidden
				subcategories {
					id
					title {
						es
						en
						pt
					}
				}
				createdAt
				deletedAt
			}
		}
	`;
	const { data: { categories = [] } = {}, loading, refetch: runQuery } = useQuery(query, { fetchPolicy: "network-only" });

	const subcategoriesRenderer = (row) => {
		return (
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				sx={{
					gap: 1,
					display: "flex",
					flexWrap: "wrap",
				}}>
				{row?.subcategories?.filter((s) => s?.title?.es).map((s) => severityPillRender(s?.title?.es, stringToColour(row?.title?.es || "")))}
			</Stack>
		);
	};

	return (
		<>
			<Stack direction="row" justifyContent="space-between">
				<Typography sx={{ m: 1 }} variant="h4">
					Listado de Categorias
				</Typography>
				<CustomDateRangePicker />
			</Stack>
			<Card {...props}>
				<Box sx={{ minWidth: 1050 }}>
					<CustomDataGrid
						filterPanel={CustomFilterPanelForTable}
						reload={runQuery}
						loading={loading}
						actions={[CreateAction, EditAction, DeleteAction]}
						hideFooterSelectedRowCount
						checkboxSelection
						label=""
						rows={categories}
						columns={[
							{ id: "id", label: "ID", type: "number" },
							{ id: "title.es", label: "Titulo" },
							{ id: "subcategories", label: "Subcategorias", customRender: subcategoriesRenderer },
							{ id: "createdAt", label: "Fecha de creacion", ...isoDateRenderer("createdAt") },
							{ id: "hidden", label: "Oculto", ...isHiddenRenderer("hidden", true) },
							{ id: "deletedAt", label: "Fecha de eliminacion", ...isoDateRenderer("deletedAt")}
						]}
					/>
				</Box>
			</Card>
		</>
	);
};
