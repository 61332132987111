import { Edit } from "@mui/icons-material";

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useContext, useEffect, useRef } from "react";
import TableIconButton from "../../../components/TableIconButton";
import { TableContext } from "../../../components/customDataGrid";
import useSnackbar from "../../../hooks/useSnackbar";
import BundleTaskForm from "./BundleTaskForm";

const GQLGetBundleTask = gql`
	query getBundleTask($id: Int!) {
		bundleTask: getBundleTask(id: $id) {
			id
			taskData
		}
	}
`;
const GQLSaveBundleTask = gql`
	mutation saveBundleTask($id: Int, $taskData: JSON!) {
		bundleTaskSaved: saveBundleTask(id: $id, taskData: $taskData)
	}
`;

const mapBundleTaskToForm = (bundleTask) => ({
	title: bundleTask?.taskData?.title,
	description: bundleTask?.taskData?.description,
	categoryId: bundleTask?.taskData?.categoryId,
	subcategoryId: bundleTask?.taskData?.subcategoryId,
	price: bundleTask?.taskData?.price,
	totalPrice: bundleTask?.taskData?.totalPrice,
	userTerms: bundleTask?.taskData?.userTerms,
	providerTerms: bundleTask?.taskData?.providerTerms,
	acquisitionMethodId: bundleTask?.taskData?.acquisitionMethodId,
	image: bundleTask?.taskData?.media?.[0]?.uri,
});

export default function EditAction({ selectedRows = [], reload }) {
	const formRef = useRef(null);
	// const { reload } = useContext(TableContext);
	const setSnackbar = useSnackbar();

	const [getBundleTask, { data: { bundleTask = {} } = {} }] = useLazyQuery(GQLGetBundleTask, { fetchPolicy: "network-only" });

	useEffect(() => {
		if (selectedRows[0]?.id) getBundleTask({ variables: { id: selectedRows[0]?.id } });
	}, [selectedRows]);

	const openModal = async () => {
		// console.log("selectedRows", selectedRows, bundleTask);
		// if (selectedRows[0]?.id) await getBundleTask({ variables: { id: selectedRows[0]?.id } });
		// console.log("selectedRows", selectedRows, bundleTask);
		formRef.current.open();
	};

	const [SaveBundleTask] = useMutation(GQLSaveBundleTask, { fetchPolicy: "network-only" });

	const onSubmit = async (formValues) => {
		console.log("formValues", formValues);
		const variables = {
			id: bundleTask?.id,
			taskData: {
				media: [{ uri: formValues.image, mediaType: "IMAGE", thumbnail: formValues.image }],
				title: formValues.title,
				description: formValues.description,
				categoryId: formValues.categoryId,
				subcategoryId: formValues.subcategoryId,
				price: parseFloat(formValues.price),
				totalPrice: parseFloat(formValues.totalPrice),
				userTerms: formValues.userTerms,
				providerTerms: formValues.providerTerms,
				acquisitionMethodId: formValues.acquisitionMethodId,
				revenueRate: (parseFloat(formValues.revenueRate) || 0) / 100,
			},
		};
		const { data: { bundleTaskSaved = false } = {}, errors } = await SaveBundleTask({ variables });
		if (errors || !bundleTaskSaved) setSnackbar("Error al crear.", "error");
		else if (bundleTaskSaved) {
			setSnackbar("Creacion exitosa.");
			// close();
			reload();
		}
	};

	const validate = (values) => {
		let errors = {};
		if (!values.title) errors.title = "Required";
		return errors;
	};
	return (
		<>
			<TableIconButton onClick={openModal} disabled={!selectedRows.length} icon={<Edit />} tooltip={"Editar Registro"} />
			<BundleTaskForm ref={formRef} initialValues={bundleTask} onSubmit={onSubmit} validate={validate} title='Editar Paquete' />
		</>
	);
}
