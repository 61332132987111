import ModalFrame from "../../../components/form/ModalFrame";
import { Edit as EditIcon } from "@mui/icons-material";

import { useDisclosure } from "react-use-disclosure";
import { Stack, Tooltip, IconButton, Typography } from "@mui/material";
import { Form } from "react-final-form";
import { useLazyQuery, useMutation } from "@apollo/client";
import { edit, QUERY_getProvider, MUTATION_deleteImage } from "../GQLQueries";
import FormInput from "../../../components/form/FormInput";
import { useContext, useEffect } from "react";
import FormCategorySelector from "../../../components/form/FormCategorySelector";
import MediaGallery from "../../../components/MediaGallery";
import FormDateInput from "../../../components/form/FormDateInput";
import { setSnackBar } from "../../../redux/snackbar";
import { useDispatch } from "react-redux";
import { TableContext } from "../../../components/customDataGrid";
import FormSwitch from "../../../components/form/FormSwitch";
import ChipSelector from "./ChipSelector";
import TableIconButton from "../../../components/TableIconButton";

export default function EditForm({ selectedRows = [] }) {
	const dispatch = useDispatch();
	const { isOpen, open, close } = useDisclosure(false);
	const { reload } = useContext(TableContext);

	const [getProvider, { data: { getProvider: provider } = {} }] = useLazyQuery(QUERY_getProvider, { fetchPolicy: "network-only" });
	const [deleteImage] = useMutation(MUTATION_deleteImage, {
		fetchPolicy: "network-only",
	});
	useEffect(() => {
		if (!provider && isOpen && selectedRows[0]?.id) {
			getProvider({ variables: { id: selectedRows[0]?.id } });
		}
	}, [isOpen, selectedRows[0]?.id]);
	const [Edit] = useMutation(edit, { fetchPolicy: "network-only" });

	const validate = (values) => {
		const errors = {};
		return errors;
	};

	let images = [];
	if (provider?.profilePicture) images.push({ id: provider?.id, url: provider?.profilePicture, type: "profilePicture" });
	if (provider?.professionalProfile?.works) images.push(...provider?.professionalProfile?.works.map((item) => ({ id: item.id, url: item.uri, type: "professionalMedia" })));
	if (provider?.professionalProfile?.certs) images.push(...provider?.professionalProfile?.certs.map((item) => ({ id: item.id, url: item.uri, type: "professionalMedia" })));

	return (
		<>
			<TableIconButton onClick={open} disabled={selectedRows.length !== 1} icon={<EditIcon />} tooltip="Editar registro" />
			<Form
				initialValues={{
					id: provider?.id,
					adminNotes: provider?.adminNotes,
					firstName: provider?.firstName,
					lastName: provider?.lastName,
					email: provider?.email,
					phone: provider?.phone,
					deletedAt: provider?.deletedAt,
					verified: provider?.verified,
					disabled: provider?.disabled,
					professionalProfile: {
						id: provider?.professionalProfile?.id,
						professionalCategories: provider?.professionalProfile?.professionalCategories.map(({ categoryId, subcategoryId }) => ({
							categoryId,
							subcategoryId,
						})),
						profileChips: provider?.professionalProfile?.profileChips?.map(({ id }) => id),
						experience: provider?.professionalProfile?.experience,
						blockDate: provider?.professionalProfile?.blockDate?.substring(0, 10),
					},
				}}
				onSubmit={async (values, actions) => {
					const { data } = await Edit({
						variables: {
							...values,
							professionalProfile: {
								id: values.professionalProfile.id,
								professionalCategories: values.professionalProfile.professionalCategories,
								experience: values.professionalProfile.experience,
								blockDate: values.professionalProfile.blockDate,
								profileChips: values.professionalProfile.profileChips,
							},
						},
					});

					if (data?.editUser)
						dispatch(
							setSnackBar({
								text: "Registro editado correctamente",
								severity: "success",
							})
						);
					else
						dispatch(
							setSnackBar({
								text: "Error al editar el registro",
								severity: "error",
							})
						);
					actions.reset({});
					close();
					reload();
				}}
				validate={validate}
				render={({ handleSubmit, values }) => (
					<ModalFrame onSubmit={handleSubmit} {...{ isOpen, close }} title={`Editar perfil de ${provider?.firstName} ${provider?.lastName}`} buttonTexts={["Guardar", "Cancelar"]}>
						<Stack spacing={4}>
							<Stack spacing={4} direction="row" justifyContent="space-between">
								<FormInput style={{ width: "100%" }} name="firstName" label="Nombres" type="text" autoFocus />
								<FormInput style={{ width: "100%" }} name="lastName" label="Apellido" type="text" />
								<FormSwitch name="verified" label="Verificado" />
								<FormSwitch name="disabled" label="Deshabilitado" />
							</Stack>
							<Stack spacing={4} direction="row" justifyContent="space-between">
								<FormInput style={{ marginTop: "auto", width: "100%" }} name="email" label="Email" type="text" />
								<FormInput style={{ marginTop: "auto", width: "100%" }} name="phone" label="Telefono" type="text" />
								<Stack spacing={0}>
									<Typography variant="h6" style={{ margin: "auto" }}>
										Fecha de bloqueo
									</Typography>
									<FormDateInput name="professionalProfile.blockDate" type="date" format={"dd/MM/yyyy"} cleareable />
								</Stack>
								<Stack spacing={0}>
									<Typography variant="h6" style={{ margin: "auto" }}>
										Fecha de eliminacion
									</Typography>
									<FormDateInput name="deletedAt" type="date" format={"dd/MM/yyyy"} cleareable /* disableFuture disablePast */ />
								</Stack>
							</Stack>
							<Stack gap={2} direction="row" justifyContent="space-between">
								<FormInput name="professionalProfile.experience" label="Experiencia" type="textarea" />
								<ChipSelector name="professionalProfile.profileChips" label="Profile Chips" />
							</Stack>
							<Stack gap={2} direction="row" justifyContent="space-between">
								{!!provider && <FormCategorySelector name="professionalProfile.professionalCategories" />}
								<MediaGallery
									handleDelete={async (variables) => {
										const { data } = await deleteImage({ variables });
										if (data?.deleteMedia) dispatch(setSnackBar({ text: "Imagen eliminada", severity: "success" }));
										else dispatch(setSnackBar({ text: "Error al eliminar la imagen", severity: "error" }));
										getProvider({ variables: { id: provider.id } });
									}}
									items={images}
								/>

								{/* <ProviderGalery handleDelete={openDelete} items={provider?.professionalProfile?.works.map((i) => ({ id: i.id, url: i.uri }))} /> */}
								{/* <ProviderGalery handleDelete={openDelete} items={provider?.professionalProfile?.certs.map((i) => ({ id: i.id, url: i.uri }))} /> */}
							</Stack>
							<FormInput name="adminNotes" label="Notas" type="textarea" />
						</Stack>
					</ModalFrame>
				)}
			/>
		</>
	);
}
