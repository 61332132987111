import { Avatar, Box, Divider, Grid, Stack, Typography } from "@mui/material";
import Attribute from "./Attribute";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useState } from "react";
import MediaDisplayModal from "../../../components/MediaDisplayModal";

export default function TaskInformation({ task = {} }) {
	const [mediaViewerSrc, setMediaViewerSrc] = useState(null);
	const lat = parseFloat(task.address?.lat);
	const lng = parseFloat(task.address?.lng);
	return (
		<>
			<Grid container>
				<Grid item xs={12} direction="row">
					<Box style={styles.carrousel}>
						{task.media?.map((media) => (
							<img onClick={() => setMediaViewerSrc(media)} src={media?.uri} alt={task?.title} style={styles.media} />
						))}
					</Box>
				</Grid>
				<Grid item xs={6} p={2}>
					<h1>{task.title}</h1>
					<p>{task.description}</p>
					<br />
					<Divider />
					<Attribute label="ID" value={task.id} />
					<Attribute label="Estado" value={task.status} />
					<Attribute label="Deshabilitado" value={task.disabled ? "Si" : "No"} />
					<Attribute label="Eliminado" value={task.deletedAt ? "Si" : "No"} />

					<Attribute label="Direccion" value={task.address?.inline} />
				</Grid>
				{task.address && (
					<Grid item xs={6} p={2}>
						<GoogleMap
							options={{
								mapId: "1ea02683e0a5f5da",
								disableDefaultUI: true,
								draggingCursor: false,
								gestureHandling: "greedy",
								clickableIcons: false,
								mapTypeControl: false,
							}}
							mapContainerStyle={{ marginTop: "10px", width: "100%", height: "300px", borderRadius: 10, border: "1px solid black" }}
							center={{ lng, lat }}
							zoom={16}>
							<Marker
								clickable={false}
								position={{
									lng: lng,
									lat: lat,
								}}
							/>
						</GoogleMap>
					</Grid>
				)}
			</Grid>
			<MediaDisplayModal src={mediaViewerSrc?.uri} isVideo={!!mediaViewerSrc?.thumbnail} onClose={() => setMediaViewerSrc(null)} />
		</>
	);
}

const styles = {
	avatar: {
		width: 80,
		height: 80,
		borderRadius: "10%",
		shadow: 2,
	},
	carrousel: {
		display: "flex",
		overflowX: "scroll",
	},
	media: {
		width: "200px",
		height: "200px",
		margin: "10px",
		objectFit: "cover",
		cursor: "pointer",
		borderRadius: "10px",
	},
};
