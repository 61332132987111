import React, { useContext, useState } from 'react';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { FilterList, Replay } from '@mui/icons-material/';
// import FilterPanel from './FilterPanel';
import { TableContext } from '..';
import FilterPanel from './FilterPanel';
import { Badge, Card, CardContent, CircularProgress, Collapse } from '@mui/material';
import ColumnsMenuButton from './ColumnsMenuButton';
import ExportDataButton from './exportDataButton';
import TableIconButton from '../../TableIconButton';

const TableToolbar = (props) => {

    const [filterPanel, setFilterPanel] = useState(false)
    const { rows, selected, filters, reload, loading, label } = useContext(TableContext);

    const { selectedActions: SelectedActionsComponent, customFilterPanel: CustomFilterPanel, actions = [] } = props;
    const numSelected = selected?.length

    return <>
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}>
            {numSelected > 0 ?
                <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">{numSelected} selected</Typography>
                : <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">{label}</Typography>}
            {!!SelectedActionsComponent && !!numSelected && <SelectedActionsComponent selectedRows={rows?.filter(r => selected.some(s => s === r.id))} />}
            {actions.map((Action, index) => <Action key={index} selectedRows={rows?.filter(r => selected.some(s => s === r.id))} reload={reload} />)}
            <ColumnsMenuButton />
            <TableIconButton onClick={() => setFilterPanel(!filterPanel)} icon={<Badge color="primary" badgeContent={filters.length}><FilterList /></Badge>} tooltip="Filtros" />
            <ExportDataButton />
            <TableIconButton disabled={loading} onClick={() => { if (typeof reload === "function") reload() }} icon={!loading ? <Replay /> : <CircularProgress size={22} />} tooltip="Recargar" />
        </Toolbar>
        <div>
            <Card>
                <Collapse in={filterPanel}>
                    <CardContent>
                        <>
                            {CustomFilterPanel ? <CustomFilterPanel /> : <FilterPanel />}
                        </>
                    </CardContent>
                </Collapse>
            </Card >
        </div>
    </>
};

export default TableToolbar