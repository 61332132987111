import { gql, useQuery } from "@apollo/client";
import { Avatar, Container, Grid } from "@mui/material";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import Attribute from "./components/Attribute";
import SideTabs from "../../components/SideTabs";
import TaskList from "./components/TaskList";
import PersonalInformation from "./components/PersonalInformation";
import EditForm from "./components/EditForm";

export default function UserDetail(params, props) {
	const routeParams = useParams();
	const navigationParams = useLocation().state;

	const GET_COMPLETE_USER = gql`
		query getCompleteUser($id: Int!) {
			user: getCompleteUser(id: $id) {
				id
				firstName
				lastName
				profilePicture
				email
				disabled
				deletedAt
				address {
					inline
					street
					number
					state
					locality
				}
			}
			createdTasks: getTasksListByUser(userId: $id) {
				id
				title
				description
				status
				createdAt
				updatedAt
				deletedAt
				budgetsCount
				promotionCode {
					id
					code
				}
				media {
					id
					uri
				}
			}
		}
	`;

	const { data: { user = [], createdTasks = [] } = {}, error } = useQuery(GET_COMPLETE_USER, { fetchPolicy: "network-only", variables: { id: parseInt(routeParams.id) } });

	return (
		<>
			<SideTabs
				tabs={[
					{ title: "Informacion Personal", component: <PersonalInformation user={user} /> },
					{ title: "Tareas Creadas", component: <TaskList tasks={createdTasks} /> },
					{ title: "Editar usuario", component: <EditForm userId={user.id} /> },
				]}
			/>
		</>
	);
}

const styles = {
	container: {
		// backgroundColor: "green",
	},
	avatar: {
		width: 80,
		height: 80,
		borderRadius: "10%",
		shadow: 2,
	},
};
