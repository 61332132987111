import { get } from "lodash";
import { format } from 'date-fns';
import { es } from 'date-fns/locale'

export default function DateRenderer(rowKey) {
	return {
		id: rowKey,
		type: "date",
		customRender: (row) => format(new Date(Number(get(row, rowKey))), "dd/MM/yyyy HH:mm", { locale: es }),
		customValue: (row) => format(new Date(Number(get(row, rowKey))), "yyyy-MM-dd'T'HH:mm:ss.mmm'Z'", { locale: es }),
	};
}
