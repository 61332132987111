import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useField } from "react-final-form";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};
const getChips = gql`
	query {
		chips: getAllProfileChips {
			id
			title
			enabled
			updatedAt
			createdAt
			deletedAt
		}
	}
`;

export default function ChipSelector(props) {
	const { input } = useField(props.name);
	const { data: { chips = [] } = {} } = useQuery(getChips);
	const theme = useTheme();
	const [selected, setSelected] = React.useState([]);
	// useEffect(() => {
	// 	if (!selected.length) setSelected(chips.filter(({ id }) => input?.value?.includes(id)));
	// }, [chips, input?.value]);

	const handleChange = (e, { props: { value } }) => {
		setSelected((v) => {
			let newValue;
			if (v.some(({ id }) => id === value.id)) newValue = v.filter(({ id }) => id !== value.id);
			else newValue = [...v, value];
			input?.onChange(newValue.map(({ id }) => id));
			return newValue;
		});
	};

	return (
		<FormControl sx={{ m: 1, width: "100%" }}>
			<InputLabel>{props.label}</InputLabel>
			<Select
				label={props.label}
				multiple
				value={selected}
				onChange={handleChange}
				// input={<OutlinedInput label={props.label} />}
				renderValue={(selected) => (
					<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
						{selected.map((chip) => (
							<Chip style={{ borderRadius: 5, backgroundColor: "#3f51b5", color: "white" }} key={chip.id} label={chip.title} />
						))}
					</Box>
				)}
				MenuProps={MenuProps}>
				{chips.map((chip) => (
					<MenuItem key={chip.title} value={chip} style={{ fontWeight: selected.some(({ id }) => chips.id === id) ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium }}>
						{chip.title}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
