import { gql, useQuery } from "@apollo/client";
import { Chip, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useField } from "react-final-form";

export default function FormCategorySelector({ name, ...props }) {
	const { data: { categories } = { categories: [] } } = useQuery(GQLgetCategories, { fetchPolicy: "network-only", variables: { lang: "es" } });

	const { input, meta } = useField(name);

	const [selectedCategories, setSelectedCategories] = useState([]);
	const [selectedSubcategories, setSelectedSubcategories] = useState([]);

	// set initial selectedCategories and selectedSubcategories
	useEffect(() => {
		if (categories?.length && meta.initial?.length && !(selectedSubcategories.length || selectedCategories.length)) {
			setSelectedCategories(categories.filter((c) => meta.initial?.some((v) => v.categoryId === c.id)));
			const flatSubcategories = categories?.flatMap((c) => c?.subCategories);
			setSelectedSubcategories(flatSubcategories.filter((s) => meta.initial?.some((v2) => v2.subcategoryId === s.id)));
		}
	}, [categories, meta.initial, selectedCategories.length, selectedSubcategories.length]);

	useEffect(() => {
		const flatCategories = selectedCategories?.filter((c) => !c?.subCategories?.length).map((c) => ({ categoryId: c.id }));
		const subcategories = selectedSubcategories?.map((s) => ({ categoryId: s.categoryId, subcategoryId: s.id }));

		input.onChange([...flatCategories, ...subcategories]);
	}, [selectedCategories, selectedSubcategories, input.onChange]);

	const toggleCategory = (category) => {
		setSelectedCategories((categories) => {
			if (categories.some((c) => c.id === category.id)) {
				setSelectedSubcategories(selectedSubcategories?.filter((s) => !category?.subCategories?.some((cs) => cs.id === s.id)));

				return categories.filter((c) => c.id !== category.id);
			} else {
				setSelectedSubcategories([...selectedSubcategories?.filter((s) => !category?.subCategories?.some((cs) => cs.id === s.id)), ...category?.subCategories]);
				return [...categories, category];
			}
		});
	};

	const toggleSubcategory = (subcategory) => {
		setSelectedSubcategories((subcategories) => (subcategories.includes(subcategory) ? subcategories.filter((s) => s !== subcategory) : [...subcategories, subcategory]));
	};
	return (
		<Stack spacing={2}>
			{categories?.map((category, i) => {
				const { id, title, subCategories = [] } = category;
				return (
					<Stack spacing={1} direction="column" justifyContent="start">
						<Chip color="secondary" variant={selectedCategories?.some((c) => c.id === id) ? "filled" : "outlined"} onClick={() => toggleCategory(category)} label={title} />
						<Stack flexWrap="wrap" gap={1} direction="row">
							{subCategories.map((subcategory) => {
								const { id, title } = subcategory;
								return <Chip color="primary" variant={selectedSubcategories?.some((s) => s?.id === id) ? "filled" : "outlined"} onClick={() => toggleSubcategory(subcategory)} label={title} />;
							})}
						</Stack>
					</Stack>
				);
			})}
		</Stack>
	);
}
const GQLgetCategories = gql`
	query ($lang: Languaje) {
		categories {
			id
			title: titleLng(lang: $lang)
			subCategories {
				categoryId
				id
				title: titleLng(lang: $lang)
			}
		}
	}
`;
