import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";

export default function ModalFrame({ isOpen, close, title, children, onSubmit, buttonTexts = ["Aceptar", "Cancelar"], submitDisabled = false, ...props }) {
	return (
		<>
			<Modal open={isOpen} onClose={close} {...props}>
				<>
					<Box
						sx={{
							position: "absolute",
							display: "flex",
							flexDirection: "column",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							width: "50%",
							bgcolor: "background.paper",
							borderRadius: 1,
							boxShadow: 24,
							p: 4,
						}}>
						<Typography variant="h3" style={{ marginBottom: "20px" }}>
							{title}
						</Typography>
						<Box
							pb={6}
							sx={{
								paddingTop: "20px",
								overflowY: "scroll",
								overflowX: "hidden",
								maxHeight: "calc(100vh - 300px)",
								// to hide scrollbar:
								"&::-webkit-scrollbar": {
									display: "none",
								},
							}}>
							{children}
						</Box>
						<Box
							style={{
								alignSelf: "flex-end",
							}}>
							<Button onClick={close} colorScheme="red" mr={3}>
								{buttonTexts[1]}
							</Button>
							<Button disabled={submitDisabled} onClick={onSubmit} colorScheme="blue">
								{buttonTexts[0]}
							</Button>
						</Box>
					</Box>
				</>
			</Modal>
		</>
	);
}
