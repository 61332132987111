
import { useQuery } from '@apollo/client';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Card, Stack, Typography, } from '@mui/material';
import React from 'react';
import { CustomDataGrid, CustomDateRangePicker } from '../../../components';
import customFilterPanelForTable from '../../../components/customFilterPanelForTable';
import { isoDateRenderer } from '../../../tableUtils';
import { getBanners } from '../GQLQueries';
import CreateOption from './CreateOption';
import DeleteConfirmation from './DeleteConfirmation';
import EditOption from './EditOption';

export const BannerList = (props) => {

  const { data: { banners = [] } = {}, loading, refetch: loadData } = useQuery(getBanners, { fetchPolicy: "network-only" });
  return (<>
    <Stack direction='row' justifyContent='space-between'>
      <Typography sx={{ m: 1 }} variant="h4">Reporte de usuarios</Typography>
      <CustomDateRangePicker />
    </Stack>
    <Card {...props}>
      <Box sx={{ minWidth: 1050 }}>
        <CustomDataGrid
          initialFilters={[]}
          reload={loadData}
          loading={loading}
          filterPanel={customFilterPanelForTable}
          actions={[CreateOption, EditOption, DeleteConfirmation]}
          hideFooterSelectedRowCount
          checkboxSelection
          rows={banners}
          columns={[
            { id: "id", label: "ID", type: "number", hide: true },
            { id: "name", label: "Nombre" },
            { id: "type", label: "Tipo" },
            { id: "image", label: "Imagen", customRender: (params) => <img src={params.image} style={{ width: 100 }} alt={params.name} /> },
            { id: "userEnabled", label: "Usuario", customRender: (params) => params.userEnabled ? <VisibilityIcon color='success' /> : <VisibilityOffIcon color='error' /> },
            { id: "providerEnabled", label: "Experto", customRender: (params) => params.providerEnabled ? <VisibilityIcon color='success' /> : <VisibilityOffIcon color='error' /> },
            { id: "showFrom", label: "Mostrar desde", ...isoDateRenderer("showFrom") },
            { id: "showUntil", label: "Mostrar hasta", ...isoDateRenderer("showUntil") },
          ]} />
      </Box>
    </Card>
  </>);
};