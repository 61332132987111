import ModalFrame from "../../../components/form/ModalFrame";
import { Delete } from "@mui/icons-material";
import { useDisclosure } from "react-use-disclosure";
import { Tooltip, IconButton } from "@mui/material";
import { Form } from "react-final-form";
import { gql, useMutation } from "@apollo/client";
import TableIconButton from "../../../components/TableIconButton";

export default function DeleteQualification({ selectedRows = [] }) {
	const { isOpen, open, close } = useDisclosure(false);

	const GQLDeleteQualification = gql`
		mutation deleteQualification($qualificationId: Int, $providerId: Int) {
			deleteQualification(qualificationId: $qualificationId, providerId: $providerId)
		}
	`;

	const [DeleteQualification] = useMutation(GQLDeleteQualification, { fetchPolicy: "network-only" });

	return (
		<>
			<TableIconButton onClick={open} disabled={selectedRows.length !== 1} icon={<Delete />} tooltip={`Eliminar calificacion`} />
			<Form
				onSubmit={async () => {
					await DeleteQualification({ variables: { qualificationId: selectedRows[0].id, providerId: selectedRows[0].budget.owner.id } });
					close();
				}}
				initialValues={{ templateId: null, tasks: selectedRows.map((u) => u.id) }}
				render={({ handleSubmit }) => <ModalFrame onSubmit={handleSubmit} {...{ isOpen, close }} title={"Seguro que desea eliminar la calificacion?"} buttonTexts={["Confirmar", "Cancelar"]} />}
			/>
		</>
	);
}
