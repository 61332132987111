import React from "react";
import { Box, Card, Typography, Stack } from "@mui/material";
import { CustomDataGrid, CustomDateRangePicker } from "../../../components";
import CustomFilterPanelForTable from "../../../components/customFilterPanelForTable";
import CreateTaskAction from "./CreateTaskAction";
import { gql, useQuery } from "@apollo/client";
import { isoDateRenderer } from "../../../tableUtils";
import CreateAction from "./CreateAction";
import CreatePackageAction from "./CreatePackageAction";
import DeleteConfirmation from "./DeleteConfirmation";
import EditAction from "./EditAction";

export const ResultList = (props) => {
	const query = gql`
		query getAllBundleTasks {
			bundles: getAllBundleTasks {
				id
				taskData
				createdAt
			}
		}
	`;
	const { data: { bundles = [] } = {}, loading, refetch: runQuery } = useQuery(query, { fetchPolicy: "network-only" });
	// const rows = data?.getAllTasks || [];
	const rows = bundles;

	return (
		<>
			<Stack direction="row" justifyContent="space-between">
				<Typography sx={{ m: 1 }} variant="h4">
					Reporte de tareas
				</Typography>
				<CustomDateRangePicker />
			</Stack>
			<Card {...props}>
				<Box sx={{ minWidth: 1050 }}>
					<CustomDataGrid
						filterPanel={CustomFilterPanelForTable}
						reload={runQuery}
						loading={loading}
						actions={[DeleteConfirmation, CreateAction, EditAction, CreateTaskAction, CreatePackageAction]}
						hideFooterSelectedRowCount
						checkboxSelection
						label=""
						rows={rows}
						columns={[
							{ id: "id", label: "ID", type: "number" },
							{ id: "taskData.title", label: "Titulo" },
							{ id: "taskData.description", label: "Descripcion" },
							{ id: "taskData.revenueRate", label: "Tasa de ingresos", type: "number" },
							{ id: "createdAt", label: "Fecha de creacion", ...isoDateRenderer("createdAt") },
						]}
					/>
				</Box>
			</Card>
		</>
	);
};
