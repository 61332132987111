import { gql, useQuery } from "@apollo/client";
import { Avatar, Box, Button, Card, Rating, Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomDataGrid, CustomDateRangePicker } from "../../../components";
import CustomFilterPanelForTable from "../../../components/customFilterPanelForTable";
import { getInitials } from "../../../helpers/name";
import { selectCompleteDate } from "../../../redux/layout";
import SetInvoicedAction from "./SetInvoicedAction";
import { avatarRenderer } from "../../../tableUtils";
import SendMailAction from "./SendMailAction";

export const ResultList = (props) => {
	const navigate = useNavigate();
	const query = gql`
		query listCreatedPackages($dateFrom: String!, $dateTo: String!) {
			rows: getPackageFinanceReport(dateFrom: $dateFrom, dateTo: $dateTo)
		}
	`;

	const [dateFrom, dateTo] = useSelector(selectCompleteDate);
	const { data: { rows = [] } = {}, loading, refetch: runQuery } = useQuery(query, { variables: { dateFrom: dateFrom, dateTo: dateTo }, fetchPolicy: "network-only" });

	const renderDetailsButton = (row) => {
		return (
			<Button onClick={() => navigate(`/package/${row.id}`)} variant="contained" color="primary">
				Ver detalles
			</Button>
		);
	};

	console.log("rows", rows);

	return (
		<>
			<Stack direction="row" justifyContent="space-between">
				<Typography sx={{ m: 1 }} variant="h4">
					Reporte de paquetes por fecha de creacion
				</Typography>
				<CustomDateRangePicker />
			</Stack>
			<Card {...props}>
				<Box sx={{ minWidth: 1050 }}>
					<CustomDataGrid
						filterPanel={CustomFilterPanelForTable}
						reload={runQuery}
						loading={loading}
						actions={[SetInvoicedAction, SendMailAction]}
						hideFooterSelectedRowCount
						checkboxSelection
						label=""
						rows={rows}
						columns={[
							{ id: "id", label: "ID", type: "number" },
							{ id: "provider", label: "Experto", ...avatarRenderer() },
							{ id: "phone", label: "Telefono" },
							{ id: "email", label: "Email" },
							{ id: "packagesAssignedCount", label: "Paquetes asignados", type: "number" },
							{ id: "amount", label: "Monto", type: "number" },
							// { id: "open", label: "Detalles", customRender: renderDetailsButton },
						]}
					/>
				</Box>
			</Card>
		</>
	);
};
