import React, { useEffect, useState } from 'react';
import MainRouter from './MainRouter';
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { CssBaseline } from "@mui/material"
import themeOptions from "./theme"
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider
} from "@apollo/client";
import { useSelector } from 'react-redux';
import { selectToken } from './redux/session';

import { CategoryScale } from 'chart.js'
import ChartJS from 'chart.js/auto'

import { esES } from '@mui/x-data-grid';
import { esES as coreEsES } from '@mui/material/locale';
import SnackBar from './screens/app/components/SnackBar';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

ChartJS.register(
  CategoryScale,
)

const Application = () => {

  const theme = createTheme(themeOptions, esES, coreEsES);
  const token = useSelector(selectToken)

  let [client, setClient] = useState(token ? new ApolloClient({
    uri: `${process.env.REACT_APP_API_URI}/graphql?token=${token}`,
    cache: new InMemoryCache()
  }) : null);

  useEffect(() => {
    if (!client && token) setClient(new ApolloClient({
      uri: `${process.env.REACT_APP_API_URI}/graphql?token=${token}`,
      cache: new InMemoryCache()
    }));
    return () => {
      if (client) client.stop()
    }
  }, [token])


  const App = <ThemeProvider theme={theme}>

    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CssBaseline />
      <MainRouter />
      <SnackBar />
    </LocalizationProvider>
  </ThemeProvider>

  return client ? <ApolloProvider client={client}>{App}</ApolloProvider> : App
}

export default Application