import { MenuItem, TableCell, TableFooter, TableRow } from '@mui/material';
import React, { useContext, useState } from 'react';
import { get } from "lodash"
import { TableContext } from '..';
import { CustomSelect } from '../..';

export default (props) => {
    const { columns, setColumns, footerRow } = useContext(TableContext);
    const FooterOptions = {
        none: (rows) => "",
        Total: (rows) => rows.length,
        Suma: (rows, col) => rows.reduce((p, curr) => p + get(curr, col.id, col.customValue ? col.customValue(curr) : 0), 0).toFixed(2),
        Promedio: (rows, col) => (rows.reduce((p, curr) => p + get(curr, col.id, col.customValue ? col.customValue(curr) : 0), 0) / rows.length).toFixed(2),
    }

    return <TableFooter>
        <TableRow>
            <TableCell />
            {columns.filter(c => !c.hide).map((col, index) => {
                return <TableCell style={{ textAlign: "right" }} key={`footer-${col.id}${index}`}>
                    <CustomSelect
                        onChange={(e) => {
                            const columnsCP = [...columns]
                            columnsCP[columns.findIndex(c => c.id === col.id)].calcFooter = FooterOptions[e.target.value]
                            setColumns(columnsCP)
                        }}
                        variant="filled"
                        renderValue={(value) => `${value} ${footerRow[col.id] || ""}`}
                        style={{ width: "100%" }}
                        defaultValue={"none"}>
                        <MenuItem value="none"></MenuItem>
                        <MenuItem value="Total">Total</MenuItem>
                        {col.type === "number" && <MenuItem value="Suma">Suma</MenuItem>}
                        {col.type === "number" && <MenuItem value="Promedio">Promedio</MenuItem>}
                        {/* <MenuItem value="Promedio">Promedio</MenuItem> */}
                    </CustomSelect>
                </TableCell>
            })}
        </TableRow>
    </TableFooter>
};