import { gql, useQuery } from "@apollo/client";
import FormSelect from "./Select";

const GQLGetAcquisitionMethodsForSelect = gql`
	query getAcquisitionMethodsForSelect {
		acquisitionMethods: getAllAcquisitionMethodsList {
			id
			flatTitle
		}
	}
`;
export default function FormAcquisitionMethodSelect(props) {
	const { data: { acquisitionMethods = [] } = {} } = useQuery(GQLGetAcquisitionMethodsForSelect, { fetchPolicy: "network-only" });
	return <FormSelect {...props} options={acquisitionMethods.map(({ id, flatTitle }) => ({ value: id, label: flatTitle }))} />;
}
