import ModalFrame from '../../../components/form/ModalFrame';
import { Email } from '@mui/icons-material'

import { useDisclosure } from "react-use-disclosure";
import { Stack, Tooltip, IconButton, AvatarGroup, Avatar } from "@mui/material"
import { Form } from 'react-final-form'
import { gql, useMutation, useQuery } from '@apollo/client';
import FormAutocomplete from '../../../components/form/FormAutocomplete';
import TableIconButton from '../../../components/TableIconButton';

export default function SendMailAction({ selectedRows = [] }) {
    const { isOpen, open, close } = useDisclosure(false);

    const GQLGetEmailTemplates = gql`
    query getEmailTemplates {
        getEmailTemplates {
            value: id
            label: name
        }
    }`;
    const { data: { getEmailTemplates: emailTemplates } = {} } = useQuery(GQLGetEmailTemplates)

    const GQLSendMail = gql`
        mutation SendMail($users: [Int]!, $templateId: String!) {
            sendMail(users: $users, templateId: $templateId) {
                id
            }
        }`;

    const [SendMailWithTemplate] = useMutation(GQLSendMail, { variables: {}, fetchPolicy: "network-only" });

    const validate = (values) => {
        const errors = {};
        if (!values.templateId) errors.templateId = 'Required';
        return errors;
    }

    return (<>
        <TableIconButton onClick={open} disabled={selectedRows.length < 1} icon={<Email />} tooltip='Enviar mail al usuario' />
        <Form
            onSubmit={async (values) => {
                await SendMailWithTemplate({ variables: values })
                close()
            }}
            initialValues={{ templateId: null, users: selectedRows.map(u => u.owner.id) }}
            validate={validate}
            render={({ handleSubmit }) => (
                <ModalFrame
                    onSubmit={handleSubmit}
                    {...{ isOpen, close }}
                    title={<>Enviando mail a:
                        <AvatarGroup style={{ padding: "0 20px", display: "inline-flex" }} total={selectedRows.length}>
                            {selectedRows.filter((_, i) => i < 5).map((row, i) => <Avatar key={i} alt={`${row.owner.firstName} ${row.owner.lastName}`} src={row.owner.profilePicture} />)}
                        </AvatarGroup>
                    </>}
                    buttonTexts={["Enviar", "Cancelar"]}>
                    <Stack spacing={4}>
                        <FormAutocomplete required optionValueKey='value' options={emailTemplates} style={{ width: '100%' }} name='templateId' label='Template' type='text' autoFocus />
                    </Stack>
                </ModalFrame>
            )} />
    </>
    )
}
