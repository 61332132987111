import React, { useEffect } from 'react';
import { Box, Avatar, Card, Typography, Stack } from '@mui/material';
import { getInitials } from '../../../helpers/name';
import { CustomDataGrid, CustomDateRangePicker } from '../../../components';
import { getAllCommissions } from '../GQLQueries';
import { useLazyQuery } from '@apollo/client';
import changeCommissionStatus from "./changeCommissionStatus"
import { get } from "lodash";
import CustomFilterPanelForTable from '../../../components/customFilterPanelForTable';
import { useSelector } from 'react-redux';
import { selectCompleteDate, } from '../../../redux/layout';
import BooleanCheck from '../../../components/tableRenderers/BooleanCheck';

export const CustomerListResults = (props) => {

  const [dateFrom, dateTo] = useSelector(selectCompleteDate);

  const [runQuery, { loading, error, data }] = useLazyQuery(getAllCommissions, { variables: { dateFrom, dateTo }, fetchPolicy: "network-only" });
  const rows = data?.getAllCommissions || []

  const loadData = () => {
    if (dateFrom && dateTo) {
      runQuery({ variables: { dateFrom, dateTo } })
    }
  }
  useEffect(loadData, [dateFrom, dateTo, runQuery])

  const avatarRenderer = (user = {}) => {
    return <Box sx={{ alignItems: 'center', display: 'flex' }}>
      <Avatar src={user?.profilePicture} sx={{ mr: 2 }}>{getInitials(`${user?.firstName} ${user?.lastName}`)}</Avatar>
      <Typography color="textPrimary" variant="body1">{user?.firstName} {user?.lastName}</Typography>
    </Box>
  }

  if (error) return JSON.stringify(error)
  return (<>
    <Stack direction='row' justifyContent='space-between'>
      <Typography sx={{ m: 1 }} variant="h4">Reporte de comisiones</Typography>
      <CustomDateRangePicker />
    </Stack>
    <Card {...props}>
      <Box sx={{ minWidth: 1050 }}>
        <CustomDataGrid
          filterPanel={CustomFilterPanelForTable}
          reload={loadData}
          loading={loading}
          hideFooterSelectedRowCount
          selectedActions={changeCommissionStatus}
          checkboxSelection
          label=""
          rows={rows}
          columns={[
            { id: "id", label: "ID", type: "number", hide: true },
            { id: "budget.task.id", label: "ID Tarea", type: "number", hide: true },
            { id: "budget.task.title", label: "Titulo de la tarea" },
            { id: "budget.task.owner.id", label: "ID Usuario", type: "number", hide: true },
            { id: "budget.task.owner", label: "Usuario", customValue: row => `${get(row, "budget.task.owner.firstName")} ${get(row, "budget.task.owner.lastName")}`, customRender: row => avatarRenderer(get(row, "budget.task.owner", {})) },
            { id: "budget.id", label: "ID Presupuesto", type: "number", hide: true },
            { id: "budget.owner.id", label: "ID Experto", type: "number", hide: true },
            { id: "budget.owner", label: "Experto", customValue: row => `${get(row, "budget.owner.firstName")} ${get(row, "budget.owner.lastName")}`, customRender: row => avatarRenderer(get(row, "budget.owner", {})) },
            { id: "amount", label: "Monto de Comision", type: "number", customValue: ({ amount }) => amount, customRender: ({ amount }) => new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(amount) },
            { id: "budget.task.paid", label: "Monto de Tarea", type: "number", customValue: ({ budget: { amount, task: { paid } } }) => (paid || amount || 0), customRender: ({ budget: { amount, task: { paid } } }) => new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(paid || amount || 0) },
            { label: "Pruesupuesto contratado", ...BooleanCheck("budget.hired"), hide: true },
            { id: "status", label: "Estado" },
            // { id: "category/subcategory", label: "Categoria", customValue: row => tableCategorySbucategoryRender(row.task), customRender: row => tableCategorySbucategoryRender(row.task) },
            // { id: "availabilities", label: "Disponibilidad", customValue: row => row.availabilities.join(" - "), customRender: row => row.availabilities?.join(" - ") },
            // { id: "comment", label: "Comentario" },
            // { id: "createdAt", label: "Fecha de creación", type: "date", customValue: dateStringValue, customRender: dateRenderer },
            // { id: "qualified", label: "Calificado", customValue: isQualified, customRender: isQualified },
          ]} />
      </Box>
    </Card>
  </>);
};