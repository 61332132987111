import ModalFrame from "../../../components/form/ModalFrame";
import { SendToMobile } from "@mui/icons-material";

import { useDisclosure } from "react-use-disclosure";
import { Stack, Tooltip, IconButton } from "@mui/material";
import { Form } from "react-final-form";
import { gql, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../../../redux/snackbar";
import FormInput from "../../../components/form/FormInput";
import FormAddress from "../../../components/form/FormAddress";
import TableIconButton from "../../../components/TableIconButton";
import WarningIcon from "@mui/icons-material/Warning";

export default function CreateTaskAction({ selectedRows = [] }) {
	const { isOpen, open, close } = useDisclosure(false);
	const dispatch = useDispatch();

	const GQLCreateTaskInApp = gql`
		mutation createTaskInApp($email: String, $bundleTaskId: Int!, $overrides: JSON, $address: JSON) {
			taskCreated: createTaskInApp(email: $email, bundleTaskId: $bundleTaskId, overrides: $overrides, address: $address)
		}
	`;

	const [CreateTaskInApp] = useMutation(GQLCreateTaskInApp, { fetchPolicy: "network-only" });

	const createTaskInApp = async ({ email, address, ...overrides }) => {
		const selectedBundleTask = selectedRows[0];
		const { data: { taskCreated } = {}, errors } = await CreateTaskInApp({ variables: { overrides, address, bundleTaskId: selectedBundleTask.id, email } });
		if (errors || !taskCreated) dispatch(setSnackBar({ text: "Error al crear la tarea.", severity: "error" }));
		else if (taskCreated) {
			dispatch(setSnackBar({ text: "Tarea creada correctamente." }));
			close();
		}
	};

	const validate = (values) => {
		const errors = {};
		if (!values.title) errors.title = "Required";
		return errors;
	};

	return (
		<>
			<TableIconButton onClick={open} icon={<SendToMobile />} tooltip="Crear tarea empaquetada" disabled />
			<Form
				onSubmit={createTaskInApp}
				initialValues={{ ...selectedRows[0]?.taskData }}
				validate={validate}
				render={({ handleSubmit, values }) => (
					<ModalFrame onSubmit={handleSubmit} {...{ isOpen, close }} title="Generar tarea en Manno" submitDisabled={!values?.address?.inline} buttonTexts={["Enviar", "Cancelar"]}>
						{/* {JSON.stringify(values?.address?.inline)} */}
						<Stack spacing={4}>
							<FormInput label="Email" name="email" autoFocus />
							{/* aclaracion, si este campo se completa, se creara un nuevo usuario o se le asignara la tarea a un usuario que matchee con este email */}
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: "10px",
									padding: "10px",
									borderRadius: "10px",
									backgroundColor: "#ffeb3b",
									color: "#000",
									fontSize: "12px",
									fontWeight: "bold",
									marginTop: 5,
								}}>
								<WarningIcon color="warning" scale="large" />
								<span>Si se completa este campo, se creara un nuevo usuario o se le asignara la tarea a un usuario que matchee con este email</span>
							</div>
							<FormInput label="Título" name="title" required />
							<FormInput label="Descripcion" name="description" required />
							<FormAddress label="Direccion" name="address" required />
						</Stack>
					</ModalFrame>
				)}
			/>
		</>
	);
}
