import { Edit as EditIcon } from "@mui/icons-material";
import { useRef } from "react";
import TableIconButton from "../../../components/TableIconButton";
import EditForm from "./EditForm";

export default function EditOption(props) {
  const formRef = useRef(null);
  const { selectedRows = [] } = props;
	return (<>
			<TableIconButton icon={<EditIcon />} onClick={formRef?.current?.open} disabled={selectedRows.length !== 1} tooltip="Editar Registro" />
			<EditForm ref={formRef} {...props} />
		</>
	);
}
