import { gql } from "@apollo/client"

export const getAllMessages = gql`query($dateFrom: String!, $dateTo: String!)  {
    getTaskActivities(dateFrom: $dateFrom, dateTo: $dateTo ) {
        id
        taskId
        owner {
            id
            firstName
            lastName
            profilePicture
        }
        task {
            id
            title
            category{ titleLng }
            subcategory{ titleLng }
        }
        createdAt
        action
    }
}`