import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale'
import { Box, Avatar, Card, Typography, Stack, Chip, } from '@mui/material';
import { getInitials } from '../../../helpers/name';
import { CustomDataGrid, CustomDateRangePicker } from '../../../components';
import {  getAllProvidersByUpdate } from '../GQLQueries';
import { useLazyQuery } from '@apollo/client';
import { get } from "lodash"
import customFilterPanelForTable from '../../../components/customFilterPanelForTable';
import { useSelector } from 'react-redux';
import { selectCompleteDate } from '../../../redux/layout';
import EditForm from './EditForm';
import SendMailAction from './SendMailAction';
import SendPushAction from './SendPushAction';
import DeleteConfirmation from './DeleteConfirmation';
import BooleanCheck from '../../../components/tableRenderers/BooleanCheck';
import SendSMSAction from '../../usersList/components/SendSMSAction';
import { WhatsappActionFactory } from '../../../components/SendWhatsappAction';

export const CustomerListResults = (props) => {
  // const tableRef = React.useRef(null)

  const [dateFrom, dateTo] = useSelector(selectCompleteDate);

  const [runQuery, { data, loading },] = useLazyQuery(getAllProvidersByUpdate, { variables: { dateFrom, dateTo }, fetchPolicy: "network-only" });
  const rows = data?.getAllProvidersByUpdate || []


  const loadData = () => {
    if (dateFrom && dateTo) {
      runQuery({ variables: { dateFrom, dateTo } })
    }
  }
  useEffect(loadData, [dateFrom, dateTo, runQuery])

  const avatarRenderer = (row) => {
    return <Box sx={{ alignItems: 'center', display: 'flex' }}>
      <Avatar src={row.profilePicture} sx={{ mr: 2 }}>{getInitials(`${row.firstName} ${row.lastName}`)}</Avatar>
      <Typography color="textPrimary" variant="body1">{row.firstName} {row.lastName}</Typography>
    </Box>
  }

  const dateRenderer = row => format(new Date(Number(row.createdAt)), 'dd/MM/yyyy HH:mm', { locale: es })
  const dateStringValue = row => format(new Date(Number(row.createdAt)), "yyyy-MM-dd'T'HH:mm:ss.mmm'Z'", { locale: es })
  const getCategoriesString = row => {
    const professionalCategories = get(row, "professionalProfile.professionalCategories", [])
    return professionalCategories.map(c => `${get(c, "category.titleLng", "noCat")}/${get(c, "subcategory.titleLng", "noSubcat")}`).join(" - ")
  }
  const chipsRender = {
    customRender: (row) => {
      const chips = get(row, "professionalProfile.profileChips", [])
      return <Stack direction='row' gap={1} width={250} flexWrap='wrap'>
        {chips.map((chip) => (
          <Chip style={{ borderRadius: 5, backgroundColor: "#3f51b5", color: "white" }} key={chip.id} label={chip.title} size="small" />
        ))}
      </Stack>
    },
    customValue: row => {
      const chips = get(row, "professionalProfile.profileChips", [])
      return chips.map(c => get(c, "title", "")).join(" - ")
    },
    hide: true
  }
  return (<>
    <Stack direction='row' justifyContent='space-between'>
      <Typography sx={{ m: 1 }} variant="h4">Reporte de expertos</Typography>
      <CustomDateRangePicker />
    </Stack>
    <Card {...props}>
      <Box sx={{ minWidth: 1050 }}>
        <CustomDataGrid
          // ref={tableRef}
          initialFilters={[]}
          reload={loadData}
          loading={loading}
          filterPanel={customFilterPanelForTable}
          actions={[SendMailAction, WhatsappActionFactory(), SendSMSAction, SendPushAction, EditForm, DeleteConfirmation]}
          hideFooterSelectedRowCount
          checkboxSelection
          rows={rows}
          columns={[
            { id: "id", label: "ID", type: "number", hide: true },
            { id: "firstName", label: "Nombre Completo", customValue: row => `${row.firstName} ${row.lastName}`, customRender: avatarRenderer },
            // { id: "firstName", label: "Nombre", hide: true },
            // { id: "lastName", label: "Apellido", hide: true },
            { id: "createdAt", label: "Fecha de creación", type: "date", customValue: dateStringValue, customRender: dateRenderer },
            { id: "professionalProfile.categories", label: "Categorías", customValue: getCategoriesString, customRender: getCategoriesString, hide: true },
            { id: "professionalProfile.experience", label: "Experiencia" },
            { id: "chips", label: "Chips", ...chipsRender, },
            { id: "viewedTasks", label: "Tareas Recibidas", type: "number" },
            { id: "sentBudgets", label: "Presupuestos Enviados", type: "number" },
            { id: "hiddenTasks", label: "Tareas Descartadas", type: "number" },
            { id: "hiredBudgets", label: "Presupuestos Contratados", type: "number" },
            { id: "qualificationsCount", label: "Calificaciones", type: "number" },
            { id: "totalCommissions", label: "Comisiones Totales", type: "number", customRender: row => new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(row.totalCommissions) },
            { id: "finalizedTasks", label: "Tareas Finalizadas", type: "number", customValue: ({ taskActivities }) => taskActivities.filter(a => a.action === "TASK_STATUS_FINALIZED").length, customRender: ({ taskActivities }) => taskActivities.filter(a => a.action === "TASK_STATUS_FINALIZED").length, hide: true },
            { id: "paidCommissions", label: "Comisiones Pagadas", type: "number", customRender: row => new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(row.paidCommissions) },
            { id: "totalBudgetedAmount", label: "Total Presupuestado", type: "number", customRender: row => new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(row.totalBudgetedAmount) },
            { id: "totalTasksPaid", label: "Total Cobrado", type: 'number', customRender: (row) => new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(row.totalTasksPaid) },
            { id: "email", label: "Email" },
            { id: "phone", label: "Telefono" },
            { id: "address.inline", label: "Direccion" },
            { id: "address.locality", label: "Localidad" },
            { id: 'acquisitionMethod', label: 'Metodo de adquisicion' },
            { id: "blockDate", label: "Fecha de Bloqueo", type: "date", customValue: row => row.professionalProfile.blockDate ? format(new Date(row.professionalProfile.blockDate), "yyyy-MM-dd'T'HH:mm:ss.mmm'Z'", { locale: es }) : null, customRender: row => row.professionalProfile.blockDate ? format(new Date(row.professionalProfile.blockDate), 'dd/MM/yyyy HH:mm', { locale: es }) : 'N/A' },
            { id: "deletedAt", label: "Eliminado", type: "date", customValue: row => row.deletedAt ? format(new Date(row.deletedAt), "yyyy-MM-dd'T'HH:mm:ss.mmm'Z'", { locale: es }) : null, customRender: row => row.deletedAt ? format(new Date(row.deletedAt), 'dd/MM/yyyy HH:mm', { locale: es }) : 'N/A' },
            { id: "verified", label: "Verificado", ...BooleanCheck("verified"), hide: true },
            { id: "adminNotes", label: "Notas" },
          ]} />
      </Box>
    </Card>
  </>);
};