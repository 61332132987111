import { gql, useMutation } from "@apollo/client";
import { NoteAdd } from "@mui/icons-material";
// import EditNoteIcon from '@mui/icons-material/EditNote';
import { useContext } from "react";
import { useDisclosure } from "react-use-disclosure";
import TableIconButton from "../../../components/TableIconButton";
import { TableContext } from "../../../components/customDataGrid";
import ModalFrame from "../../../components/form/ModalFrame";
import { Stack } from "@mui/material";
import { Form } from "react-final-form";
import FormInput from "../../../components/form/FormInput";

const GQLEditNote = gql`
	mutation editPackageNotes($packageId: Int!, $adminNotes: String) {
		edited: editPackageNotes(packageId: $packageId, adminNotes: $adminNotes)
	}
`;

export default function EditNotesAction({ selectedRows = [] }) {
	const { isOpen, open, close } = useDisclosure();
	const { reload } = useContext(TableContext);
	const selectedPkg = selectedRows[0];
	const [EditNote] = useMutation(GQLEditNote, { fetchPolicy: "network-only" });

	const handleSubmit = async ({ adminNotes }) => {
		const res = await EditNote({ variables: { packageId: selectedPkg.id, adminNotes } });
		await reload();
		close();
	};

	return (
		<>
			<TableIconButton onClick={open} disabled={selectedRows.length !== 1} icon={<NoteAdd />} tooltip={`Editar nota`} />
			<Form
				initialValues={{ id: selectedPkg?.id, adminNotes: selectedPkg?.adminNotes }}
				onSubmit={handleSubmit}
				// validate={validate}
				render={({ handleSubmit, values }) => (
					<ModalFrame onSubmit={handleSubmit} {...{ isOpen, close }} title={`Editar notas`} buttonTexts={["Guardar", "Cancelar"]}>
						<Stack spacing={4}>
							<FormInput name="adminNotes" label="Notas" type="textarea" />
						</Stack>
					</ModalFrame>
				)}
			/>
		</>
	);
}
