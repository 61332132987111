import ModalFrame from "../../../components/form/ModalFrame";

import { useLazyQuery, useMutation } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import { forwardRef, useContext, useEffect, useImperativeHandle } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useDisclosure } from "react-use-disclosure";
import { TableContext } from "../../../components/customDataGrid";
import FormDateInput from "../../../components/form/FormDateInput";
import FormInput from "../../../components/form/FormInput";
import FormInputHTMLEditor from "../../../components/form/FormInputHTMLEditor";
import FormSwitch from "../../../components/form/FormSwitch";
import FormSelect from "../../../components/form/Select";
import { setSnackBar } from "../../../redux/snackbar";
import { MUTATION_editBanner, QUERY_getBanner } from "../GQLQueries";

export default forwardRef(function EditForm({ selectedRows = [] }, ref) {
	const dispatch = useDispatch();
	const { isOpen, open, close } = useDisclosure(false);
	useImperativeHandle(ref, () => ({ open }));
	const { reload } = useContext(TableContext);

	const [getUser, { data: { banner = {} } = {} }] = useLazyQuery(QUERY_getBanner, { fetchPolicy: "network-only" });
	useEffect(() => {
		if (isOpen && selectedRows[0]?.id) getUser({ variables: { id: selectedRows[0]?.id } });
	}, [isOpen, selectedRows, getUser]);
	const [Edit] = useMutation(MUTATION_editBanner, { fetchPolicy: "network-only" });

	const validate = (values) => {
		const errors = {};
		return errors;
	};

	const submit = async (values) => {
		let variables = {
			...values,
			sort: parseInt(values?.sort),
			typeConfig: undefined,
		};
		if (values.bannerType === "ModalTemplate") {
			variables.type = "APP_SCREEN";
			variables.typeConfig = JSON.stringify({
				screenName: "ModalTemplate",
				screenData: typeof values?.modalTemplateData === "string" ? JSON.parse(values?.modalTemplateData) : values?.modalTemplateData,
			});
		} else if (values.bannerType === "AppScreen") {
			variables.type = "APP_SCREEN";
			variables.typeConfig = JSON.stringify({ screenName: values.screenName, screenData: JSON.parse(values?.screenData || "{}") });
		} else if (values.bannerType === "OpenLink") {
			variables.type = "OPEN_LINK";
			variables.typeConfig = JSON.stringify({ uri: values.uri });
		} else if (values.bannerType === "ShareApp") {
			variables.type = "SHARE_APP";
			variables.typeConfig = JSON.stringify({});
		} else if (values.bannerType === "NoAction") {
			variables.type = "NO_ACTION";
			variables.typeConfig = JSON.stringify({});
		} else if (values.bannerType === "Testing") {
			variables.type = "Q_A";
			variables.typeConfig = JSON.stringify({});
		} else variables.typeConfig = banner.typeConfig;

		const { data } = await Edit({ variables });

		if (!data.errors) dispatch(setSnackBar({ text: "Registro editado correctamente", severity: "success" }));
		else dispatch(setSnackBar({ text: "Error al editar el registro", severity: "error" }));
		close();
		reload();
	};

	const typeConfig = JSON.parse(banner?.typeConfig || "{}");
	const getBannerType = (type, config) => {
		if (type === "APP_SCREEN" && config?.screenName === "ModalTemplate") return "ModalTemplate";
		else if (type === "APP_SCREEN") return "AppScreen";
		else if (type === "OPEN_LINK") return "OpenLink";
		else if (type === "SHARE_APP") return "ShareApp";
		else if (type === "Q_A") return "Testing";
		else return "NoAction";
	};

	return (
		<>
			<Form
				initialValues={{
					screenName: typeConfig?.screenName || "",
					screenData: JSON.stringify(typeConfig?.screenData) || "",
					uri: typeConfig?.uri || "",
					id: banner.id,
					...banner,
					bannerType: getBannerType(banner?.type, typeConfig),
					modalTemplateData: typeConfig?.screenData || "",
				}}
				onSubmit={submit}
				validate={validate}
				render={({ handleSubmit, values, form }) => {
					return (
						<ModalFrame onSubmit={handleSubmit} {...{ isOpen, close }} title={`Editar banner`} buttonTexts={["Guardar", "Cancelar"]}>
							<Stack spacing={4}>
								<Stack gap={2} direction="row" justifyContent="space-between">
									<Stack spacing={4} width="100%">
										<Stack spacing={4} direction="row" justifyContent="space-between">
											<FormInput style={{ width: "100%" }} name="name" label="Nombres" type="text" autoFocus />
											{/* upload image */}
										</Stack>
										<Stack spacing={4} direction="row" justifyContent="space-around">
											<FormSwitch name="userEnabled" label="Usuario" />
											<FormSwitch name="providerEnabled" label="Experto" />
											<FormInput style={{ width: 100 }} name="sort" label="Orden" type="number" />
										</Stack>

										<Stack spacing={4} direction="row" justifyContent="space-around">
											<Stack spacing={4} direction="column">
												<Typography variant="h6">Fecha de habilitacion</Typography>
												<FormDateInput name="showFrom" type="date" format={"dd/MM/yyyy"} cleareable /* disableFuture disablePast */ />
												<FormDateInput name="showUntil" type="date" format={"dd/MM/yyyy"} cleareable /* disableFuture disablePast */ />
											</Stack>
											<Stack alignContent={"center"} alignItems={"center"} justifyContent={"center"} spacing={2}>
												<FormInput style={{ width: "100%" }} name="image" label="Imagen" type="text" />
												{!values.image && (
													<input
														type="file"
														accept="image/*"
														onChange={async (e) => {
															const token = window.localStorage.getItem("sessionToken");
															const formData = new FormData();
															formData.append("image", e.target.files[0]);
															const res = await fetch(`${process.env.REACT_APP_API_URI}/api/news/uploadBanner`, {
																method: "POST",
																headers: { Authorization: `Bearer ${token}` },
																body: formData,
															});
															const data = await res.json();
															if (data?.url) form.change("image", data.url);
														}}
													/>
												)}
												{!!values.image && <img src={values.image} alt="banner" style={{ height: "200px", width: "auto" }} />}
											</Stack>
										</Stack>
										<FormSelect
											name="bannerType"
											label="Tipo"
											options={[
												{ label: "Modal", value: "ModalTemplate" },
												{ label: "Abrir link", value: "OpenLink" },
												{ label: "Compartir app", value: "ShareApp" },
												{ label: "Pantalla de la app", value: "AppScreen" },
												{ label: "No hacer nada", value: "NoAction" },
												{},
											]}
										/>
										{values.bannerType === "ModalTemplate" && <FormInputHTMLEditor name="modalTemplateData" label="Modal" />}
										{values.bannerType === "AppScreen" && (
											<>
												<FormInput style={{ width: "100%" }} name="screenName" label="Nombre de la pantalla" type="text" />
												<FormInput style={{ width: "100%" }} name="screenData" label="Parametros" type="text" />
											</>
										)}
										{values.bannerType === "OpenLink" && <FormInput style={{ width: "100%" }} name="uri" label="Link" type="text" />}
									</Stack>
								</Stack>
							</Stack>
						</ModalFrame>
					);
				}}
			/>
		</>
	);
});
