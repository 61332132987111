import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { MenuItem } from '@mui/material';
import TextField from '@mui/material/TextField';
import { addDays } from "date-fns";
import localeEs from "date-fns/locale/es";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDateFrom, selectDateTo, selectRangeValue, setDateFrom, setDateTo, setRangeValue } from '../../redux/layout';
import CustomSelect from '../customSelect';

export default function BasicDateRangePicker(props) {

    const dispatch = useDispatch()
    const selectedDateFrom = useSelector(selectDateFrom);
    const selectedDateTo = useSelector(selectDateTo);
    const selectedRangeValue = useSelector(selectRangeValue);

    const fromDateRef = React.useRef(null);
    const toDateRef = React.useRef(null);
    const [value, setValue] = React.useState([selectedDateFrom, selectedDateTo]);

    useEffect(() => {
        if (selectedRangeValue && selectedRangeValue.length) {
            let [dateFrom, dateTo] = value
            let newValue = [dateFrom, dateTo]
            let [selectFrom, selectTo] = selectedRangeValue
            if (selectedRangeValue.some(v => v >= 0)) {
                dateFrom = selectFrom !== undefined && selectFrom !== null ? addDays(new Date(new Date().setHours(0, 0, 0, 0)), -selectFrom) : value[0]
                dateTo = selectTo !== undefined && selectTo !== null ? addDays(new Date(new Date().setHours(23, 59, 59, 999)), -selectTo) : value[1]
                newValue = [dateFrom, dateTo]
                setValue(newValue)

                dispatch(setDateFrom(dateFrom))
                dispatch(setDateTo(dateTo))
            }
            if (selectedRangeValue.some(v => v < 0)) {
                const textFieldFocus = selectedRangeValue.findIndex(v => v < 0)
                if (textFieldFocus === 0) { fromDateRef.current.focus(); fromDateRef.current.selectionEnd = fromDateRef.current.value.length; }
                if (textFieldFocus === 1) { toDateRef.current.focus(); toDateRef.current.selectionEnd = toDateRef.current.value.length; }
            }
            if (typeof props.onChange === "function") props.onChange(newValue)
        }
    }, [selectedRangeValue])

    const daysFromReleaseDate = () => {
        const releaseDate = new Date("2021-09-01")
        const today = new Date()
        const diffTime = Math.abs(today - releaseDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays-1
    }

    return (<div>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeEs}>

            <DateRangePicker
                startText="Desde"
                endText="Hasta"
                value={value}
                onChange={(newValue) => {
                    setValue(newValue);
                    dispatch(setDateFrom(newValue[0]))
                    dispatch(setDateTo(newValue[1]))
                    dispatch(setRangeValue(null))

                    // if (typeof props.onChange === "function") props.onChange(newValue)
                }}
                renderInput={(startProps, endProps) => (
                    <div style={{ textAlign: "end", width: "100%" }}>
                        <TextField {...startProps} style={{ borderRadius: '8px', backgroundColor: "white" }} inputRef={fromDateRef} />
                        <TextField {...endProps} style={{ borderRadius: '8px', backgroundColor: "white" }} inputRef={toDateRef} />
                        <CustomSelect
                            style={{ margin: 1, minWidth: 150, textAlign: "center", borderRadius: '8px', backgroundColor: "white" }}
                            label="Valor predefinido"
                            value={JSON.stringify(selectedRangeValue)}
                            onChange={(e) => { dispatch(setRangeValue(eval(e.target.value))) }}>
                            <MenuItem value={"[0,0]"}>Hoy</MenuItem>
                            <MenuItem value={"[1,1]"}>Ayer</MenuItem>
                            <MenuItem value={"[7,0]"}>7 Dias</MenuItem>
                            <MenuItem value={"[30,0]"}>30 Dias</MenuItem>
                            <MenuItem value={"[90,0]"}>90 Dias</MenuItem>
                            <MenuItem value={"[365,0]"}>Ultimo Año</MenuItem>
                            <MenuItem value={`[${daysFromReleaseDate()},0]`}>Desde siempre</MenuItem>
                            <MenuItem value={null}>Personalizado</MenuItem>
                        </CustomSelect>
                    </div>
                )}
            />
        </LocalizationProvider >
    </div>
    );
}