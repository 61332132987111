import { configureStore } from "@reduxjs/toolkit";
import layoutReducer from "./layout";
import snackbarReducer from "./snackbar";
import sessionReducer from "./session";

export default configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
    reducer: {
        snackbar: snackbarReducer,
        layout: layoutReducer,
        session: sessionReducer
    }
});
