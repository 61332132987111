import { gql } from "@apollo/client"

export const getAllPackageMessages = gql`query($dateFrom: String!, $dateTo: String!)  {
    messages: getAllPackageMessages(dateFrom: $dateFrom, dateTo: $dateTo ) {
        id
        conversationId
        conversation {
            packageId
            packageApplicationId
            package {
                id
                title
                status
                workDate
            }
            provider {
                id
                firstName
                lastName
                profilePicture
            }
            user {
                id
                firstName
                lastName
                profilePicture
            }
        }
        createdAt
        data
        media
        senderId
    }
}`