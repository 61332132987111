import { useQuery } from "@apollo/client";
import styled from '@emotion/styled';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Autocomplete, Menu, TextField, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTitle } from '../../../redux/layout';
import { logout } from '../../../redux/session';
import { getCurrentBoUser } from '../GQLQueries';
import { ThemeProvider } from "@mui/styles";
import GlobalSearch from "./GlobalSearch";

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
    boxShadow: theme.shadows[3]
}));

export default function AppBarComponent(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch()
    const title = useSelector(selectTitle);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLogout = () => {
        dispatch(logout());
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // const { loading, error, data } = useQuery(getCurrentBoUser);
    //get theme colors from material ui
    const theme = useTheme();

    return <div style={{
        padding: '10px 20px',
        alignContent: 'center',
        display: 'flex',
        height: '65px',
        backgroundColor: theme.palette.primary.main,
        justifyContent: 'space-between',
    }}>
        <GlobalSearch />
        <div>
            <IconButton style={{

            }} size="small" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
                <AccountCircle fontSize="large" />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem onClick={handleLogout}>Cerrar Sesion</MenuItem>
            </Menu>
        </div>
    </div>
}
