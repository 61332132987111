import { gql, useLazyQuery } from "@apollo/client";
import { Paper, Slide } from "@mui/material";
import TrapFocus from "@mui/material/Unstable_TrapFocus";
import React from "react";
import ExpandibleContainer from "../../../components/ExpandibleContainer";
const QUERY_getProvider = gql`
	query getProfessionalProfile($id: Int!) {
		getProvider(id: $id) {
			id
			firstName
			lastName
			phone
			profilePicture
			adminNotes
		}
	}
`;
export default function ProviderInfoWindow({ distance, provider: p, onBlur }) {
	const { id: providerId } = p || {};
	const [getProvider, { data: { getProvider: provider } = {} }] = useLazyQuery(QUERY_getProvider, { fetchPolicy: "network-only" });

	React.useEffect(() => {
		if (providerId) {
			getProvider({ variables: { id: providerId } });
		}
	}, [providerId]);

	if (!provider) return null;

	const { id, firstName, lastName, profilePicture, address, lat, lng } = provider;
	return (
		<TrapFocus>
			<Slide direction="up" in={!!provider} mountOnEnter unmountOnExit>
				<Paper sx={styles.paper}>
					<div style={styles.providerContainer}>
						<img style={styles.selectedAvatar} src={`${profilePicture}`} onBlur={onBlur} />
						<div style={styles.dataColumn}>
							<span>Id: {id}</span>
							<span>
								{firstName} {lastName}
							</span>
							<span>Distancia: {distance}km</span>
							<span>Teléfono: {provider?.phone}</span>
						</div>
						<ExpandibleContainer>
							<div
								style={{
									...styles.dataColumn,
									minWidth: "20vw",
									overflowY: "scroll",
									scrollbarWidth: "none",
									msOverflowStyle: "none",
									"::-webkit-scrollbar": {
										display: "none",
									},
								}}>
								<span style={styles.notesText}>{provider?.adminNotes}</span>
							</div>
						</ExpandibleContainer>
					</div>
				</Paper>
			</Slide>
		</TrapFocus>
	);
}

const styles = {
	selectedAvatar: {
		width: "10vh",
		height: "10vh",
		objectFit: "cover",
	},
	paper: {
		position: "absolute",
		backgroundColor: "transparent",
		bottom: "3vh",
		left: 0,
		m: 0,
		p: 2,
		borderWidth: 0,
		maxHeight: "10vh",
	},
	providerContainer: {
		display: "flex",
		alignItems: "center",
		gap: "5px 10px",
		backgroundColor: "white",
		borderRadius: "10px",
		border: "1px solid #a0a0a0",
		paddingRight: "10px",
		transitions: "width 0.5s",
		overflow: "hidden",
	},
	dataColumn: {
		maxWidth: "20vw",
		padding: "0px 0",
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		height: "10vh",
		justifyContent: "center",
		fontSize: "12px",
		fontWeight: "bold",
	},
	notesText: {
		fontSize: "12px",
		fontWeight: "normal",
		height: "100%",
		padding: "10px 0",
	},
	categoriesContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "flex-start",
		gap: "2px",
		fontSize: "12px",
		fontWeight: "normal",
		overflowX: "scroll",
		overflowY: "hidden",
		paddingBottom: "17px",
		width: "100%",
	},
	categoryChip: {
		// elipsis
		whiteSpace: "nowrap",
		backgroundColor: "#3f51b5",
		color: "white",
		borderRadius: "5px",
		padding: "2px 2px",
	},
};
