import { Check, Close } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { get } from "lodash";

export default function BooleanCheck(rowKey) {
	return {
		id: rowKey,
		type: "boolean",
		customRender: (row) => {
			const value = get(row, rowKey);
			return <Stack direction="row" justifyContent="center">{value ? <Check color="success" /> : <Close color="error" />}</Stack>;
		},
		customValue: (row) => get(row, rowKey),
	};
}
