import ModalFrame from "../../../components/form/ModalFrame";
import { Edit as EditIcon } from "@mui/icons-material";

import { useDisclosure } from "react-use-disclosure";
import { Stack, Tooltip, IconButton, Typography } from "@mui/material";
import { Form } from "react-final-form";
import { useLazyQuery, useMutation } from "@apollo/client";
import { MUTATION_editUser, QUERY_getUser, MUTATION_deleteImage } from "../GQLQueries";
import FormInput from "../../../components/form/FormInput";
import { useContext, useEffect } from "react";
import MediaGallery from "../../../components/MediaGallery";
import FormDateInput from "../../../components/form/FormDateInput";
import { setSnackBar } from "../../../redux/snackbar";
import { useDispatch } from "react-redux";
import { TableContext } from "../../../components/customDataGrid";
import FormSwitch from "../../../components/form/FormSwitch";
import TableIconButton from "../../../components/TableIconButton";

export default function EditForm({ selectedRows = [] }) {
	const dispatch = useDispatch();
	const { isOpen, open, close } = useDisclosure(false);
	const { reload } = useContext(TableContext);

	const [getUser, { data: { getUser: user } = {} }] = useLazyQuery(QUERY_getUser, { fetchPolicy: "network-only" });
	const [deleteImage] = useMutation(MUTATION_deleteImage, {
		fetchPolicy: "network-only",
	});

	useEffect(() => {
		if (isOpen && selectedRows[0]?.id) getUser({ variables: { id: selectedRows[0]?.id } });
	}, [isOpen, selectedRows, getUser]);
	// Line 29:14:  React Hook useEffect has a complex expression in the dependency array. Extract it to a separate variable so it can be statically checked  react-hooks/exhaustive-deps
	
	const [Edit] = useMutation(MUTATION_editUser, { fetchPolicy: "network-only" });

	const validate = (values) => {
		const errors = {};
		return errors;
	};

	let images = [];
	if (user?.profilePicture) images.push({ id: user?.id, url: user?.profilePicture, type: "profilePicture" });
	return (
		<>
			<TableIconButton onClick={open} disabled={selectedRows.length !== 1} icon={<EditIcon />} tooltip="Editar registro" />
			<Form
				initialValues={{
					id: user?.id,
					firstName: user?.firstName,
					lastName: user?.lastName,
					email: user?.email,
					phone: user?.phone,
					deletedAt: user?.deletedAt,
					disabled: user?.disabled,
				}}
				onSubmit={async (values, actions) => {
					const { data } = await Edit({ variables: values });

					if (data?.editUser)
						dispatch(
							setSnackBar({
								text: "Registro editado correctamente",
								severity: "success",
							})
						);
					else
						dispatch(
							setSnackBar({
								text: "Error al editar el registro",
								severity: "error",
							})
						);
					reload();
					close();
					// actions.reset({});
				}}
				validate={validate}
				render={({ handleSubmit, values }) => (
					<ModalFrame onSubmit={handleSubmit} {...{ isOpen, close }} title={`Editar perfil de ${user?.firstName} ${user?.lastName}`} buttonTexts={["Guardar", "Cancelar"]}>
						<Stack spacing={4}>
							<Stack gap={2} direction="row" justifyContent="space-between">
								<Stack spacing={4} width="100%">
									<Stack spacing={4} direction="row" justifyContent="space-between">
										<FormInput style={{ width: "100%" }} name="firstName" label="Nombres" type="text" autoFocus />
										<FormInput style={{ width: "100%" }} name="lastName" label="Apellido" type="text" />
									</Stack>
									<Stack spacing={4} direction="row" justifyContent="space-between">
										<FormInput style={{ marginTop: "auto", width: "100%" }} name="email" label="Email" type="text" />
										<FormSwitch name="disabled" label="Deshabilitado" />
									</Stack>
									<Stack spacing={4} direction="row" justifyContent="space-between">
										<FormInput style={{ marginTop: "auto", width: "100%" }} name="phone" label="Telefono" type="text" />
										<Stack spacing={0}>
											<Typography variant="h6" style={{ margin: "auto" }}>
												Fecha de eliminacion
											</Typography>
											<FormDateInput name="deletedAt" type="date" format={"dd/MM/yyyy"} cleareable /* disableFuture disablePast */ />
										</Stack>
									</Stack>
								</Stack>
								<Stack justifyContent={"center"} width="100%">
									<MediaGallery
										handleDelete={async (variables) => {
											const { data } = await deleteImage({ variables });
											if (data?.deleteMedia) dispatch(setSnackBar({ text: "Imagen eliminada", severity: "success" }));
											else dispatch(setSnackBar({ text: "Error al eliminar la imagen", severity: "error" }));
											getUser({ variables: { id: user.id } });
										}}
										items={images}
									/>
								</Stack>
							</Stack>
						</Stack>
					</ModalFrame>
				)}
			/>
		</>
	);
}
