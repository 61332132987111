import InboxIcon from "@mui/icons-material/Inbox";
import { Collapse, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { NavItem } from "./nav-item";

export const NavGroup = (props) => {
	const [open, isOpen] = useState(props.initialOpen || false);

	return (
		<>
			<NavItem icon={props.icon} title={props.title} onClick={() => isOpen((o) => !o)} collapse={open} />
			<Collapse
				sx={{
					marginLeft: 2,
					marginRight: 2,
					paddingLeft: 0,
					background: "#00000040",
				}}
				in={open}
				timeout="auto"
				unmountOnExit>
				{props.children}
			</Collapse>
		</>
	);
};

NavGroup.propTypes = {
	title: PropTypes.string,
};
