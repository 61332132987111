import { Button } from "@mui/material";
import { Circle } from "@react-google-maps/api";
import React, { useState } from "react";
import { Form } from "react-final-form";
import FormGoogleMapsAutocomplete from "../../components/form/FormGoogleMapsAutocomplete";
import FormSlider from "../../components/form/FormSlider";
import FormSwitch from "../../components/form/FormSwitch";
import MapView from "./components/MapView";
import ProviderMarker from "./components/ProviderMarker";
import ProviderInfoWindow from "./components/ProviderInfoWindow";
import { gql, useLazyQuery } from "@apollo/client";
import FormCategorySelector from "./components/FormCategorySelector";
import JsonView from "../../components/JsonView";

const GET_PROVIDERS = gql`
	query GetProviders($lat: Float!, $lng: Float!, $radius: Float!, $providerCategories: [JSON]) {
		providers: getProvidersInArea(lat: $lat, lng: $lng, radius: $radius, providerCategories: $providerCategories) {
			id
			firstName
			lastName
			profilePicture
			address {
				id
				lat
				lng
				inline
			}
			professionalProfile {
				id
				taskLookupSettings
				professionalCategories {
					id
					category {
						id
						titleLng
					}
					subcategory {
						id
						titleLng
					}
				}
			}
		}
	}
`;
export default function ProvidersMap() {
	const [mapCenter, setMapCenter] = useState({ lat: -34.603722, lng: -58.381592 });
	const [markers, setMarkers] = useState([]);
	const [selectedMarker, setSelectedMarker] = useState(null);

	const [getProviders] = useLazyQuery(GET_PROVIDERS, { networkPolicy: "network-only" });

	const onSubmit = async (values) => {
		let newCenter = mapCenter;
		if (values.address?.lat && values.address?.lng) {
			newCenter = { lat: values.address.lat, lng: values.address.lng };
			setMapCenter(newCenter);
		}

		// console.log(values.categoriesFilter);
		const { data: { providers } = {} } = await getProviders({
			networkPolicy: "network-only",
			fetchPolicy: "network-only",
			variables: {
				lat: newCenter.lat,
				lng: newCenter.lng,
				radius: values.radius,
				providerCategories: values.categoriesFilter,
			},
		});

		let newMarkers = providers
			.filter((p) => p.address?.lat && p.address?.lng)
			.map((provider) => {
				let marker = {
					...provider,
					workMarker: {
						lat: parseFloat(provider.professionalProfile?.taskLookupSettings?.lat),
						lng: parseFloat(provider.professionalProfile?.taskLookupSettings?.lng),
						workMarker: null,
					},
				};
				marker.adressMarker = { lat: parseFloat(provider.address.lat), lng: parseFloat(provider.address.lng) };
				if (provider.professionalProfile?.taskLookupSettings?.address?.lat && provider.professionalProfile?.taskLookupSettings?.address?.lng) {
					marker.workMarker = {
						lat: parseFloat(provider.professionalProfile?.taskLookupSettings?.address.lat),
						lng: parseFloat(provider.professionalProfile?.taskLookupSettings?.address.lng),
					};
				}
				return marker;
			});
		console.log(newMarkers.length);
		// .filter((p) => p.lat && p.lng);
		setMarkers(newMarkers);
	};
	console.log("SelectedCategories", selectedMarker?.professionalProfile);

	const getDistance = ({ lat, lng } = {}) => {
		if (!mapCenter.lat || !mapCenter.lng || !lat || !lng) return 0;
		const R = 6371; // Radius of the earth in km
		const dLat = (mapCenter.lat - lat) * (Math.PI / 180); // deg2rad below
		const dLon = (mapCenter.lng - lng) * (Math.PI / 180);
		const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos((lat * Math.PI) / 180) * Math.cos((mapCenter.lat * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const distance = R * c; // Distance in km
		return distance.toFixed(2);
	};

	return (
		<div>
			<h1>Mapa de expertos</h1>

			<Form
				onSubmit={onSubmit}
				initialValues={{
					radius: 10,
					viewWorkAdress: false,
					showHeadMap: false,
				}}
				render={({ handleSubmit, values, valid }) => (
					<div style={styles.container}>
						<div style={styles.settingsContainer}>
							<h2 style={styles.settingsTitle}>Settings</h2>
							<div style={styles.formContainer}>
								<FormGoogleMapsAutocomplete label="Direccion" name="address" />
								<FormSlider name="radius" label={`Radio: ${values.radius}`} min={1} max={60} />
								<Button onClick={handleSubmit} variant="contained" color="primary">
									Buscar
								</Button>
								{/* <JsonView data={values?.categoriesFilter} /> */}
								<div style={styles.mapViewSettings}>
									<h2 style={styles.settingsTitle}>View Settings</h2>
									<FormSwitch name="viewWorkAdress" label="Mostrar area de trabajo" inline />
								</div>
								<FormCategorySelector name="categoriesFilter" startSelected />
							</div>
						</div>
						<MapView values={values} center={mapCenter}>
							<Circle center={mapCenter} radius={values?.radius * 1000} />
							{markers.map((p) => (
								<ProviderMarker key={p.id} center={values?.viewWorkAdress ? p.workMarker : p.adressMarker} onClick={() => setSelectedMarker(p)} />
							))}
							<ProviderInfoWindow center={mapCenter} provider={selectedMarker} distance={getDistance(values?.viewWorkAdress ? selectedMarker?.workMarker : selectedMarker?.adressMarker)} />
						</MapView>
					</div>
				)}
			/>
		</div>
	);
}

const styles = {
	container: {
		display: "flex",
		// justifyContent: "space-between",
		gap: "20px",
	},
	settingsContainer: {
		width: "30%",
		border: "1px solid black",
		borderRadius: "10px",
		gap: "10px",
		padding: "10px",
		maxHeight: "80vh",
		overflowY: "auto",
		scrollbarWidth: "thin",
		scrollbarColor: "black",
	},
	settingsTitle: {
		// textAlign: "center",
		paddingBottom: "10px",
	},
	mapContainer: {
		height: "80vh",
		width: "70%",
		borderRadius: "10px",
	},
	formContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "10px",
	},
	mapViewSettings: {
		display: "flex",
		marginTop: "30px",
		paddingTop: "30px",
		borderTop: "1px solid black",
		flexDirection: "column",
	},
	selectedMarker: {
		backgroundColor: "white",
		width: "100%",
		height: "100%",
		borderRadius: "10px",
		border: "1px solid #a0a0a0",
		width: "150px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		gap: "5px",
		padding: "10px",
	},
	selectedAvatar: {
		width: "50px",
		height: "50px",
		borderRadius: "50%",
		backgroundColor: "gray",
	},
};
