import { Check, Close } from "@mui/icons-material";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { get } from "lodash";
import { severityPillRender, stringToColour } from "./components/customDataGrid/utils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { getInitials } from "./helpers/name";

export function isoDateRenderer(field) {
	return {
		type: "date",
		customValue: (row) => get(row, field),
		customRender: (row) => (get(row, field) ? format(new Date(get(row, field)), "dd/MM/yyyy HH:mm", { locale: es }) : null),
	};
}

export function priceRenderer(field) {
	return {
		type: "number",
		customValue: (row) => get(row, field, 0),
		customRender: (row) => new Intl.NumberFormat("es-AR", { style: "currency", currency: "ARS" }).format(get(row, field, 0)),
	};
}

export const booleanRenderer = (field) => {
	return {
		type: "boolean",
		customRender: (row) => {
			const value = get(row, field);
			return (
				<Stack
					direction="row"
					style={{
						width: "100px",
						// backgroundColor: 'red',
						// justifyContent: 'center',
					}}
					justifyContent="center">
					{value ? <Check color="success" /> : <Close color="error" />}
				</Stack>
			);
		},
		customValue: (row) => get(row, field),
	};
};

export const categoryRenderer = (field) => {
	return {
		customRender: (row) => {
			const value = get(row, field);
			if (!value) return null;
			return severityPillRender(value, stringToColour(value));
			// return value?.title?.es;
		},
		// customValue: (row) => get(row, field),
	};
};

// export const isHiddenRenderer = (id) => ({ id, label: "Oculto", customRender: (params) => get(params, id) ? <VisibilityIcon color='success' /> : <VisibilityOffIcon color='error' /> })
export const isHiddenRenderer = (id, inverted) => ({
	id,
	label: "Oculto",
	customRender: (params) => {
		const value = get(params, id);
		if (inverted) return value ? <VisibilityOffIcon color="error" /> : <VisibilityIcon color="success" />;
		return value ? <VisibilityIcon color="success" /> : <VisibilityOffIcon color="error" />;
	},
});

export const avatarRenderer = (field) => {
	return {
		customRender: (row) => {
			const user = get(row, field, row);
			return (
				<Box sx={{ alignItems: "center", display: "flex" }}>
					<Avatar src={user?.profilePicture} sx={{ mr: 2 }}>
						{getInitials(`${user?.firstName} ${user?.lastName}`)}
					</Avatar>
					<Typography color="textPrimary" variant="body1">
						{user?.firstName} {user?.lastName}
					</Typography>
				</Box>
			);
		},
		customValue: (row) => {
			const user = get(row, field, row);
			return `${user?.firstName} ${user?.lastName}`;
		},
	};
};
