import React, { useEffect } from "react";
import { CustomDataGrid } from "../../components";
import {
  useQuery,
  gql
} from "@apollo/client";
import { useDispatch } from "react-redux";
import { setTitle } from "../../redux/layout";

export default function Reports(props) {
  const dispatch = useDispatch()
  useEffect(() => dispatch(setTitle("Reportes")), [dispatch])

  const { loading, error, data } = useQuery(gql`
  query getAllTasks {
    getAllTasks {
      title
      description
      id
    }
  }
`);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error</p>;

  return <div>
    <h3>Listado de tareas</h3>
    <CustomDataGrid
      columns={[
        { field: "id", headerName: "ID", hide: false },
        { field: "title", headerName: "Título", width: 150 },
        { field: "description", headerName: "Descripcion", width: 150 }
      ]}
      rows={data.getAllTasks} />
  </div>
}

