import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale'
import { Box, Card, Typography, Grid, } from '@mui/material';
import { CustomDataGrid } from '../../../components';
import { promotionCodes_getAll } from '../GQLQueries';
import { useLazyQuery } from '@apollo/client';
import customFilterPanelForTable from '../../../components/customFilterPanelForTable';
import AddForm from './AddForm';
import DeleteConfirmation from './DeleteConfirmation';
import EditForm from './EditForm';
import { severityPillRender } from '../../../components/customDataGrid/utils';

export const CustomerListResults = (props) => {

  const [runQuery, { data, loading }] = useLazyQuery(promotionCodes_getAll, { fetchPolicy: "network-only" });
  const rows = data?.promotionCodes_getAll || []

  const loadData = () => {
    runQuery()
  }
  useEffect(loadData, [runQuery])

  const statusNumber = (status) => [
    "PUBLISHED", // 0
    "BUDGETED", // 1
    "HIRED", // 2
    "FINALIZED", // 3
    "QUALIFIED", // 4
    "COMPLETED" // 5
  ].findIndex(s => s === status)

  const dateRenderer = row => format(new Date(Number(row.createdAt)), 'dd/MM/yyyy HH:mm', { locale: es })
  const dateStringValue = row => format(new Date(Number(row.createdAt)), "yyyy-MM-dd'T'HH:mm:ss.mmm'Z'", { locale: es })

  return (<>
    <Grid container spacing={3} >
      <Grid {...{ item: true, xl: 8 }} ><Typography sx={{ m: 1 }} variant="h4">Códigos promocionales</Typography></Grid>
    </Grid>
    <Card {...props}>
      {console.log(rows)}
      <Box sx={{ minWidth: 1050 }}>
        <CustomDataGrid
          initialFilters={[]}
          reload={loadData}
          loading={loading}
          filterPanel={customFilterPanelForTable}
          actions={[AddForm, EditForm, DeleteConfirmation]}
          hideFooterSelectedRowCount
          checkboxSelection
          rows={rows}
          columns={[
            { id: "id", label: "ID", type: "number", hide: true },
            { id: "code", label: "Código", customRender: (row) => severityPillRender(row.code) },
            { id: "description", label: "Descripción" },
            { id: "enabled", label: "Habilitado", type: "boolean" },
            { id: "tasks.length", label: "Tareas creadas", type: "number" },
            { id: "tasks.length.budgeted", label: "Tareas presupuestadas", type: "number", customValue: row => row.tasks?.filter(t => statusNumber(t.status) >= 1).length },
            { id: "tasks.length.hired", label: "Tareas contratadas", type: "number", customValue: row => row.tasks?.filter(t => statusNumber(t.status) >= 2).length },
            { id: "tasks.length.qualified", label: "Tareas calificadas", type: "number", customValue: row => row.tasks?.filter(t => statusNumber(t.status) >= 4).length },
            { id: "tasks.length.deleted", label: "Tareas eliminadas", type: "number", customValue: row => row.tasks?.filter(t => t.deletedAt).length },
            { id: "createdAt", label: "Fecha de creación", type: "date", customValue: dateStringValue, customRender: dateRenderer },
          ]} />
      </Box>
    </Card>
  </>);
};