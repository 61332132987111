import { gql, useLazyQuery } from "@apollo/client";
import FormSelect from "./Select";
import { useEffect } from "react";

export default function FormSubcategorySelect({ categoryId, ...props }) {
	const GQLGetSubcategoriesForSelect = gql`
		query getSubcategoriesForSelect($categoryId: Int!) {
			subcategories: getSubcategories(categoryId: $categoryId) {
				id
				titleLng
			}
		}
	`;
	const [getSubcategories, { data: { subcategories = [] } = {} }] = useLazyQuery(GQLGetSubcategoriesForSelect, { fetchPolicy: "network-only" });

	useEffect(() => {
		if (categoryId) getSubcategories({ variables: { categoryId } });
	}, [categoryId, getSubcategories]);

	return <FormSelect {...props} disabled={!props.value && !subcategories.length} options={subcategories.map(({ id, titleLng }) => ({ value: id, label: titleLng }))} />;
}
