import { gql, useMutation } from "@apollo/client";
import { Update } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useContext } from "react";
import { Form } from "react-final-form";
import { useDisclosure } from "react-use-disclosure";
import TableIconButton from "../../../components/TableIconButton";
import { TableContext } from "../../../components/customDataGrid";
import FormDateInput from "../../../components/form/FormDateInput";
import FormTimeInput from "../../../components/form/FormTimeInput";
import ModalFrame from "../../../components/form/ModalFrame";

const GQLReschedulePackage = gql`
	mutation reschedulePackage($packageId: Int!, $date: DateTime) {
		rescheduled: reschedulePackage(packageId: $packageId, date: $date)
	}
`;

export default function RescheduleAction({ selectedRows = [] }) {
	const { isOpen, open, close } = useDisclosure();
	const { reload } = useContext(TableContext);
	const selectedPkg = selectedRows[0];
	const [ReschedulePackage] = useMutation(GQLReschedulePackage, { fetchPolicy: "network-only" });

	const handleSubmit = async ({ workDate, workTime }) => {
		const date = workDate;
		date.setHours(workTime.getHours());
		date.setMinutes(workTime.getMinutes());
		const res = await ReschedulePackage({ variables: { packageId: selectedPkg.id, date } });
		await reload();
		close();
	};

	return (
		<>
			<TableIconButton onClick={open} disabled={selectedRows.length !== 1} icon={<Update />} tooltip={`Reprogramar paquete`} />
			<Form
				initialValues={{ id: selectedPkg?.id, workDate: new Date(selectedPkg?.workDate), workTime: new Date(selectedPkg?.workDate) }}
				onSubmit={handleSubmit}
				// validate={validate}
				render={({ handleSubmit, values }) => (
					<ModalFrame onSubmit={handleSubmit} {...{ isOpen, close }} title={`Editar notas`} buttonTexts={["Guardar", "Cancelar"]}>
						<Stack spacing={4} direction={"row"}>
							<FormDateInput name="workDate" label="Fecha del trabajo" disablePast required />
							<FormTimeInput name="workTime" label="Hora del trabajo" required />
						</Stack>
					</ModalFrame>
				)}
			/>
		</>
	);
}
