import React from "react";
import { Stack, Card, CardMedia, CardActions, IconButton, List, Modal, Box, Button, Typography, CardContent } from "@mui/material";
import { Delete } from "@mui/icons-material";
import "react-image-gallery/styles/css/image-gallery.css";
import { useDisclosure } from "react-use-disclosure";
import CardMediaWithImgSet from "./CardMediaWithImgSet";
import MediaDisplayModal from "./MediaDisplayModal";

export default function MediaGallery({ handleDelete, items = [], bigMode = false }) {
	const { isOpen: confirmModalOpen, open: openConfirm, close: closeConfirm } = useDisclosure(false);
	const [deleteImage, setDeleteImage] = React.useState(null);
	try {
		const [selectedIndex, setSelectedIndex] = React.useState(-1);
		const handleOpen = (index) => setSelectedIndex(index);
		const handleClose = () => setSelectedIndex(-1);
		const getTypeString = (type, isVideo) => {
			if (type === "profilePicture") return "Foto de perfil";
			if (type === "professionalMedia") return "Imagen del perfil profesional";
			if (type === "taskMedia") return isVideo ? "Video de la tarea" : "Imagen de la tarea";
			if (type === "messageMedia") return isVideo ? "Video del mensaje" : "Imagen del mensaje";
			else return type;
		};

		return (
			<>
				<List>
					<Stack scrollable maxHeight={200} justifyContent="center" flexWrap="wrap" gap={1} direction="row">
						{items.map((item, index) => {
							const srcSet = [];
							if (item.thumbnail) srcSet.push(item.thumbnail);
							srcSet.push(`${item.url}400`, item.url, "https://bitsofco.de/content/images/2018/12/broken-1.png");
							return (
								<Card sx={{ position: "relative", width: bigMode ? 400 : 200, height: bigMode ? 400 : 200 }}>
									<CardActions
										sx={{
											p: 0,
											background: "transparent",
											position: "absolute",
											display: "flex",
											right: 0,
											top: 0,
										}}
										disableSpacing>
										<IconButton
											sx={{
												"&:hover": { background: "rgba(225,225,225,0.9)" },
												padding: 0.5,
												margin: 0.5,
												borderRadius: "50%",
												background: "rgba(255,255,255,0.9)",
												color: "white",
											}}
											onClick={() => {
												setDeleteImage(item);
												openConfirm();
											}}>
											<Delete style={{ color: "red" }} fontSize="medium" />
										</IconButton>
									</CardActions>
									<CardMediaWithImgSet onClick={() => handleOpen(index)} height="100%" srcSet={srcSet} alt={JSON.stringify(item)} />
									{/* <CardMedia component={"img"} onClick={() => handleOpen(index)} height="100%" image={item.thumbnail || (item.url+"200")} alt={JSON.stringify(item)} /> */}
									<CardContent sx={{ position: "absolute", bottom: 0, left: 0, right: 0, background: "rgba(0,0,0,0.5)", color: "white" }}>
										{/* {"" + !!item?.thumbnail} */}
										<Typography variant="body2">Tipo: {getTypeString(item.type, !!item?.thumbnail)}</Typography>
									</CardContent>
								</Card>
							);
						})}
					</Stack>
				</List>
				<MediaDisplayModal src={items[selectedIndex]?.url} isVideo={!!items[selectedIndex]?.thumbnail} onClose={handleClose} />

				<Modal open={confirmModalOpen} onClose={closeConfirm}>
					<Box
						sx={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							boxShadow: 24,
							backgroundColor: "white",
							p: 4,
							borderRadius: 2,
						}}>
						<Typography variant="h6" component="h2" gutterBottom>
							¿Está seguro que desea eliminar la imagen?
						</Typography>
						<Stack direction="row" justifyContent="flex-end" gap={2} marginTop={2}>
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									handleDelete({ [deleteImage.type + "Ids"]: deleteImage.id });
									closeConfirm();
									setDeleteImage(null);
								}}>
								Si
							</Button>
							<Button variant="contained" color="error" onClick={closeConfirm}>
								No
							</Button>
						</Stack>
					</Box>
				</Modal>
			</>
		);
	} catch (e) {
		console.log(e);
		return null;
	}
}
