import { Stack } from "@mui/material";
import FormInput from "./FormInput";

export default function TranslateFields(key, labelPrefix = "Título en") {
	return (
		<Stack spacing={2} direction={{ xs: "column", sm: "row" }} justifyContent="space-between">
			<FormInput name={`${key}.es`} label={`${labelPrefix} español`} />
			<FormInput name={`${key}.en`} label={`${labelPrefix} ingles`} />
			<FormInput name={`${key}.pt`} label={`${labelPrefix} portugues`} />
		</Stack>
	);
}
