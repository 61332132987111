import React from "react";
import { Box, Card, Typography, Stack } from "@mui/material";
import { CustomDataGrid, CustomDateRangePicker } from "../../../components";
import CustomFilterPanelForTable from "../../../components/customFilterPanelForTable";
import CreateAction from "./CreateAction";
import { gql, useQuery } from "@apollo/client";
import { isoDateRenderer, categoryRenderer } from "../../../tableUtils";
import EditAction from "./EditAction";
import DeleteAction from "./DeleteAction";


export const ResultList = (props) => {
	const query = gql`
		query getAllCategories {
			subcategories: getAllSubcategories {
				id
				title {
					id
					es
					en
					pt
				}
				categoryId
				category {
					id
					title {
						id
						es
						en
						pt
					}
				}
				createdAt
				deletedAt
			}
		}
	`;
	const { data: { subcategories = [] } = {}, loading, refetch: runQuery } = useQuery(query, { fetchPolicy: "network-only" });

	return (
		<>
			<Stack direction="row" justifyContent="space-between">
				<Typography sx={{ m: 1 }} variant="h4">
					Listado de Subcategorias
				</Typography>
				<CustomDateRangePicker />
			</Stack>
			<Card {...props}>
				<Box sx={{ minWidth: 1050 }}>
					<CustomDataGrid
						filterPanel={CustomFilterPanelForTable}
						reload={runQuery}
						loading={loading}
						actions={[CreateAction, EditAction, DeleteAction]}
						hideFooterSelectedRowCount
						checkboxSelection
						label=""
						rows={subcategories}
						columns={[
							{ id: "id", label: "ID", type: "number" },
							{ id: "title.es", label: "Titulo" },
							{ id: "category.title.es", label: "Categoria", ...categoryRenderer("category.title.es") },
							{ id: "createdAt", label: "Fecha de creacion", ...isoDateRenderer("createdAt") },
							{ id: "deletedAt", label: "Fecha de eliminacion", ...isoDateRenderer("deletedAt")}
						]}
					/>
				</Box>
			</Card>
		</>
	);
};
