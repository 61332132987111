import { Add } from "@mui/icons-material";
import ModalFrame from "../../../components/form/ModalFrame";

import { gql, useMutation, useQuery } from "@apollo/client";
import { Stack } from "@mui/material";
import { useContext } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useDisclosure } from "react-use-disclosure";
import TableIconButton from "../../../components/TableIconButton";
import { TableContext } from "../../../components/customDataGrid";
import FormDateInput from "../../../components/form/FormDateInput";
import FormSelect from "../../../components/form/Select";
import TranslateFields from "../../../components/form/TranslateFieldsBundle";
import { setSnackBar } from "../../../redux/snackbar";

export default function CreateAction({ selectedRows = [] }) {
	const { isOpen, open, close } = useDisclosure(false);
	const { reload } = useContext(TableContext);
	const dispatch = useDispatch();

	const GQLGetCategoriesForSelect = gql`
		query getCategoriesForSelect {
			categories: getAllCategories {
				id
				titleLng
			}
		}
	`;

	const { data: { categories = [] } = {} } = useQuery(GQLGetCategoriesForSelect, { fetchPolicy: "network-only" });

	const GQLSaveSubcategory = gql`
		mutation saveSubcategory($title: TranslationInput!, $categoryId: Int!, $id: Int, $deletedAt: DateTime) {
			subcategoryCreated: saveSubcategory(title: $title, categoryId: $categoryId, id: $id, deletedAt: $deletedAt)
		}
	`;

	const [SaveSubcategory] = useMutation(GQLSaveSubcategory, { fetchPolicy: "network-only" });

	const onSubmit = async ({ title, categoryId, deletedAt }) => {
		const { data: { subcategoryCreated = false } = {}, errors } = await SaveSubcategory({ variables: { title, categoryId, deletedAt } });
		console.log({ errors, subcategoryCreated });
		if (errors || !subcategoryCreated) dispatch(setSnackBar({ text: "Error al editar.", severity: "error" }));
		else if (subcategoryCreated) {
			dispatch(setSnackBar({ text: "Edicion exitosa." }));
			close();
			reload();
		}
	};

	const validate = (values) => {
		const errors = {};
		if (!values.title) errors.title = "Required";
		return errors;
	};

	return (
		<>
			<TableIconButton onClick={open} icon={<Add />} tooltip="Crear nueva subcategoria" />
			<Form
				onSubmit={onSubmit}
				initialValues={{}}
				validate={validate}
				render={({ handleSubmit }) => (
					<ModalFrame onSubmit={handleSubmit} {...{ isOpen, close }} title="Crear subcategoria" buttonTexts={["Enviar", "Cancelar"]}>
						<Stack spacing={4}>
							{TranslateFields("title")}
							<FormSelect name="categoryId" label="Categoria" options={categories.map(({ id, titleLng }) => ({ value: id, label: titleLng }))} />

							<FormDateInput name="deletedAt" label="Fecha de eliminacion" type="date" format={"dd/MM/yyyy"} cleareable />
						</Stack>
					</ModalFrame>
				)}
			/>
		</>
	);
}
