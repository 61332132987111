import { Avatar, Button, Grid } from "@mui/material";
import React from "react";
import Attribute from "./Attribute";
import { useNavigate } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { setSnackBar } from "../../../redux/snackbar";
import { useDispatch } from "react-redux";

export default function BudgetList({ task = {}, budgets = [], refresh }) {
	//sort hired: true first
	budgets = budgets.sort((a, b) => {
		if (a.hired && !b.hired) return -1;
		if (!a.hired && b.hired) return 1;
		return 0;
	});
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const setAmountPoints = (amount) => {
		// transform 123456789 to 123.456.789
		return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	};

	const HIRE_BUDGET = gql`
		mutation hireBudget($taskOwnerId: Int!, $budgetId: Int!) {
			hireBudget(taskOwnerId: $taskOwnerId, budgetId: $budgetId)
		}
	`;

	const [hireBudget] = useMutation(HIRE_BUDGET, { fetchPolicy: "network-only" });

	return (
		<div
			style={{
				alignItems: "center",
				overflowY: "scroll",
				height: "calc(90vh - 40px)",
			}}>
			{!budgets.length ? (
				<h3>Esta tarea aun no ha recibido presupuestos</h3>
			) : (
				budgets.map((budget) => {
					return (
						<div style={styles.taskContainer}>
							<img alt={budget.owner?.id} src={budget.owner?.profilePicture} style={styles.taskMedia} />
							<Grid container mr={1}>
								<Grid xs={6} style={styles.budgetInfo}>
									<h3>
										{budget.owner?.firstName} {budget.owner?.lastName} {budget.owner?.verified && "✅"}
									</h3>
									<p style={{ whiteSpace: "break-spaces" }}>{budget.comment}</p>
									<div style={styles.chipContainer}>
										{!!budget.hired && <div style={styles.chip()}>Contratado</div>}
										{!!budget.warrantyMonths && (
											<div style={styles.chip("#828DF8")}>
												{budget.warrantyMonths} mes{budget.warrantyMonths > 1 && "es"} de garantia
											</div>
										)}
										{/* {!!budget.promotionCode && <div style={styles.chip("red")}>{budget.promotionCode?.code}</div>} */}
									</div>
								</Grid>
								<Grid item sx={6} style={styles.budgetInfo}>
									<Attribute label="Monto" value={`$${setAmountPoints(budget.amount)}`} />
									<Attribute label="ID del presupuesto" value={budget.id} />
									<Attribute label="ID del experto" value={budget.owner?.id} />
								</Grid>
								{task.status === "BUDGETED" && (
									<Grid
										xs={12}
										style={{
											display: "flex",
											justifyContent: "flex-end",
											padding: "10px",
										}}>
										<Button
											variant="contained"
											color="primary"
											onClick={async () => {
												const { data = {}, errors } = await hireBudget({ variables: { taskOwnerId: task.ownerId, budgetId: budget.id } });
												refresh()
												if (errors) dispatch(setSnackBar({ text: "Ocurrio un error al contratar el presupuesto", severity: "error" }));
											}}>
											Contratar
										</Button>
									</Grid>
								)}
							</Grid>
						</div>
					);
				})
			)}
		</div>
	);
}

const styles = {
	taskContainer: {
		display: "flex",
		marginBottom: "10px",
		flexDirection: "row",
		justifyContent: "space-between",
		borderRadius: "10px",
		overflow: "hidden",
		backgroundColor: "#5048E530",
		// alignItems: "center",
	},
	taskMedia: {
		width: "150px",
		height: "150px",
		// resize modes: "cover", "contain", "stretch", "repeat", "center"
		objectFit: "cover",
		// backgroundColor: "cover",
	},
	budgetInfo: {
		// backgroundColor: "green",
		padding: "10px",
		flex: 1,
		marginLeft: "10px",
	},
	chipContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		// backgroundColor: "blue",
	},
	chip: (backgroundColor = "#90DF30") => ({
		backgroundColor,
		padding: "4px",
		fontSize: "12px",
		borderRadius: "4px",
		marginRight: "5px",
	}),
};
