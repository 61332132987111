import { createSlice } from '@reduxjs/toolkit'

const counterSlice = createSlice({
    name: 'snackbar',
    initialState: {
        text: "",
        severity: ""
    },
    reducers: {
        setSnackBar: (state, action) => {
            state.text = action.payload.text
            state.severity = action.payload.severity || "success"
        }
    }
})

export const selectSnackBar = (state) => state.snackbar;

export const { setSnackBar } = counterSlice.actions
export default counterSlice.reducer;
