import { Add } from "@mui/icons-material";
import ModalFrame from "../../../components/form/ModalFrame";

import { gql, useMutation } from "@apollo/client";
import { Stack } from "@mui/material";
import { useContext } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useDisclosure } from "react-use-disclosure";
import TableIconButton from "../../../components/TableIconButton";
import { TableContext } from "../../../components/customDataGrid";
import FormDateInput from "../../../components/form/FormDateInput";
import FormInput from "../../../components/form/FormInput";
import FormSwitch from "../../../components/form/FormSwitch";
import TranslateFields from "../../../components/form/TranslateFieldsBundle";
import { setSnackBar } from "../../../redux/snackbar";

export default function CreateAction({ selectedRows = [] }) {
	const { isOpen, open, close } = useDisclosure(false);
	const { reload } = useContext(TableContext);
	const dispatch = useDispatch();

	const GQLSaveCategory = gql`
		mutation saveCategory($title: TranslationInput!, $hidden: Boolean, $image: String, $cardColor: String, $deletedAt: DateTime) {
			categoryCreated: saveCategory(title: $title, hidden: $hidden, image: $image, cardColor: $cardColor, deletedAt: $deletedAt)
		}
	`;

	const [SaveCategory] = useMutation(GQLSaveCategory, { fetchPolicy: "network-only" });

	const onSubmit = async ({ title, hidden }) => {
		const { data: { categoryCreated = false } = {}, errors } = await SaveCategory({ variables: { title, hidden } });
		if (errors || !categoryCreated) dispatch(setSnackBar({ text: "Error al crear.", severity: "error" }));
		else if (categoryCreated) {
			dispatch(setSnackBar({ text: "Creacion exitosa." }));
			reload()
			close();
		}
	};

	const validate = (values) => {
		const errors = {};
		if (!values.title) errors.title = "Required";
		return errors;
	};

	return (
		<>
			<TableIconButton onClick={open} icon={<Add />} tooltip="Crear nueva categoria" />
			<Form
				onSubmit={onSubmit}
				initialValues={{ title: { es: "", en: "", pt: "" }, cardColor: "#828DF8" }}
				validate={validate}
				render={({ handleSubmit, values, form }) => (
					<ModalFrame onSubmit={handleSubmit} {...{ isOpen, close }} title="Crear categoria" buttonTexts={["Enviar", "Cancelar"]}>
						{/* {JSON.stringify(values.cardColor)} */}
						<Stack spacing={4}>
							{TranslateFields("title")}
							<Stack alignContent={"center"} alignItems={"center"} justifyContent={"space-around"} spacing={2} flexDirection={"row"}>
								<FormInput style={{ display: "flex", minWidth: 200 }} name="cardColor" label="Color" type="color" />
								<FormSwitch name="hidden" label="Oculto" />

								<FormDateInput name="deletedAt" label="Fecha de eliminacion" type="date" format={"dd/MM/yyyy"} cleareable />
							</Stack>
							<Stack style={{ width: "100%" }} alignContent={"center"} alignItems={"center"} justifyContent={"center"} spacing={2}>
								<FormInput style={{ width: "100%" }} name="image" label="Imagen" type="text" />
								{!values.image && (
									<input
										type="file"
										accept="image/*"
										onChange={async (e) => {
											const token = window.localStorage.getItem("sessionToken");
											const formData = new FormData();
											formData.append("image", e.target.files[0]);
											const res = await fetch(`${process.env.REACT_APP_API_URI}/api/media/uploadMedia`, {
												method: "POST",
												headers: { Authorization: `Bearer ${token}` },
												body: formData,
											});
											const data = await res.json();
											if (data?.url) form.change("image", data.url);
										}}
									/>
								)}
							</Stack>
							{!!values.image && <img src={values.image} alt="banner" style={{ height: "200px", width: "auto", objectFit: "contain" }} />}
						</Stack>
					</ModalFrame>
				)}
			/>
		</>
	);
}
