import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material';

export default function DashboardDataCard({ label, mainValue, secondaryValue, icon: Icon }) {

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="overline">{label}</Typography>
            <Typography color="textPrimary" variant="h4">{mainValue}</Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: 'success.main',
                height: 56,
                width: 56
              }}>
              <Icon fontSize='large' />
            </Avatar>
          </Grid>
        </Grid>
        <Box sx={{ alignItems: 'center', display: 'flex', pt: 2 }}>
          <Typography variant="body2" sx={{ mr: 1 }}>{secondaryValue}</Typography>
        </Box>
      </CardContent>
    </Card>
  )
};
