import { gql } from "@apollo/client"

export const graphQLQuery = gql`query($dateFrom: String!, $dateTo: String!) {
    getAllQualifications(dateFrom: $dateFrom, dateTo: $dateTo ) {
        id
        stars
        comment
        acquisitionMethod
        acknowledgments {
            id
            title
        }
        owner {
            id
            firstName
            lastName
            profilePicture
        }
        task {
            id
            title
            description
            createdAt
            status
            deleted
            deletedAt
            paid
            category {
                titleLng(lang: es)
            }
            subcategory {
                titleLng(lang: es)
            }
            promotionCode {
                code
            }
        }
        package {
            id
            title
            description
            price
            workDate
            media {
                uri
                mediaType
                # thumbnail
            }
            address {
                inline
            }
            category {
                titleLng(lang: es)
            }
            subcategory {
                titleLng(lang: es)
            }
            applications {
                id
                assigned
                owner {
                    id
                    firstName
                    lastName
                    profilePicture
                }
            }
        }
        budget {
            id
            amount
            comment
            owner {
                id
                firstName
                lastName
                profilePicture
            }
        }
    }
}`


export const sendNotificationsMutation = gql`mutation sendNotifications($ids: [Int]!, $title: String!, $subtitle: String!) {
    sendCustomNotification(ids: $ids, title: $title, subtitle: $subtitle) 
}`