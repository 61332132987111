import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import React from "react";

export default function CustomSwitch({ name, label, onClick, value = false, onChange, onBlur, ...props }) {

    return <FormGroup style={props.style}>
        <FormControlLabel
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            labelPlacement="top"
            control={<Switch
                defaultChecked={value}
                defaultValue={value}
                variant="outlined"
                color="primary"
                onClick={onClick}
            />}
            label={label}
        />
    </FormGroup>
}