import ModalFrame from "../../../components/form/ModalFrame";
import {  Edit } from "@mui/icons-material";

import { useDisclosure } from "react-use-disclosure";
import { Stack, Tooltip, IconButton } from "@mui/material";
import { Form } from "react-final-form";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { setSnackBar } from "../../../redux/snackbar";
import TranslateFields from "../../../components/form/TranslateFieldsBundle";
import FormSelect from "../../../components/form/Select";
import { useContext } from "react";
import { TableContext } from "../../../components/customDataGrid";
import FormDateInput from "../../../components/form/FormDateInput";
import TableIconButton from "../../../components/TableIconButton";

export default function EditAction({ selectedRows = [] }) {
	const { isOpen, open, close } = useDisclosure(false);
	const { reload } = useContext(TableContext);
	const dispatch = useDispatch();

	const GQLGetCategoriesForSelect = gql`
		query getCategoriesForSelect {
			categories: getAllCategories {
				id
				titleLng
			}
		}
	`;

	const { data: { categories = [] } = {} } = useQuery(GQLGetCategoriesForSelect, { fetchPolicy: "network-only" });

	const GQLSaveSubcategory = gql`
		mutation saveSubcategory($title: TranslationInput!, $categoryId: Int!, $id: Int, $deletedAt: DateTime) {
			subcategoryCreated: saveSubcategory(title: $title, categoryId: $categoryId, id: $id, deletedAt: $deletedAt)
		}
	`;

	const [SaveSubcategory] = useMutation(GQLSaveSubcategory, { fetchPolicy: "network-only" });

	const onSubmit = async ({ id, title, categoryId, deletedAt }) => {

		const { data: { subcategoryCreated = false } = {}, errors } = await SaveSubcategory({ variables: { id, title, categoryId, deletedAt } });
		if (errors || !!subcategoryCreated) dispatch(setSnackBar({ text: "Error al editar.", severity: "error" }));
		else if (!subcategoryCreated) {
			dispatch(setSnackBar({ text: "Edicion exitosa." }));
			close();
			reload();
		}
	};

	const validate = (values) => {
		const errors = {};
		if (!values.title) errors.title = "Required";
		return errors;
	};

	return (
		<>
			<TableIconButton onClick={open} disabled={selectedRows.length !== 1} icon={<Edit />} tooltip="Editar subcategoria" />
			<Form
				onSubmit={onSubmit}
				initialValues={{
					...selectedRows[0],
					title: {
						id: selectedRows[0]?.title?.id,
						es: selectedRows[0]?.title?.es,
						en: selectedRows[0]?.title?.en,
						pt: selectedRows[0]?.title?.pt,
					},
				}}
				validate={validate}
				render={({ handleSubmit }) => (
					<ModalFrame onSubmit={handleSubmit} {...{ isOpen, close }} title="Editar subcategoria" buttonTexts={["Enviar", "Cancelar"]}>
						<Stack spacing={4}>
							{TranslateFields("title")}
							<FormSelect name="categoryId" label="Categoria" options={categories.map(({ id, titleLng }) => ({ value: id, label: titleLng }))} />

							<FormDateInput name="deletedAt" label="Fecha de eliminacion" type="date" format={"dd/MM/yyyy"} cleareable />
						</Stack>
					</ModalFrame>
				)}
			/>
		</>
	);
}
