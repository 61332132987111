import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale'
import { Box, Avatar, Card, Typography, Stack, } from '@mui/material';
import { getInitials } from '../../../helpers/name';
import { CustomDataGrid, CustomDateRangePicker } from '../../../components';
import { getAllMessages } from '../GQLQueries';
import { useLazyQuery } from '@apollo/client';
import CustomFilterPanelForTable from '../../../components/customFilterPanelForTable';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackBar } from '../../../redux/snackbar';
import { get } from 'lodash';
import { tableCategorySbucategoryRender } from '../../../helpers/tableRenders';
import { selectCompleteDate } from '../../../redux/layout';

export const CustomerListResults = (props) => {

  const [dateFrom, dateTo] = useSelector(selectCompleteDate);

  const [runQuery, { error, data, loading }] = useLazyQuery(getAllMessages, { variables: { dateFrom, dateTo }, fetchPolicy: "network-only" });
  const rows = data?.getTaskActivities || []

  const dispatch = useDispatch()
  useEffect(() => { if (error) dispatch(setSnackBar({ text: `Error en el reporte: ${error.graphQLErrors[0].message}.`, severity: "error" })) }, [error, dispatch])


  const loadData = () => {
    if (dateFrom && dateTo) {
      runQuery({ variables: { dateFrom, dateTo } })
    }
  }

  useEffect(loadData, [dateFrom, dateTo, runQuery])


  const avatarRenderer = (user) => {
    if (!user) return null
    return <Box sx={{ alignItems: 'center', display: 'flex' }}>
      <Avatar src={user.profilePicture} sx={{ mr: 2 }}>{getInitials(`${user.firstName} ${user.lastName}`)}</Avatar>
      <Typography color="textPrimary" variant="body1">{user.firstName} {user.lastName}</Typography>
    </Box>
  }
  const dateRenderer = row => format(new Date(Number(row.createdAt)), 'dd/MM/yyyy HH:mm', { locale: es })
  const dateStringValue = row => format(new Date(Number(row.createdAt)), "yyyy-MM-dd'T'HH:mm:ss.mmm'Z'", { locale: es })

  return (<>
    <Stack direction='row' justifyContent='space-between'>
      <Typography sx={{ m: 1 }} variant="h4">Reporte de actividad en tareas</Typography>
      <CustomDateRangePicker />
    </Stack>
    <Card {...props}>
      <Box sx={{ minWidth: 1050 }}>
        <CustomDataGrid
          filterPanel={CustomFilterPanelForTable}
          reload={loadData}
          loading={loading}
          hideFooterSelectedRowCount
          checkboxSelection
          label=""
          rows={rows}
          columns={[
            { id: "id", label: "ID", type: "number", hide: true },
            { id: "taskId", label: "ID Tarea", type: "number", hide: false },
            { id: "task.title", label: "Titulo Tarea" },
            { id: "category/subcategory", label: "Categoria", customValue: row => tableCategorySbucategoryRender(row.task), customRender: row => tableCategorySbucategoryRender(row.task) },
            { id: "owner", label: "Creador del evento", customValue: row => `${get(row, "owner.firstName")} ${get(row, "owner.lastName")}`, customRender: row => avatarRenderer(row.owner) },
            { id: "createdAt", label: "Fecha de creación", type: "date", customValue: dateStringValue, customRender: dateRenderer },
            { id: "action", label: "Evento de la tarea", },
          ]} />
      </Box>
    </Card>
  </>);
};