import { gql } from "@apollo/client"

const profileChipFields = `{
    id
    title
    completeTitle {
        id
        es
        en
        pt
    }
    enabled
    updatedAt
    createdAt
    deletedAt
}`

export const getAllProviderChips = gql`query {
    chips: getAllProfileChips ${profileChipFields}
}`

export const add = gql`mutation add($title: TranslationInput!, $enabled: Boolean!) {
    addProfileChip(title: $title, enabled: $enabled) ${profileChipFields}
}`

export const edit = gql`mutation edit($id: Int!, $title: TranslationInput!, $enabled: Boolean!) {
    editProfileChip(id: $id, title: $title, enabled: $enabled) ${profileChipFields}
}`

// export const deleteMutation = gql`mutation delete($ids: [Int]!) {
//     promotionCodes_delete(ids: $ids)
// }`