import { gql, useLazyQuery } from "@apollo/client";
import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function GlobalSearch() {
	const [inputValue, setInputValue] = React.useState("");
	const [options, setOptions] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

	const GLOBAL_SEARCH = gql`
		query GlobalSearch($search: String!) {
			results: globalSearch(search: $search)
		}
	`;

	const [globalSearch, { data: { results = [] } = {}, error }] = useLazyQuery(GLOBAL_SEARCH, { fetchPolicy: "network-only" });

	useEffect(() => {
		let timeout = null;
		// Call the global search query if input value is not empty && 3sec has passed since last call, to avoid too many calls
		if (inputValue !== "") {
			setLoading(true);
			timeout = setTimeout(async () => {
				const { data: { results = [] } = {}, error } = await globalSearch({ variables: { search: inputValue } });
				if (!error) setOptions(results);
				// setOptions([{ key: 0, title: "No results" }]);
				setLoading(false);
			}, 500);
		}
		return () => {
			if (timeout) clearTimeout(timeout);
		};
	}, [inputValue]);

	const goToDetailScreen = (val) => {
    console.log("Navigate to: ", val)
		navigate('/users/' + val.id, { state: val });
	};

	return (
		<Autocomplete
			style={{ width: 500 }}
			options={loading ? [{ key: 0, title: "Loading..." }] : options}
			filterOptions={(options) => options}
			getOptionLabel={(option) => option.title || option.name || option}
			onChange={(e, val) => goToDetailScreen(val)}
			disableClearable
			clearOnBlur
			freeSolo
			renderOption={(props, option) => {
				return (
					<li {...props} key={`${option.type}-${option.id}`}>
						{option.title || option.name}
					</li>
				);
			}}
			onInputChange={(e, val) => {
				console.log(val);
				setInputValue(val);
			}}
			renderInput={(params) => <TextField {...params} value={inputValue} label={"Busca"} variant="standard" />}
		/>
	);
}
