import { gql } from "@apollo/client"

export const getBanners = gql`query {
    banners: getBanners {
        id
        name
        type
        image
        userEnabled
        providerEnabled
        showFrom
        showUntil
    }

}`
export const MUTATION_deleteBanner = gql`mutation deleteBanners($ids: [Int]!) {
    deleteBanners(ids: $ids)
}`

export const QUERY_getBanner = gql`query($id: Int!) {
    banner: getBanner(id: $id) {
        id
        name
        image
        userEnabled
        providerEnabled
        typeConfig
        sort
        type
        image
        showFrom
        showUntil

    }
}`

export const MUTATION_editBanner = gql`mutation edit(
    $id: Int,
    $name: String,
    $userEnabled: Boolean,
    $providerEnabled: Boolean,
    $typeConfig: String,
    $sort: Int,
    $type: String,
    $image: String,
    $showFrom: DateTime,
    $showUntil: DateTime,
) {
    editBanner(
        id: $id,
        name: $name,
        userEnabled: $userEnabled,
        providerEnabled: $providerEnabled,
        typeConfig: $typeConfig,
        sort: $sort,
        type: $type,
        image: $image,
        showFrom: $showFrom,
        showUntil: $showUntil,
    )
}`
