import { gql } from "@apollo/client"

export const getDashboardData = gql`query($dateFrom: String!, $dateTo: String!) {
    getDashboardData(dateFrom: $dateFrom, dateTo:$dateTo ) {
        tasksCount
        budgetedTasksCount
        hiredTasksCount
        qualifiedTasksCount
        historicTasksCount
        usersCount
        historicUsersCount
        providersCount
        historicProvidersCount
        tasksWithConverCount
        deletedTasksCount
        totalDeletedTasksCount
        taskActivities {
            action
            task {
                id
                deletedAt
            }
        }
    }
    getPromotionTasksActivities(dateFrom: $dateFrom, dateTo:$dateTo ) {
        action
        task {
            id
            deletedAt
        }
    }
}`

export const getBudgetCount = gql`query getBudgetsForDashboard {
    getTotalBudgets {
        budgetsCount
        totalAmount
    }
}`

export const getTasksProgress = gql`query getTaskProgressForDashboard {
    getTasksProgress {
        tasksCount
        tasksHiredOrCompleted
    }
}`

export const getAllTasks = gql`query getAllTasks {
    getAllTasks {
        title
        description
        status
        id
    }
}
`