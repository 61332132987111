import { Box, Typography } from '@mui/material';
import draftToHtml from 'draftjs-to-html';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useField } from 'react-final-form';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default function FormInputHTMLEditor({ name, ...props }) {

    const { input: { value, ...input } } = useField(name)

    let initialContentState = value
    if (value && typeof value === 'string') initialContentState = JSON.parse(value)
    initialContentState = initialContentState?.state

    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                width: '100%',
                height: '100%',
                minHeight: '300px',
                border: '1px solid #ccc',
                borderRadius: '5px',
            }}>
            <Typography variant='h5' style={{ margin: '0.5rem' }}>
                {props.label}
            </Typography>
            <Editor
                {...input}
                {...props}
                id={name}
                onChange={(state) => {
                    input.onChange(JSON.stringify({ state, html: draftToHtml(state) }))
                }}
                initialContentState={initialContentState}
            />
        </Box>
    )
}