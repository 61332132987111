import { useField } from "react-final-form";

export default function ImgUploader({ image, name, ...props }) {
	const {
		input: { onChange, onFocus, onBlur, ...input },
	} = useField(name);
	return (
		<input
			type="file"
			accept="image/*"
			onChange={async (e) => {
				const token = window.localStorage.getItem("sessionToken");
				const formData = new FormData();
				formData.append("image", e.target.files[0]);
				const res = await fetch(`${process.env.REACT_APP_API_URI}/api/media/uploadMedia`, {
					method: "POST",
					headers: { Authorization: `Bearer ${token}` },
					body: formData,
				});
				const data = await res.json();
				if (data?.url) onChange(data.url);
			}}
		/>
	);
}
