import React, { useEffect } from 'react';
import { format, formatDistance } from 'date-fns';
import { es } from 'date-fns/locale'
import { Box, Avatar, Card, Typography, ImageListItem, ImageList, Rating, Stack, Button, } from '@mui/material';
import { getInitials } from '../../../helpers/name';
import { CustomDataGrid, CustomDateRangePicker } from '../../../components';
import { getAllTasks } from '../GQLQueries';
import { useLazyQuery } from '@apollo/client';
import { tableCategorySbucategoryRender, tableTaskStatusRender, tableTaskStatusStringValue } from '../../../helpers/tableRenders';
import { get } from "lodash";
import CustomFilterPanelForTable from '../../../components/customFilterPanelForTable';
import { selectCompleteDate } from '../../../redux/layout';
import { useSelector } from 'react-redux';
import { severityPillRender } from '../../../components/customDataGrid/utils';
import SendPushAction from './SendPushAction';
import CancelTaskAction from './CancelTaskAction';
import DateRenderer from '../../../components/tableRenderers/Date';
import SendSMSAction from './SendSMSAction';
import SendWhatsappAction from './SendWhatsappAction';
import { useNavigate } from 'react-router-dom';
import SendMailAction from './SendMailAction';

export const CustomerListResults = (props) => {
  const navigate = useNavigate()
  const [dateFrom, dateTo] = useSelector(selectCompleteDate);
  const [runQuery, { data, loading }] = useLazyQuery(getAllTasks, { variables: { dateFrom: dateFrom, dateTo: dateTo }, fetchPolicy: "network-only" });
  const rows = data?.getAllTasks || []

  const loadData = () => {
    if (dateFrom && dateTo) {
      runQuery({ variables: { dateFrom, dateTo } })
    }
  }
  useEffect(loadData, [dateFrom, dateTo, runQuery])

  const avatarRenderer = (row) => {
    return <Box sx={{ alignItems: 'center', display: 'flex' }}>
      <Avatar src={row?.owner?.profilePicture} sx={{ mr: 2 }}>{getInitials(`${row?.owner?.firstName} ${row?.owner?.lastName}`)}</Avatar>
      <Typography color="textPrimary" variant="body1">{row?.owner?.firstName} {row?.owner?.lastName}</Typography>
    </Box>
  }
  const dateDistanceRenderer = row => formatDistance(new Date(Number(row.createdAt)), new Date(), { locale: es, addSuffix: true })
  const dateStringValue = row => format(new Date(Number(row.createdAt)), "yyyy-MM-dd'T'HH:mm:ss.mmm'Z'", { locale: es })
  const isProviderBoolean = row => get(row, "deleted") ? "Si" : "No"

  const mediaView = row => <>
    <ImageList cols={3}>
      {get(row, "media", []).map(m => <a href={m.uri} target="_blank" rel="noreferrer" key={m.id}>
        <ImageListItem>
          {m.uri?.endsWith("jpg") ? <img style={{ cursor: "pointer" }} alt="Media" loading="lazy" src={m.uri} />
            : <video onMouseOver={event => event.target.play()} style={{ maxHeight: 100, cursor: "pointer" }} src={m.uri} />}
        </ImageListItem>
      </a>)}
    </ImageList>
  </>

  const qualificationView = row => <>
    {row.qualification?.stars ? <Rating value={row.qualification?.stars} readOnly /> : null}
  </>

  const renderDetailsButton = (row) => {
    return <Button onClick={() => navigate(`/task/${row.id}`)} variant="contained" color="primary">Ver detalles</Button>
  }

  return (<>
    <Stack direction='row' justifyContent='space-between'>
      <Typography sx={{ m: 1 }} variant="h4">Reporte de tareas</Typography>
      <CustomDateRangePicker />
    </Stack>
    <Card {...props}>
      <Box sx={{ minWidth: 1050 }}>
        <CustomDataGrid
          filterPanel={CustomFilterPanelForTable}
          reload={loadData}
          loading={loading}
          // selectedActions={sendNotificationsForTable}
          actions={[SendMailAction, SendWhatsappAction, SendSMSAction, SendPushAction, CancelTaskAction]}
          hideFooterSelectedRowCount
          checkboxSelection
          label=""
          rows={rows}
          columns={[
            { id: "id", label: "ID", type: "number", hide: true },
            { id: 'open', label: 'Detalles', customRender: renderDetailsButton },
            { id: "owner.id", label: "Creador", customValue: row => `${row.owner.firstName} ${row.owner.lastName}`, customRender: avatarRenderer },
            { ...DateRenderer("owner.createdAt"), label: "Fecha de registro", hide: true },
            { id: "title", label: "Titulo", flex: 2 },
            { id: "promotionCodeFlat", label: "Codigo", flex: 2, customRender: (row) => severityPillRender(row?.promotionCodeFlat || "") },
            { id: "description", label: "Descripcion", flex: 2 },
            { ...DateRenderer("createdAt"), label: "Fecha de creación" },
            { id: "duration", label: "Duracion", type: "date", customValue: dateStringValue, customRender: dateDistanceRenderer, filtrable: false },
            { id: "status", label: "Estado", customValue: tableTaskStatusStringValue, customRender: tableTaskStatusRender },
            { id: "category/subcategory", label: "Categoria", customValue: tableCategorySbucategoryRender, customRender: tableCategorySbucategoryRender },
            { id: "taskViews", label: "Vistas", type: "number" },
            { id: "budgetsCount", label: "Presupuestos", type: "number" },
            { id: "seenBudgetsCount", label: "Presupuestos Vistos", type: "number" },
            { id: "conversationsCount", label: "Conversaciones", type: "number" },
            { id: "budgetsAvg", label: "Promedio Presupuestos", type: "number", customRender: row => new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(row.budgetsAvg) },
            { id: "deleted", label: "Eliminada", customValue: isProviderBoolean, customRender: isProviderBoolean },
            { id: "media.length", label: "Multimedia", type: "number", customValue: (row) => get(row, "media.length", 0), customRender: mediaView },
            { id: "qualification", label: "Calificacion", type: "number", customValue: (row) => get(row, "qualification.stars"), customRender: qualificationView },
            { id: 'acquisitionMethod', label: 'Metodo de adquisicion' },
            { id: "warrantyMonths", label: "Garantia", type: "number", customRender: row => row.warrantyMonths ? `${row.warrantyMonths} meses` : "Sin garantia" },
            { id: "address.inline", label: "Direccion"},
          ]} />
      </Box>
    </Card>
  </>);
};