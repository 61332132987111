import { Download } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useRef, useContext } from "react"
import { TableContext } from "..";
import { json2csvAsync } from 'json-2-csv';
import { get } from "lodash"
import TableIconButton from "../../TableIconButton";

export default (props) => {

    const { columns, rows, label } = useContext(TableContext);
    const downloadElem = useRef(null);

    const handleClick = async () => {
        const report = rows.map(r => {
            let mappedRow = {}
            columns.filter(c => !c.hide).forEach(c => {
                if (c.customValue) mappedRow[c.label] = c.customValue(r)
                else if (typeof r[c.id] === "string") mappedRow[c.label] = get(r, c.id, "")
                else mappedRow[c.label] = get(r, c.id, "")
            })
            return mappedRow
        })
        const csv = await json2csvAsync(report, { wrap: true })
        const blob = new Blob([csv]);
        downloadElem.current.href = URL.createObjectURL(blob)
        downloadElem.current.click()
    }
    return <>
        <TableIconButton onClick={handleClick} icon={<Download />} tooltip="Exportar" />
        <a style={{ display: "none" }} download={label ? `${label}.csv` : "Reporte.csv"} ref={downloadElem} />
    </>
}