import ModalFrame from '../../../components/form/ModalFrame';
import { Edit as EditIcon } from '@mui/icons-material'

import { useDisclosure } from "react-use-disclosure";
import { Stack, Tooltip, IconButton } from "@mui/material"
import { Form } from 'react-final-form'
import { useMutation } from '@apollo/client';
import { edit } from '../GQLQueries';
import FormInput from '../../../components/form/FormInput';
import FormInputHTMLEditor from '../../../components/form/FormInputHTMLEditor';
import FormSwitch from '../../../components/form/FormSwitch';
import TableIconButton from '../../../components/TableIconButton';


export default function EditForm({ selectedRows = [] }) {
    const { isOpen, open, close } = useDisclosure(false);

    const [Edit] = useMutation(edit, { fetchPolicy: "network-only" });

    const validate = (values) => {
        const errors = {};
        if (!values.code) errors.code = 'El codigo es requerido.';
        return errors;
    }

    return (<>
        <TableIconButton onClick={open} disabled={selectedRows.length !== 1} icon={<EditIcon />} tooltip='Editar registro' />
        <Form
            initialValues={selectedRows[0]}
            onSubmit={async (values, actions) => {
                const { dirtyFields } = actions.getState()
                let variables = { id: values.id }
                Object.keys(dirtyFields).map(k => variables[k] = values[k])
                await Edit({ variables: values })
                close()
            }}
            validate={validate}
            render={({ handleSubmit, form, errors, submitting, pristine, values }) => (
                <ModalFrame onSubmit={handleSubmit} {...{ isOpen, close }} title='Nuevo' buttonTexts={["Guardar", "Cancelar"]}>
                    <Stack spacing={4}>
                        <Stack spacing={4} direction='row' justifyContent='space-between'>
                            <FormInput style={{ width: '100%' }} name='code' label='Codigo' type='text' autoFocus />
                            <FormSwitch name="enabled" label="Habilitado" />
                        </Stack>
                        <FormInput name='description' label='Descripcion' type='text' />
                        <FormInputHTMLEditor name='details' label='Como funciona' />
                    </Stack>
                </ModalFrame>
            )} />
    </>
    )
}
