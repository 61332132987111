import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Autocomplete } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { useField } from "react-final-form";

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const AutocompleteService = new window.google.maps.places.AutocompleteService();
const PlacesService = new window.google.maps.places.PlacesService(document.createElement("div"));

export default function FormGoogleMapsAutocomplete({ name, style, ...props }) {
	const {
		input: { onChange, onFocus, onBlur, ...input },
		meta,
	} = useField(name);
	const [value, setValue] = React.useState(input.value || null);
	const [inputValue, setInputValue] = React.useState(input.value?.inline || "");
	const [options, setOptions] = React.useState([]);

	const getOptions = React.useMemo(() => debounce(async (input, callback) => AutocompleteService.getPlacePredictions({ input }, callback), 400), []);

	React.useEffect(() => {
		if (inputValue === "") {
			setOptions(value ? [value] : []);
			return undefined;
		}

		getOptions(inputValue, (results) => {
			let newOptions = [];
			if (value) newOptions = [value];
			if (results) newOptions = [...newOptions, ...results];
			setOptions(newOptions);
		});
	}, [value, inputValue, getOptions]);

	const getDetails = async (placeId) => {
		if (!placeId) return {};
		const request = { placeId, fields: ["name", "formatted_address", "place_id", "plus_code", "address_components", "geometry"] };
		return new Promise((resolve, reject) => {
			PlacesService.getDetails(request, (result, status) => {
				if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
					reject(status);
				}
				resolve(result);
			});
		});
	};

	return (
		<Autocomplete
			style={style}
			getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
			filterOptions={(x) => x}
			options={options}
			autoComplete
			includeInputInList
			filterSelectedOptions
			value={value}
			noOptionsText="No locations"
			onChange={async (event, newValue) => {
				setValue(newValue);
				let details = {};
				if (newValue) details = await getDetails(newValue?.place_id);
				newValue = {
					...newValue,
					street: details?.address_components?.find((c) => c.types.includes("route"))?.long_name,
					number: details?.address_components?.find((c) => c.types.includes("street_number"))?.long_name,
					locality: details?.address_components?.find((c) => c.types.includes("locality"))?.long_name,
					state: details?.address_components?.find((c) => c.types.includes("administrative_area_level_1"))?.long_name,
					postalCode: details?.address_components?.find((c) => c.types.includes("postal_code"))?.long_name,
					lat: details?.geometry?.location?.lat(),
					lng: details?.geometry?.location?.lng(),
					inline: details?.formatted_address,
				};
				onChange({ ...newValue, ...details });
			}}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
			}}
			renderInput={(params) => <TextField {...params} label="Add a location" fullWidth />}
			renderOption={(props, option) => {
				const matches = option.structured_formatting.main_text_matched_substrings || [];

				const parts = parse(
					option.structured_formatting.main_text,
					matches.map((match) => [match.offset, match.offset + match.length])
				);

				return (
					<li {...props}>
						<Grid container alignItems="center">
							<Grid item sx={{ display: "flex", width: 44 }}>
								<LocationOnIcon sx={{ color: "text.secondary" }} />
							</Grid>
							<Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
								{parts.map((part, index) => (
									<Box key={index} component="span" sx={{ fontWeight: part.highlight ? "bold" : "regular" }}>
										{part.text}
									</Box>
								))}
								<Typography variant="body2" color="text.secondary">
									{option.structured_formatting.secondary_text}
								</Typography>
							</Grid>
						</Grid>
					</li>
				);
			}}
		/>
	);
}
