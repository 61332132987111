import { gql, useQuery } from "@apollo/client";
import FormSelect from "./Select";

const GQLGetCategoriesForSelect = gql`
	query getCategoriesForSelect {
		categories: getAllCategories {
			id
			titleLng
		}
	}
`;
export default function FormCategorySelect(props) {
	const { data: { categories = [] } = {} } = useQuery(GQLGetCategoriesForSelect, { fetchPolicy: "network-only" });
	return <FormSelect {...props} options={categories.map(({ id, titleLng }) => ({ value: id, label: titleLng }))} />;
}
