import { SeverityPill } from "../screens/dashboardTemplate/components/severity-pill"



const statusLabel = {
    'PUBLISHED': 'Publicada',
    'BUDGETED': 'Presupuestada',
    'HIRED': 'Contratada',
    'FINISHED': 'Finalizado por el experto',
    'REALIZED': 'Finalizada por el experto',
    'QUALIFIED': 'Calificada por el usuario',
    'COMPLETED': 'Terminada',
    'CANCELED': 'Cancelada',
}

const statusColor = {
    'PUBLISHED': '#5048E5',
    'BUDGETED': '#F76915',
    'HIRED': '#EEDE04',
    'REALIZED': '#A0D636',
    'FINISHED': '#A0D636',
    'COMPLETED': '#333ED4',
    'QUALIFIED': '#2FA236',
    'CANCELED': '#FD0100',
}

export const tableTaskStatusRender = (row) => <SeverityPill style={{ backgroundColor: statusColor[row?.status] }}>{statusLabel[row?.status]}</SeverityPill>

export const tableTaskStatusStringValue = (row) => {
    return Object.keys(statusLabel).indexOf(row?.status) + ` ${statusLabel[row?.status]}`
}

export const tableCategorySbucategoryRender = ({ category, subcategory }) => {
    const cat = category?.titleLng, subcat = subcategory?.titleLng
    return subcat? `${cat} / ${subcat}` : cat
}