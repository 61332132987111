import { useLazyQuery, useMutation } from '@apollo/client';
import { Box, Button, MenuItem, Modal, Portal, Stack, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FieldArray, Form, Formik, useFormikContext } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CustomDatePicker, CustomSelect } from '..';
import { setSnackBar } from '../../redux/snackbar';
import ChipButton from "../chipButton";
import { TableContext } from '../customDataGrid';
import { findOperator, getOperators } from '../customDataGrid/components/filterUtils';
import CustomSwitch from '../customSwitch';
import { deleteFilterMutation, getFilters, saveFilters } from './GQLQueries';

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    button: {
        margin: 1
    },
    field: {
        margin: 1
    }
}));

export default function CustomFilterPanelForTable(props) {
    const [filterToDelete, setFilterToDelete] = useState(null)
    const [saveFiltersOpen, setSaveFiltersModalOpen] = useState(false)
    const dispatch = useDispatch()

    const { columns, filters, setFilters, setColumns } = useContext(TableContext);

    const [getSavedFilters, { data }] = useLazyQuery(getFilters, { fetchPolicy: "network-only", variables: { reportPath: window.location.pathname } });
    useEffect(() => { getSavedFilters() }, [])

    const savedFilters = data?.getUserFilters || []
    const [saveCurrentFilters, { error: saveError }] = useMutation(saveFilters)
    const [deleteFilter] = useMutation(deleteFilterMutation)

    const classes = useStyles()
    const getColumnConfig = (colId) => columns.find(c => c.id === colId)
    const container = React.useRef(null);

    const BusinessLogic = () => {
        const { values } = useFormikContext();
        useEffect(() => {
            values.filters = filters
        }, [filters]);
        return null;
    };

    const saveCustomFilters = async ({ name, global }, { setSubmitting, resetForm }) => {
        setSubmitting(true)
        await saveCurrentFilters({
            variables: {
                name,
                global,
                filters: JSON.stringify(filters),
                columns: JSON.stringify(columns.map(c => ({ id: c.id, hide: !!c.hide }))),
                reportPath: window.location.pathname
            }
        })

        if (!saveError) dispatch(setSnackBar({ text: "Filtro guardado correctamente." }))
        else dispatch(setSnackBar({ text: "Filtro no guardado.", severity: "error" }))
        getSavedFilters()
        resetForm();
        setSaveFiltersModalOpen(false)
    }

    return <>
        <Formik
            initialValues={{ filters }}
            onSubmit={async (values) => {
                const mappedValues = values.filters.map(({ columnField, value, operatorValue }, index) => ({ columnField, operatorValue, id: 1, value }))
                setFilters(mappedValues)
            }}>
            {({ values, handleChange, handleBlur }) => (
                <Form>
                    <Stack direction="row" spacing={1}>
                        {savedFilters.map(filter => <ChipButton
                            key={`filter-${filter.id}`}
                            color={filter.ownerId ? "primary" : "success"}
                            label={filter.name}
                            onClick={() => {
                                const newColumns = JSON.parse(filter.columns)
                                setFilters(JSON.parse(filter.filters))
                                setColumns(columns.map(c => ({ ...c, ...newColumns.find(nC => c.id === nC.id) })))
                            }}
                            onDelete={() => setFilterToDelete(filter.id)}
                        />)}
                    </Stack>
                    <div style={{ width: "100%", display: "inline-flex", justifyContent: "flex-end", alignItems: 'center', }}>
                        <Box sx={{ p: 1 }} ref={container} />
                        <Button size="large" type="submit" variant="contained">Aplicar</Button>
                    </div>
                    <FieldArray name="filters">
                        {({ remove, push }) => {
                            const addFilterRow = () => {
                                const defaultColumn = columns.find(c => !c.hide)
                                push({ columnField: defaultColumn.id, operatorValue: getOperators(defaultColumn.type)[0].id, value: "" })
                            }
                            return <div style={{ alignItems: 'center', }}>
                                <Portal container={container.current}>
                                    <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                                        <Button type="button" variant="outlined" onClick={addFilterRow}>Agregar Filtro</Button>
                                        <Button type="button" variant="outlined" onClick={() => { setSaveFiltersModalOpen(true) }}>Guardar Filtro</Button>
                                    </Stack>
                                </Portal>
                                {values.filters.map((filter, index) => {
                                    const nameColumn = `filters[${index}].columnField`;
                                    const nameOperator = `filters[${index}].operatorValue`;
                                    const nameValue = `filters[${index}].value`;
                                    const filterColumn = getColumnConfig(filter.columnField || columns[0].id) || {}

                                    const getFilterValueComponent = (columnType = "string") => {
                                        if (columnType === "date") return <CustomDatePicker className={classes.field} label="Fecha" name={nameValue} value={filter.value || ""} required onChange={handleChange} onBlur={handleBlur} />
                                        return <TextField className={classes.field} label="Valor" name={nameValue} value={filter.value || ""} required onChange={handleChange} onBlur={handleBlur} />
                                    }

                                    return <div style={{ padding: "10px 0px" }} key={columns[0].id}>

                                        <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                                            <CustomSelect
                                                required
                                                className={classes.field}
                                                name={nameColumn}
                                                label="Columna"
                                                value={filter.columnField || columns[0].id}
                                                onChange={handleChange}
                                                onBlur={handleBlur}>
                                                {columns.filter(c => c.filtrable !== false).map((col, index) => <MenuItem key={`col-${col.id}`} value={col.id}>{col.label}</MenuItem>)}
                                            </CustomSelect>
                                            <CustomSelect
                                                required
                                                className={classes.field}
                                                name={nameOperator}
                                                label="Operador"
                                                value={filter.operatorValue || getOperators(filterColumn?.type)[0].id}
                                                onChange={handleChange}
                                                onBlur={handleBlur}>
                                                {getOperators(filterColumn?.type).map((op, index) => <MenuItem key={`op-${op.id}`} value={op.id}>{op.label}</MenuItem>)}
                                            </CustomSelect>
                                            {!findOperator(filterColumn?.type, filter.operatorValue).disableValue && getFilterValueComponent(filterColumn?.type)}
                                            <Button className={classes.button} type="button" color="error" variant="outlined" onClick={() => remove(index)}>X</Button>
                                        </Stack>
                                    </div>
                                })}
                            </div>
                        }}
                    </FieldArray>
                    <BusinessLogic></BusinessLogic>
                </Form>
            )}
        </Formik >


        <Modal open={saveFiltersOpen} onClose={() => setSaveFiltersModalOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "50%", bgcolor: 'background.paper', borderRadius: 1, boxShadow: 24, p: 4, }}>
                <Formik
                    initialValues={{ name: "", global: false }}
                    onSubmit={saveCustomFilters}>
                    {({ values, handleChange, handleBlur, isSubmitting }) => {
                        const fieldProps = { className: classes.field, onChange: handleChange, onBlur: handleBlur, fullWidth: true }
                        return <Form >
                            <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                                <TextField style={{ marginRight: 100 }}{...fieldProps} autoFocus required label="Nombre" name="name" value={values.name} />
                                <CustomSwitch name="global" label="Global" value={values.global} onChange={handleChange} onBlur={handleBlur} />
                            </Stack>
                            <br />
                            <Button disabled={isSubmitting} size="large" type="submit" variant="contained">Enviar</Button>
                        </Form>
                    }}
                </Formik >
            </Box>
        </Modal>
        <Modal
            open={!!filterToDelete}
            onClose={() => setFilterToDelete(null)} >
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "20%", bgcolor: 'background.paper', borderRadius: 1, boxShadow: 24, p: 4, }}>
                <Typography align="center" variant="h6" component="div" sx={{ flexGrow: 1 }}>¿Esta seguro que desea eliminar el filtro?</Typography>
                <br />
                <Stack direction="row" spacing={1} justifyContent="space-around" alignItems="center">
                    <Button size="large" onClick={() => {
                        deleteFilter({ variables: { filterId: filterToDelete } })
                        setFilterToDelete(null)
                        getSavedFilters()
                    }} variant="contained" color="error">Eliminar</Button>
                    <Button size="large" onClick={() => setFilterToDelete(null)} variant="contained" >Cancelar</Button>
                </Stack>
            </Box>
        </Modal>

    </>
}