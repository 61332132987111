import ModalFrame from "../../../components/form/ModalFrame";
import { Cancel } from "@mui/icons-material";
import { useDisclosure } from "react-use-disclosure";
import { Tooltip, IconButton } from "@mui/material";
import { Form } from "react-final-form";
import { gql, useMutation } from "@apollo/client";
import TableIconButton from "../../../components/TableIconButton";

export default function CancelTaskAction({ selectedRows = [] }) {
	const { isOpen, open, close } = useDisclosure(false);

	const GQLCancelTasks = gql`
		mutation GQLCancelTasks($tasks: [Int]!) {
			cancelTasks(taskIds: $tasks) {
				id
			}
		}
	`;

	const [CancelTasks] = useMutation(GQLCancelTasks, { fetchPolicy: "network-only" });

	return (
		<>
			<TableIconButton onClick={open} disabled={selectedRows.length < 1} icon={<Cancel />} tooltip={`Cancelar Tarea${selectedRows.length === 1 ? "" : "s"}`} />
			<Form
				onSubmit={async () => {
					await CancelTasks({ variables: { tasks: selectedRows.map((t) => t.id) } });
					close();
				}}
				initialValues={{ templateId: null, tasks: selectedRows.map((u) => u.id) }}
				render={({ handleSubmit }) => <ModalFrame onSubmit={handleSubmit} {...{ isOpen, close }} title={"Seguro que desea cancelar las tareas?"} buttonTexts={["Confirmar", "Cancelar"]} />}
			/>
		</>
	);
}
