import React, { useEffect, useState } from "react";
import { GoogleMap } from "@react-google-maps/api";

export default function MapView({ center, ...props }) {
	const mapRef = React.useRef(null);
	const [mapLoaded, setMapLoaded] = useState(null);
	const onMapClick = React.useCallback((e) => {}, []);

	return (
		<GoogleMap
			ref={mapRef}
			onLoad={setMapLoaded}
			onClick={onMapClick}
			options={{
				mapId: "1ea02683e0a5f5da",
				scaleControl: false,
				fullscreenControl: false,
				streetViewControl: false,
				zoomControl: false,
				mapTypeControl: false,
				panControl: false,
				clickableIcons: false,
			}}
			mapContainerStyle={styles.mapContainer}
			center={center}
			zoom={10}>
			{props.children}
		</GoogleMap>
	);
}

const styles = {
	mapContainer: {
		height: "80vh",
		width: "100%",
		borderRadius: "10px",
	},
};
