import { Avatar } from "@mui/material";
import Attribute from "./Attribute";

export default function PersonalInformation({ user = {} }) {
	return (
		<div
			style={{
				padding: "10px",
				display: "flex",
				width: "100%",
				flexDirection: "Column",
				justifyContent: "center",
				alignItems: "center",
			}}>
			<Avatar alt={user.firstName} src={user.profilePicture} variant="square" sizes="large" sx={styles.avatar} />
			<h1>
				{user.firstName} {user.lastName}
			</h1>
			<Attribute label="ID" value={user.id} />
			<Attribute label="Email" value={user.email} />
			<Attribute label="Direccion" value={user.address?.inline} />
			<Attribute label="Deshabilitado" value={user.disabled ? "Si" : "No"} />
			<Attribute label="Eliminado" value={user.deletedAt ? "Si" : "No"} />

		</div>
	);
}

const styles = {
	avatar: {
		width: 80,
		height: 80,
		borderRadius: "10%",
		shadow: 2,
	},
};
