import { gql } from "@apollo/client"

export const getAllMessages = gql`query($dateFrom: String!, $dateTo: String!)  {
    getAllMessages(dateFrom: $dateFrom, dateTo: $dateTo ) {
        id
        conversationId
        conversation {
            taskId
            budgetId
            task {
                id
                title
                status
            }
            budget {
                id
                amount
                availabilities
                comment
            }
            provider {
                id
                firstName
                lastName
                profilePicture
            }
            user {
                id
                firstName
                lastName
                profilePicture
            }
        }
        createdAt
        data
        media
        senderId
    }
}`