import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale'
import { Box, Avatar, Card, Typography, Stack, } from '@mui/material';
import { getInitials } from '../../../helpers/name';
import { CustomDataGrid, CustomDateRangePicker } from '../../../components';
import { getAllPackageMessages } from '../GQLQueries';
import { useLazyQuery } from '@apollo/client';
import CustomFilterPanelForTable from '../../../components/customFilterPanelForTable';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackBar } from '../../../redux/snackbar';
import { Send } from '@mui/icons-material';
import { get } from "lodash"
import { selectCompleteDate } from '../../../redux/layout';
import { tableTaskStatusRender, tableTaskStatusStringValue } from '../../../helpers/tableRenders';
import { isoDateRenderer } from '../../../tableUtils'
import { WhatsappActionFactory } from '../../../components/SendWhatsappAction';

export const CustomerListResults = (props) => {

  const [dateFrom, dateTo] = useSelector(selectCompleteDate);

  const [runQuery, { error, data: { messages: rows = [] } = {}, loading }] = useLazyQuery(getAllPackageMessages, { variables: { dateFrom, dateTo }, fetchPolicy: "network-only" });
  // const rows = data?.getAllMessages || []

  const dispatch = useDispatch()
  // useEffect(() => { if (error) dispatch(setSnackBar({ text: `Error en el reporte: ${error.graphQLErrors[0].message}.`, severity: "error" })) }, [error, dispatch])


  const loadData = () => {
    if (dateFrom && dateTo) {
      runQuery({ variables: { dateFrom, dateTo } })
    }
  }

  useEffect(loadData, [dateFrom, dateTo, runQuery])


  const avatarRenderer = (user, senderId) => {
    if (!user) return null
    return <Box sx={{ alignItems: 'center', display: 'flex' }}>
      <Avatar src={user.profilePicture} sx={{ mr: 2 }}>{getInitials(`${user.firstName} ${user.lastName}`)}</Avatar>
      <Typography color="textPrimary" variant="body1">{user.firstName} {user.lastName}</Typography>
      {senderId === user.id && <Send fontSize="large" style={{ marginLeft: 10, padding: 5 }} />}
    </Box>
  }
  const messageRender = row => {
    const isUserSender = row.senderId === get(row, 'conversation.user.id')
    return <div style={{ backgroundColor: isUserSender ? "#a2cf6e" : "#4dabf5", padding: "5px 10px" }}>
      {row?.media && <div >
        <a href={row.media} target="_blank" rel="noreferrer"><img style={{ maxHeight: 200, maxWidth: 200, cursor: "pointer" }} alt="media" loading="lazy" src={row.media} /></a>
      </div>}
      <span>{row.data}</span>
    </div>
  }

  const userIdsMapper = (selectedRows) => selectedRows.map((row) => row?.senderId).filter((id) => !!id);

  const hide = true;
  return (<>
    <Stack direction='row' justifyContent='space-between'>
      <Typography sx={{ m: 1 }} variant="h4">Reporte de mensajes</Typography>
      <CustomDateRangePicker />
    </Stack>
    <Card {...props}>
      <Box sx={{ minWidth: 1050 }}>
        <CustomDataGrid
          filterPanel={CustomFilterPanelForTable}
          reload={loadData}
          loading={loading}
          hideFooterSelectedRowCount
          checkboxSelection
          actions={[
            WhatsappActionFactory(userIdsMapper),
          ]}
          label=""
          rows={rows}
          columns={[
            { id: "id", label: "ID", type: "number", hide },
            { id: "conversationId", label: "ID Conversacion", type: "number", hide },
            { id: "conversation.packageId", label: "ID del paquete", type: "number", hide },
            { id: "conversation.packageApplicationId", label: "ID de Aplicacion", type: "number", hide },
            { id: "createdAt", label: "Fecha de creación", ...isoDateRenderer('createdAt') },
            { id: "conversation.package.title", label: "Titulo", hide: false },
            { id: "conversation.package.workDate", label: "Fecha agendada", ...isoDateRenderer('conversation.package.workDate') },
            { id: "status", label: "Estado", customValue: row => tableTaskStatusStringValue(row?.conversation?.package), customRender: row => tableTaskStatusRender(row?.conversation?.package) },
            { id: "conversation.user.id", label: "ID Usuario", type: "number", hide },
            { id: "user", label: "Usuario", customValue: row => `${get(row, "conversation.user.firstName")} ${get(row, "conversation.user.lastName")}`, customRender: row => avatarRenderer(get(row, "conversation.user", {}), row.senderId) },
            { id: "conversation.provider.id", label: "ID Experto", type: "number", hide },
            { id: "provider", label: "Experto", customValue: row => `${get(row, 'conversation.provider.firstName', '')} ${get(row, 'conversation.provider.lastName', '')}`, customRender: row => avatarRenderer(get(row, 'conversation.provider'), get(row, 'senderId')) },
            { id: "data", label: "Mensaje", hide: false, customValue: row => row.data, customRender: messageRender },
          ]} />
      </Box>
    </Card>
  </>);
};