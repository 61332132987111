import { gql } from "@apollo/client"

export const promotionCodes_getAll = gql`query {
    promotionCodes_getAll {
        id
        code
        description
        details
        enabled
        tasks {
            status
        }
        createdAt
    }
}`

export const add = gql`mutation add($code: String!, $description: String!, $details: String!, $enabled: Boolean!) {
    promotionCodes_addOne(code: $code, description: $description, details: $details, enabled: $enabled) {
        id
        code
        description
        details
        enabled
        createdAt
    }
}`

export const edit = gql`mutation add($id: Int!, $code: String!, $description: String, $details: String, $enabled: Boolean!) {
    promotionCodes_editOne(id: $id, code: $code, description: $description, details: $details, enabled: $enabled) {
        id
        code
        description
        details
        enabled
        createdAt
    }
}`
export const deleteMutation = gql`mutation delete($ids: [Int]!) {
    promotionCodes_delete(ids: $ids)
}`