import ModalFrame from "../../../components/form/ModalFrame";
import { useDisclosure } from "react-use-disclosure";
import { Tooltip, IconButton } from "@mui/material";
import { Delete as TrashIcon } from "@mui/icons-material";
import { gql, useMutation } from "@apollo/client";
import { TableContext } from "../../../components/customDataGrid";
import { useContext } from "react";
import TableIconButton from "../../../components/TableIconButton";

const GQLDelete = gql`
	mutation deleteSubcategory($ids: [Int!]!) {
		subcategoryDeleted: deleteSubcategory(ids: $ids)
	}
`;

export default function DeleteAction({ selectedRows = [] }) {
	const { isOpen, open, close } = useDisclosure();
	const { reload } = useContext(TableContext);

	const [Delete] = useMutation(GQLDelete, { fetchPolicy: "network-only" });

	const handleSubmit = async () => {
		await Delete({ variables: { ids: selectedRows.map((i) => i.id) } });
		await reload();
		close();
	};

	return (
		<>
			<TableIconButton onClick={open} disabled={!selectedRows.length} icon={<TrashIcon />} tooltip={`Eliminar Registro${selectedRows.length === 1 ? "" : "s"}`} />
			<ModalFrame
				onSubmit={handleSubmit}
				{...{ isOpen, close }}
				title={`Estas seguro que deseas eliminar ${
					selectedRows.length === 1 ? `la subcategoria ${selectedRows[0]?.title?.es}` : `las ${selectedRows.length} subcategorias seleccionados`
				}?`}></ModalFrame>
		</>
	);
}
