import { useMutation, useLazyQuery } from "@apollo/client";
import { Container, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MediaGallery from "../../components/MediaGallery";
import { setSnackBar } from "../../redux/snackbar";
import { QUERY_getAllMedia, MUTATION_deleteMedia } from "./GQLQueries";
import { selectCompleteDate } from "../../redux/layout";
import { CustomDateRangePicker } from "../../components";

export default function Gallery() {
	const [dateFrom, dateTo] = useSelector(selectCompleteDate);
	const [getAllImages, { data: { media = [] } = {} }] = useLazyQuery(QUERY_getAllMedia, { variables: { dateFrom: dateFrom, dateTo: dateTo }, fetchPolicy: "network-only" });
	const [deleteMedia] = useMutation(MUTATION_deleteMedia, { fetchPolicy: "network-only" });
	const dispatch = useDispatch();
	useEffect(() => {
		if (dateFrom && dateTo) {
			getAllImages({ variables: { dateFrom: dateFrom, dateTo: dateTo } });
		}
	}, [dateFrom, dateTo, getAllImages]);
	return (
		<Container maxWidth={false}>
			<Stack direction="row" spacing={4} padding={4} justifyContent="space-between">
				<Typography sx={{ m: 1 }} variant="h4">
					Galería
				</Typography>
				<CustomDateRangePicker />
			</Stack>
			<MediaGallery
				bigMode
				handleDelete={async (variables) => {
					const { data } = await deleteMedia({ variables });
					if (data?.deleteMedia) dispatch(setSnackBar({ text: `Se han eliminado ${data?.deleteMedia} registros`, severity: "success" }));
					else dispatch(setSnackBar({ text: "Error al eliminar los registros seleccionados", severity: "error" }));
					getAllImages();
				}}
				items={media}
			/>
		</Container>
	);
}
