import { RequestQuote } from "@mui/icons-material";
import ModalFrame from "../../../components/form/ModalFrame";

import { gql, useMutation } from "@apollo/client";
import { Form } from "react-final-form";
import { useDisclosure } from "react-use-disclosure";
import TableIconButton from "../../../components/TableIconButton";

export default function SetInvoicedAction({ selectedRows = [] }) {
	const { isOpen, open, close } = useDisclosure(false);

	const GQLSetPackagesInvoiced = gql`
		mutation SetPackagesInvoiced($packageIds: [Int]!) {
			setPackagesInvoiced(packageIds: $packageIds) 
		}
	`;

	const [SetPackagesInvoiced] = useMutation(GQLSetPackagesInvoiced, { variables: {}, fetchPolicy: "network-only" });

	return (
		<>
			<TableIconButton onClick={open} disabled={selectedRows.length < 1} icon={<RequestQuote />} tooltip="Marcar como facturado" />
			<Form
				onSubmit={async (values) => {
					console.log("values", values)
					await SetPackagesInvoiced({ variables: values });
					close();
				}}
				initialValues={{ packageIds: selectedRows.reduce((acc, row) => acc.concat(row.packages?.map(p=>p.id)), []) }}
				// validate={validate}
				render={({ handleSubmit }) => (
					<ModalFrame
						onSubmit={handleSubmit}
						{...{ isOpen, close }}
						title={`Estas a punto de marcar como facturado los ${selectedRows.reduce((acc, row) => acc.concat(row.packages?.map(p=>p.id)), []).length} paquetes seleccionados. ¿Estas seguro?`}
						buttonTexts={["Enviar", "Cancelar"]}/>
				)}
			/>
		</>
	);
}
