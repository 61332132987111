import { useQuery } from '@apollo/client';
import { Box, Card, Grid, Typography, } from '@mui/material';
import React from 'react';
import { CustomDataGrid } from '../../../components';
import customFilterPanelForTable from '../../../components/customFilterPanelForTable';
import { booleanRenderer, isoDateRenderer } from '../../../tableUtils';
import { getAllAcquisitionMethodsList } from '../GQLQueries';
import AddForm from './AddForm';
import EditForm from './EditForm';

export const AcquisitionMethodsList = (props) => {

  const { data, loading, refetch } = useQuery(getAllAcquisitionMethodsList, { fetchPolicy: "network-only" });
  const rows = data?.methods || []

  return (<>
    <Grid container spacing={3} >
      <Grid {...{ item: true, xl: 8 }} ><Typography sx={{ m: 1 }} variant="h4">Metodos de adquisicion</Typography></Grid>
    </Grid>
    <Card {...props}>
      <Box sx={{ minWidth: 1050 }}>
        <CustomDataGrid
          initialFilters={[]}
          reload={refetch}
          loading={loading}
          filterPanel={customFilterPanelForTable}
          actions={[AddForm, EditForm]}
          // actions={[AddForm, EditForm, DeleteConfirmation]}
          hideFooterSelectedRowCount
          checkboxSelection
          rows={rows}
          columns={[
            { id: "id", label: "ID", type: "number" },
            // { id: "code", label: "Código", customRender: (row) => severityPillRender(row.code) },
            { id: "value", label: "Valor" },
            { id: "title.es", label: "Titulo ES" },
            { id: "title.en", label: "Titulo EN" },
            { id: "title.pt", label: "Titulo PT" },
            { id: "disabled", label: "Deshabilitado", ...booleanRenderer },
            { id: "usersCount", label: "Usuarios", type: "number" },
            { id: "createdTasksCount", label: "Tareas creadas", type: "number" },
            { id: "hiredTasksCount", label: "Tareas contratadas", type: "number" },
            { id: "revenueRate", label: "Tasa de ingresos", type: "number" },
            // { id: "tasks.length.budgeted", label: "Tareas presupuestadas", type: "number", customValue: row => row.tasks?.filter(t => statusNumber(t.status) >= 1).length },
            // { id: "tasks.length.hired", label: "Tareas contratadas", type: "number", customValue: row => row.tasks?.filter(t => statusNumber(t.status) >= 2).length },
            // { id: "tasks.length.qualified", label: "Tareas calificadas", type: "number", customValue: row => row.tasks?.filter(t => statusNumber(t.status) >= 4).length },
            // { id: "createdAt", label: "Fecha de creación", type: "date", customValue: dateStringValue, customRender: dateRenderer },
            { id: "createdAt", label: "Fecha de creación", ...isoDateRenderer('createdAt') },
          ]} />
      </Box>
    </Card>
  </>);
};