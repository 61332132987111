import { IconButton, TextField } from "@mui/material";
import React from "react";
import { useField } from "react-final-form";
import { Clear } from "@mui/icons-material";
import { DesktopDatePicker, TimePicker } from "@mui/x-date-pickers";

export default function FormTimeInput({ name, ...props }) {
	const {
		input: { onChange, onFocus, onBlur, ...input },
		meta,
	} = useField(name);
	input.style = { minWidth: 200, ...props.style };
	return (
		<TimePicker
			// inputFormat="dd-MM-yyyy"
			ampm={false}
			ampmInClock={false}
			minutesStep={5}
			disablePortal
			onFocus={onFocus}
			onBlur={onBlur}
			onChange={(val) => onChange(val)}
			value={input.value ? new Date(input.value) : null}
			{...props}
			renderInput={(params) => (
				<TextField
					id={name}
					{...input}
					{...params}
					required={props.required}
					error={meta.error && meta.touched}
					helperText={meta?.error}
					InputProps={{
						endAdornment: (
							<>
								{!!input.value && props.cleareable && (
									<IconButton
										sx={{ margin: "0 -10px", visibility: input.value ? "visible" : "hidden" }}
										onClick={() => {
											onChange(null);
										}}>
										<Clear />
									</IconButton>
								)}
								{params.InputProps.endAdornment}
							</>
						),
					}}
				/>
			)}
		/>
	);
}
