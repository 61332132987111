import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSnackBar, setSnackBar } from '../../../redux/snackbar';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackBar() {
    const dispatch = useDispatch()
    const { text, severity, duration = 6000 } = useSelector(selectSnackBar);
    React.useEffect(() => { if (text) setOpen(true); }, [text])
    const [open, setOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpen(false);
        dispatch(setSnackBar({ text: "" }))
    };

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>{text}</Alert>
            </Snackbar>
        </Stack>
    );
}