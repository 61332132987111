import { FormControlLabel, FormGroup, Slider } from "@mui/material";
import React from "react";
import { useField } from "react-final-form";

export default function FormSlider({ name, label, ...props }) {
	const {
		input: { value = false, onChange, onBlur },
	} = useField(name);
	return (
		<FormGroup style={props.style}>
			<FormControlLabel
				name={name}
				onBlur={onBlur}
				onChange={(e, p) => onChange(p)}
				labelPlacement="top"
				control={<Slider {...props} value={value} variant="outlined" color="primary" />}
				label={label}
			/>
		</FormGroup>
	);
}
