import { Circle } from "@react-google-maps/api";
import React from "react";

export default function CustomMarker({ area, onChange = () => {} }) {
	const ref = React.useRef(null);

	const getCoords = () => {
		const center = ref.current?.state?.circle?.center;
		const lat = center?.lat();
		const lng = center?.lng();
		if (!lat || !lng) return null;
		return { lat, lng };
	};

	const getRadius = () => {
		const radius = ref.current?.state?.circle?.radius;
    console.log("RADIUS =>", ref.current?.state?.circle?.radius);
		if (!radius) return null;
		return radius / 1000;
	};

	const isInitial = () => {
		const center = ref.current?.state?.circle?.center;
		const lat = center?.lat();
		const lng = center?.lng();
		const radius = ref.current?.state?.circle?.radius;

		if (!lat || !lng || !radius) return true;

		return lat === area.lat && lng === area.lon && radius === area.radius * 1000;
	};

	if (!area || !area.lat || !area.lon || !area.radius) return null;

	return (
		<Circle
			ref={ref}
			key={`${area.lat}-${area.lon}-${area.radius}-${area.type}`}
			editable
      // draggable
      
			onCenterChanged={() => {
        if (isInitial()) return;
				console.log(getRadius());
        onChange(getCoords(), getRadius());
				// const newArea = { ...area, lat: e.latLng.lat(), lon: e.latLng.lng() };
				// setAreas((areas) => {
				// 	const newAreas = [...areas];
				// 	newAreas[areas.indexOf(area)] = newArea;
				// 	return newAreas;
				// });
			}}
			onRadiusChanged={(e) => {
				if (isInitial()) return;
        onChange(getCoords(), getRadius());
				// onChange(getCoords(), e.getRadius() / 1000)
				// const newArea = { ...area, radius: e.getRadius() / 1000 };
				// setAreas((areas) => {
				// 	const newAreas = [...areas];
				// 	const editedIndex = areas.findIndex((a) => a.lat === area.lat && a.lon === area.lon && a.type === area.type);
				// 	newAreas[editedIndex] = newArea;
				// 	return newAreas;
				// });
			}}
			center={{
				lat: area.lat,
				lng: area.lon,
			}}
			radius={area.radius * 1000}
			options={{
				strokeColor: (area.type === "provider" ? "#FF0000" : "#0000FF") + (area.over ? "80" : "50"),
				fillColor: (area.type === "provider" ? "#FF0000" : "#0000FF") + (area.over ? "40" : "15"),
				strokeOpacity: 1,
				strokeWeight: 2,
				fillOpacity: 1,
			}}
		/>
	);
}
