import React, { useEffect, useState, useRef } from "react"
export default function TestDownloadFileFromMemory(props) {

    const downloadElem = useRef(null);
    const [downloadUri, setDownloadUri] = useState(null)
    useEffect(() => {
        if (downloadUri) {
            downloadElem.current.click()
            URL.revokeObjectURL(downloadUri);
        }
    }, [downloadUri])

    return <div>
        <button onClick={() => {
            const blob = new Blob(["Prueba"]);
            setDownloadUri(URL.createObjectURL(blob));
        }}>Descargar</button>
        <br />
        <a style={{ display: "none" }}
            download="states.csv"
            href={downloadUri}
            ref={downloadElem}
        >download it</a>
    </div>
}