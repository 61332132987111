import { useMutation } from "@apollo/client";
import { Stack } from "@mui/material";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { Form } from "react-final-form";
import { useDisclosure } from "react-use-disclosure";
import FormInput from "../../../components/form/FormInput";
import FormSwitch from "../../../components/form/FormSwitch";
import ModalFrame from "../../../components/form/ModalFrame";
import { saveAcquisitionMethods } from "../GQLQueries";

export default forwardRef(function AcquisitionMethodForm(props, ref) {
	const [SaveAcquisitionMethods] = useMutation(saveAcquisitionMethods, { fetchPolicy: "network-only" });
	const { isOpen, open, close } = useDisclosure(false);
	const formRef = useRef(null);

	const handleSubmit = async (values, actions) => {
		const revenueRate = (values.revenueRate || 0) / 100;
		await SaveAcquisitionMethods({
			variables: {
				id: values.id,
				value: values.value,
				title: {
					id: values.title.id,
					es: values.title.es,
					en: values.title.en,
					pt: values.title.pt,
				},
				disabled: values.disabled,
				revenueRate,
			},
		});
		actions.reset();
		close();
		props.afterSubmit();
	};

	useImperativeHandle(ref, () => ({
		open,
		close,
		setValues: (values) => {
			console.log(formRef);
		},
	}));
	const formRender = ({ handleSubmit, form, errors, submitting, pristine, values }) => {
		return (
			<ModalFrame onSubmit={handleSubmit} {...{ isOpen, close }} title="Nuevo">
				<Stack spacing={4}>
					<Stack spacing={4} direction="row" justifyContent="space-between">
						<FormInput style={{ width: "100%" }} name="value" label="Valor" type="text" autoFocus />
						<FormInput name="revenueRate" label="Tasa de ingresos" type="number" />
						<FormSwitch name="disabled" label="Deshabilitado" />
					</Stack>
					<Stack spacing={4} direction="row" justifyContent="space-between">
						<FormInput style={{ width: "100%" }} name="title.es" label="Titulo ES" type="text" />
						<FormInput style={{ width: "100%" }} name="title.en" label="Titulo EN" type="text" />
						<FormInput style={{ width: "100%" }} name="title.pt" label="Titulo PT" type="text" />
					</Stack>
				</Stack>
			</ModalFrame>
		);
	};

	return <Form onSubmit={handleSubmit} initialValues={{ title: {}, ...(props.initialValues || {}), revenueRate: props?.initialValues?.revenueRate * 100 }} render={formRender} />;
});
