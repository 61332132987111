import React from "react";

export default function Attribute({ label, value, outlined = false }) {
	return (
		<div
			style={{
				border: outlined ? "1px solid black" : "none",
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				// backgroundColor: "red",
				width: "100%",
				alignItems: "center",
			}}>
			<h3>{label}: </h3>
			<p>{value}</p>
		</div>
	);
}
