import ModalFrame from "../../../components/form/ModalFrame";
import { useDisclosure } from "react-use-disclosure";
import { Tooltip, IconButton } from "@mui/material";
import { Delete as TrashIcon } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { MUTATION_deleteBanner } from "../GQLQueries";
import { TableContext } from "../../../components/customDataGrid";
import { useContext } from "react";
import TableIconButton from "../../../components/TableIconButton";

export default function DeleteConfirmation({ selectedRows = [] }) {
	const { isOpen, open, close } = useDisclosure();
	const { reload } = useContext(TableContext);

	const [Delete] = useMutation(MUTATION_deleteBanner, { fetchPolicy: "network-only" });

	const handleSubmit = async () => {
		await Delete({ variables: { ids: selectedRows.map((i) => i.id) } });
		await reload();
		close();
	};

	return (
		<>
			<TableIconButton icon={<TrashIcon />} onClick={open} disabled={!selectedRows.length} tooltip={`Eliminar Registro${selectedRows.length === 1 ? "" : "s"}`} />
			<ModalFrame
				onSubmit={handleSubmit}
				{...{ isOpen, close }}
				title={`Estas seguro que deseas eliminar ${
					selectedRows.length === 1 ? `el banner ${selectedRows[0].firstName} ${selectedRows[0].lastName}` : `los ${selectedRows.length} banners seleccionados`
				}?`}></ModalFrame>
		</>
	);
}
