import { gql, useQuery } from "@apollo/client";
import { Chip, Stack, IconButton, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import React, { useState, useCallback } from "react";
import { useField } from "react-final-form";

export default function FormCategorySelector({ name, startSelected = false, ...props }) {
	const { data: { categories } = { categories: [] } } = useQuery(GQLgetCategories, { fetchPolicy: "network-only", variables: { lang: "es" }, onCompleted: handleCategoriesLoad });
	const { input, meta } = useField(name);

	const [providerCategories, setProviderCategories] = useState([]);
	const [expandedCategories, setExpandedCategories] = useState({});

	// Handler para establecer la selección inicial al cargar las categorías
	function handleCategoriesLoad() {
		if (categories?.length && meta.initial?.length && !providerCategories?.length) {
			setProviderCategories(meta.initial);
		}
	}

	// Manejar el clic en categorías o subcategorías
	const handleToggle = (item, type, value) => {
		console.log("toggle", item, type, value);
		if (type === "category") {
			setProviderCategories((prev) => {
				let newProvCat;
				if (value) newProvCat = [...prev, ...(!!item.subCategories?.length ? item.subCategories.map((s) => ({ categoryId: item.id, subcategoryId: s.id })) : [{ categoryId: item.id }])];
				else newProvCat = prev.filter((c) => c.categoryId !== item.id);

				input.onChange(newProvCat);
				return newProvCat;
			});
		} else {
			setProviderCategories((prev) => {
				let newProvCat;
				if (value) newProvCat = [...prev, { categoryId: item.categoryId, subcategoryId: item.id }];
				else newProvCat = prev.filter((c) => c.subcategoryId !== item.id);

				input.onChange(newProvCat);
				return newProvCat;
			});
			console.log("providerCategories", providerCategories);
		}
	};

	const toggleExpand = (categoryId) => {
		setExpandedCategories((prev) => ({ ...prev, [categoryId]: !prev[categoryId] }));
	};

	return (
		<Stack spacing={2}>
			{categories.map((category) => {
				const { id, title, subCategories = [] } = category;
				const isExpanded = expandedCategories[id];
				// category is selected only if all subcategories are selected or providerCategories has a category with this id and no subcategories
				const isSelectedCategory = subCategories.length
					? subCategories.every((s) => providerCategories.some((pc) => pc.subcategoryId === s.id))
					: providerCategories.some((pc) => pc.categoryId === id && !pc.subcategoryId);
				return (
					<Stack key={id} spacing={1} direction="column" justifyContent="start">
						<Stack direction="row" alignItems="center" spacing={1}>
							<Chip
								sx={{
									width: "100%",
								}}
								color="secondary"
								variant={isSelectedCategory ? "filled" : "outlined"}
								onClick={() => handleToggle(category, "category", !isSelectedCategory)}
								label={title}
							/>
							{/* {subCategories.length > 0 && ( */}
							<IconButton disabled={!subCategories.length} size="small" onClick={() => toggleExpand(id)}>
								{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
							</IconButton>
							{/* )} */}
						</Stack>
						<Collapse in={isExpanded}>
							<Stack flexWrap="wrap" gap={1} direction="row">
								{subCategories.map((subcategory) => {
									const isSelectedSubcategory = providerCategories.some((pc) => pc.subcategoryId === subcategory.id);
									return (
										<Chip
											key={subcategory.id}
											color="primary"
											variant={isSelectedSubcategory ? "filled" : "outlined"}
											onClick={() => handleToggle(subcategory, "subcategory", !isSelectedSubcategory)}
											label={subcategory.title}
										/>
									);
								})}
							</Stack>
						</Collapse>
					</Stack>
				);
			})}
		</Stack>
	);
}

const GQLgetCategories = gql`
	query ($lang: Languaje) {
		categories {
			id
			title: titleLng(lang: $lang)
			subCategories {
				categoryId
				id
				title: titleLng(lang: $lang)
			}
		}
	}
`;
