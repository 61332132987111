import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale'
import { Box, Avatar, Card, Typography, Stack, } from '@mui/material';
import { getInitials } from '../../../helpers/name';
import { CustomDataGrid, CustomDateRangePicker } from '../../../components';
import { getAllUsers } from '../GQLQueries';
import { useLazyQuery } from '@apollo/client';
import customFilterPanelForTable from '../../../components/customFilterPanelForTable';
import { selectCompleteDate } from '../../../redux/layout';
import { useSelector } from 'react-redux';
import SendMailAction from './SendMailAction';
import SendPushAction from './SendPushAction';
import DeleteConfirmation from './DeleteConfirmation';
import EditForm from './EditForm';
import SendSMSAction from './SendSMSAction';
import { WhatsappActionFactory } from '../../../components/SendWhatsappAction';

export const CustomerListResults = (props) => {
  const [dateFrom, dateTo] = useSelector(selectCompleteDate);

  const [runQuery, { data, loading }] = useLazyQuery(getAllUsers, { variables: { dateFrom, dateTo }, fetchPolicy: "network-only" });

  const rows = data?.getAllUsers || []

  const loadData = () => {
    if (dateFrom && dateTo) {
      runQuery({ variables: { dateFrom, dateTo } })
    }
  }
  useEffect(loadData, [dateFrom, dateTo, runQuery])

  const avatarRenderer = (row) => {
    return <Box sx={{ alignItems: 'center', display: 'flex' }}>
      <Avatar src={row.profilePicture} sx={{ mr: 2 }}>{getInitials(`${row.firstName} ${row.lastName}`)}</Avatar>
      <Typography color="textPrimary" variant="body1">{row.firstName} {row.lastName}</Typography>
    </Box>
  }

  const dateRenderer = row => format(new Date(Number(row.createdAt)), 'dd/MM/yyyy HH:mm', { locale: es })
  const dateStringValue = row => format(new Date(Number(row.createdAt)), "yyyy-MM-dd'T'HH:mm:ss.mmm'Z'", { locale: es })

  return (<>
    <Stack direction='row' justifyContent='space-between'>
      <Typography sx={{ m: 1 }} variant="h4">Reporte de usuarios</Typography>
      <CustomDateRangePicker />
    </Stack>
    <Card {...props}>
      <Box sx={{ minWidth: 1050 }}>
        <CustomDataGrid
          initialFilters={[]}
          reload={loadData}
          loading={loading}
          filterPanel={customFilterPanelForTable}
          // selectedActions={sendNotificationsForTable}

          actions={[SendMailAction, WhatsappActionFactory(), SendSMSAction, SendPushAction, EditForm, DeleteConfirmation]}
          hideFooterSelectedRowCount
          checkboxSelection
          rows={rows}
          columns={[
            { id: "id", label: "ID", type: "number", hide: true },
            { id: "firstName", label: "Nombre", customValue: row => `${row.firstName} ${row.lastName}`, customRender: avatarRenderer },
            { id: "createdAt", label: "Fecha de creación", type: "date", customValue: dateStringValue, customRender: dateRenderer },
            { id: "createdTasksCount", label: "Tareas creadas", type: "number" },
            { id: "receibedBudgets", label: "Presupuestos Recibidos", type: "number" },
            { id: "userConversationsCount", label: "Conversaciones", type: "number" },
            { id: "receibedBudgetsSeen", label: "Presupuestos Vistos", type: "number" },
            { id: "deletedTasksCount", label: "Tareas Eliminadas", type: "number" },
            { id: "hiredTasksCount", label: "Tareas Contratadas", type: "number" },
            { id: "qualifiedTasksCount", label: "Tareas Calificadas", type: "number" },
            { id: "totalPaidTasks", label: "Total pagado", type: "number", customRender: row => new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(row.totalPaidTasks) },
            { id: "email", label: "Email" },
            { id: "phone", label: "Telefono" },
            { id: "address.locality", label: "Localidad" },
            { id: 'acquisitionMethod', label: 'Metodo de adquisicion' },
            { id: "deletedAt", label: "Eliminado", type: "date", customValue: row => row.deletedAt ? format(new Date(row.deletedAt), "yyyy-MM-dd'T'HH:mm:ss.mmm'Z'", { locale: es }) : null, customRender: row => row.deletedAt ? format(new Date(row.deletedAt), 'dd/MM/yyyy HH:mm', { locale: es }) : 'N/A' },
          ]} />
      </Box>
    </Card>
  </>);
};