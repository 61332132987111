import ModalFrame from "../../../components/form/ModalFrame";
import { Edit as EditIcon } from "@mui/icons-material";

import { useDisclosure } from "react-use-disclosure";
import { Stack, Tooltip, IconButton, Typography, Button, Box } from "@mui/material";
import { Form } from "react-final-form";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { MUTATION_editUser, QUERY_getUser, MUTATION_deleteImage } from "../../usersList/GQLQueries";
import FormInput from "../../../components/form/FormInput";
import { useContext, useEffect } from "react";
import MediaGallery from "../../../components/MediaGallery";
import FormDateInput from "../../../components/form/FormDateInput";
import { setSnackBar } from "../../../redux/snackbar";
import { useDispatch } from "react-redux";
import { TableContext } from "../../../components/customDataGrid";
import FormSwitch from "../../../components/form/FormSwitch";
import TableIconButton from "../../../components/TableIconButton";

export default function EditForm({ userId }) {
	const dispatch = useDispatch();
	const { isOpen, open, close } = useDisclosure(false);
	const { reload } = useContext(TableContext);

	const { data: { getUser: user } = {}, refetch } = useQuery(QUERY_getUser, { fetchPolicy: "network-only", variables: { id: userId } });
	const [deleteImage] = useMutation(MUTATION_deleteImage, {
		fetchPolicy: "network-only",
	});

	const [Edit] = useMutation(MUTATION_editUser, { fetchPolicy: "network-only" });

	const validate = (values) => {
		const errors = {};
		return errors;
	};

	let images = [];
	if (user?.profilePicture) images.push({ id: user?.id, url: user?.profilePicture, type: "profilePicture" });
	return (
		<Form
			initialValues={{
				id: user?.id,
				firstName: user?.firstName,
				lastName: user?.lastName,
				email: user?.email,
				phone: user?.phone,
				deletedAt: user?.deletedAt,
				disabled: user?.disabled,
			}}
			onSubmit={async (values, actions) => {
				const { data } = await Edit({ variables: values });

				if (data?.editUser)
					dispatch(
						setSnackBar({
							text: "Registro editado correctamente",
							severity: "success",
						})
					);
				else
					dispatch(
						setSnackBar({
							text: "Error al editar el registro",
							severity: "error",
						})
					);
				reload();
				close();
				actions.reset(values);
			}}
			validate={validate}
			render={({ handleSubmit, values }) => (
				// <ModalFrame onSubmit={handleSubmit} {...{ isOpen, close }} title={`Editar perfil de ${user?.firstName} ${user?.lastName}`} buttonTexts={["Guardar", "Cancelar"]}>
				<Stack spacing={4}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							padding: "10px",
						}}>
						<Button variant="contained" color="primary" onClick={handleSubmit}>
							Guardar
						</Button>
					</Box>
					<Stack gap={2} justifyContent="space-between">
						<Stack spacing={4} width="100%">
							<Stack spacing={4} direction="row" justifyContent="space-between">
								<FormInput style={{ width: "100%" }} name="firstName" label="Nombres" type="text" autoFocus />
								<FormInput style={{ width: "100%" }} name="lastName" label="Apellido" type="text" />
							</Stack>
							<Stack spacing={4} direction="row" justifyContent="space-between">
								<FormInput style={{ marginTop: "auto", width: "100%" }} name="email" label="Email" type="text" />
								<FormSwitch name="disabled" label="Deshabilitado" />
							</Stack>
							<Stack spacing={4} direction="row" justifyContent="space-between">
								<FormInput style={{ marginTop: "auto", width: "100%" }} name="phone" label="Telefono" type="text" />
								<Stack spacing={0}>
									<Typography variant="h6" style={{ margin: "auto" }}>
										Fecha de eliminacion
									</Typography>
									<FormDateInput name="deletedAt" type="date" format={"dd/MM/yyyy"} cleareable /* disableFuture disablePast */ />
								</Stack>
							</Stack>
						</Stack>
						<Stack justifyContent={"center"} width="100%">
							<MediaGallery
								handleDelete={async (variables) => {
									const { data } = await deleteImage({ variables });
									if (data?.deleteMedia) dispatch(setSnackBar({ text: "Imagen eliminada", severity: "success" }));
									else dispatch(setSnackBar({ text: "Error al eliminar la imagen", severity: "error" }));
									refetch();
								}}
								items={images}
							/>
						</Stack>
					</Stack>
				</Stack>
			)}
		/>
	);
}
