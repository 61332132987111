import { Stack } from "@mui/material";
import { forwardRef, useImperativeHandle } from "react";
import { Form } from "react-final-form";
import { useDisclosure } from "react-use-disclosure";
import FormAcquisitionMethodSelect from "../../../components/form/FormAcquisitionMethodSelect";
import FormCategorySelect from "../../../components/form/FormCategorySelect";
import FormInput from "../../../components/form/FormInput";
import FormSubcategorySelect from "../../../components/form/FormSubcategorySelect";
import ImgUploader from "../../../components/form/ImageUploader";
import ModalFrame from "../../../components/form/ModalFrame";

export default forwardRef(function BundleTaskForm(props, ref) {
	const { isOpen, open, close } = useDisclosure(false);
	useImperativeHandle(ref, () => ({
		open,
		close,
	}));
	const formRender = ({ handleSubmit, form, errors, submitting, pristine, values }) => {
		const { image, categoryId } = values;
		return (
			<ModalFrame onSubmit={handleSubmit} {...{ isOpen, close }} title={props.title} buttonTexts={["Guardar", "Cancelar"]}>
				<Stack spacing={4}>
					<Stack style={{ width: "100%" }} alignContent={"center"} alignItems={"center"} justifyContent={"center"} spacing={2}>
						<Stack direction="row" spacing={2} width="100%">
							<FormInput label="Título" name="title" required autoFocus />
							<FormInput label="Precio" name="price" type="number" required fullWidth={false} />
							<FormInput label="% de Ganancia" name="revenueRate" type="number" required fullWidth={false} />
						</Stack>
						<Stack direction="row" spacing={2} width="100%">
							<FormInput label="Descripcion" name="description" required />
							<FormInput label="Precio Cobrado" name="totalPrice" type="number" required fullWidth={false} />
						</Stack>
						<Stack direction="row" spacing={2} width="100%">
							<FormCategorySelect name="categoryId" label="Categoria" />
							<FormSubcategorySelect name="subcategoryId" label="Subcategoria" categoryId={categoryId} />
						</Stack>
						<FormAcquisitionMethodSelect name="acquisitionMethodId" label="Metodo de adquisicion" />
						<FormInput style={{ width: "100%" }} name="image" label="Imagen" type="text" />
						{!image && <ImgUploader name="image" />}
					</Stack>
					{!!image && <img src={image} alt="banner" style={{ height: "200px", width: "auto", objectFit: "contain" }} />}
					<Stack direction="row" spacing={2}>
						<FormInput label="TyC Usuario" name="userTerms" multiline rows={8} />
						<FormInput label="TyC Expertos" name="providerTerms" multiline rows={8} />
					</Stack>
				</Stack>
			</ModalFrame>
		);
	};

	return (
		<Form
			onSubmit={(...args) => {
				props.onSubmit(...args);
				close();
			}}
			initialValues={{
				title: props.initialValues?.taskData?.title,
				description: props.initialValues?.taskData?.description,
				categoryId: props.initialValues?.taskData?.categoryId,
				subcategoryId: props.initialValues?.taskData?.subcategoryId,
				price: props.initialValues?.taskData?.price,
				totalPrice: props.initialValues?.taskData?.totalPrice,
				userTerms: props.initialValues?.taskData?.userTerms,
				providerTerms: props.initialValues?.taskData?.providerTerms,
				acquisitionMethodId: props.initialValues?.taskData?.acquisitionMethodId,
				image: props.initialValues?.taskData?.media?.[0]?.uri,
				revenueRate: props.initialValues?.taskData?.revenueRate * 100,
			}}
			render={formRender}
		/>
	);
});
