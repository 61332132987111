import { gql } from "@apollo/client"

export const getAllProviders = gql`query($dateFrom: String!, $dateTo: String!) {
    getAllProviders(dateFrom: $dateFrom, dateTo: $dateTo) {
        id
        firstName
        lastName
        profilePicture
        email
        phone
        deleted
        createdAt
        viewedTasks
        hiddenTasks
        sentBudgets
        hiredBudgets
        qualificationsCount
        totalCommissions
        paidCommissions
        totalBudgetedAmount
        totalTasksPaid
        verified
        adminNotes
        acquisitionMethod
        taskActions { action }
        tasks {
            id
            deletedAt
            status
            paid
            budgets { id amount userSeen }
        }
        userConversations { id }
        professionalProfile {
            id
            isProvider
            experience
            blockDate
            professionalCategories {
                category { titleLng }
                subcategory { titleLng }
            }
            
            profileChips {
                id
                title
            }
        }
        address { locality inline }
        taskActivities { action }
        deletedAt
    }
}`

export const sendNotificationsMutation = gql`mutation sendNotifications($ids: [Int]!, $title: String!, $subtitle: String!) {
    sendCustomNotification(ids: $ids, title: $title, subtitle: $subtitle) 
}`

export const edit = gql`mutation edit(
    $id: Int!,
    $professionalProfile: GQLProfessionalProfileInput!
    $firstName: String,
    $lastName: String,
    $email: String,
    $phone: String,
    $deletedAt: DateTime
    $verified: Boolean
    $disabled: Boolean
    $adminNotes: String
    ) {
    editUser(
        id: $id,
        professionalProfile: $professionalProfile
        firstName: $firstName,
        lastName: $lastName,
        email: $email,
        phone: $phone,
        verified: $verified
        deletedAt: $deletedAt
        disabled: $disabled
        adminNotes: $adminNotes
        ){
        id
    }
}`

export const QUERY_getProvider = gql`query getProfessionalProfile($id: Int!) {
        getProvider(id: $id) {
            id
            firstName
            lastName
            email
            phone
            profilePicture
            deleted
            createdAt
            deletedAt
            verified
            disabled
            adminNotes
            tasks { id }
            professionalProfileId
            professionalProfile {
                id experience idFront idBack blockDate
                professionalCategories {
                    id
                    categoryId
                    subcategoryId
                }
                certs: professionalMedia(type: certs) {
                    id
                    uri
                    type
                }
                works: professionalMedia(type: works) {
                    id
                    uri
                    type
                }
                profileChips {
                    id
                }
            }
        }
}`

export const MUTATION_deleteImage = gql`mutation deleteImage($professionalMediaIds: [Int], $profilePictureIds: [Int]) {
    deleteMedia(professionalMediaIds: $professionalMediaIds, userIds: $profilePictureIds)
}`

export const MUTATION_deleteUser = gql`mutation deleteUsers($ids: [Int]!) {
    deleteUsers(ids: $ids)
}`