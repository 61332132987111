import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, Switch } from '@mui/material';
import { ViewColumn } from '@mui/icons-material';
import { TableContext } from '..';
import TableIconButton from '../../TableIconButton';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 12,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default () => {

    const { setColumns, columns } = React.useContext(TableContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <TableIconButton
                onClick={handleClick}
                icon={<ViewColumn />}
                tooltip="Columnas"
                aria-controls={open ? 'columns-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined} />
            <StyledMenu
                id="columns-customized-menu"
                MenuListProps={{ 'aria-labelledby': 'demo-customized-button' }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                {columns.map((col, index) => <MenuItem key={index} style={{ width: "100%", display: "flex", justifyContent: "space-between" }} disableRipple>
                    {col.label} <Switch checked={!col.hide} onChange={(event) => {
                        const columnsCP = [...columns]
                        columnsCP[index].hide = !event.target.checked
                        setColumns(columnsCP)
                    }} style={{ display: 'flex', alignItems: "right" }} />
                </MenuItem>)}
            </StyledMenu>
        </div>
    );
}
