import { Chip } from "@mui/material";
import React from "react";

export default ({ label, onClick, onDelete, color = "primary" }) => {

    return <Chip
        label={label}
        variant="outlined"
        color={color}
        onClick={onClick}
        onDelete={onDelete}
    />
}