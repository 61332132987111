import { styled } from '@mui/material/styles';
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { selectToken } from "../../redux/session";
import AppBar from "./components/AppBar";
import Drawer from "./components/Drawer";

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    paddingTop: 64,
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
        paddingLeft: 280
    }
}));

export default function AppScreen({ routes }) {
    const sessionToken = useSelector(selectToken)
    const drawerWidth = 350
    return sessionToken ? (
        <div style={{
            overflow: 'hidden',
            overflowY: 'auto',
            // marginTop: 65,
            height: 'calc(100vh - 65px)',
        }}>
            <div style={{ paddingLeft: drawerWidth}}>
                <AppBar />
                <div style={{ padding: '20px' }}>
                    <Outlet />
                </div>
                <Drawer width={drawerWidth} routes={routes} />
            </div>
        </div>)
        : <Navigate to="/signIn" />
} 
