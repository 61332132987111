import { Add as AddIcon } from "@mui/icons-material";
import { useRef } from "react";
import TableIconButton from "../../../components/TableIconButton";
import EditForm from "./EditForm";

export default function CreateOption(props) {
	const formRef = useRef(null);
	return (
		<>
			<TableIconButton icon={<AddIcon />} onClick={formRef?.current?.open} tooltip="Nuevo Registro" />
			<EditForm ref={formRef} {...props} selectedRows={[]} />
		</>
	);
}
