import { gql, useMutation } from "@apollo/client";
import { AssignmentReturn } from "@mui/icons-material";
import { useContext } from "react";
import { useDisclosure } from "react-use-disclosure";
import TableIconButton from "../../../components/TableIconButton";
import { TableContext } from "../../../components/customDataGrid";
import ModalFrame from "../../../components/form/ModalFrame";
import FormInput from "../../../components/form/FormInput";
import { Form } from "react-final-form";

const GQLUnassign = gql`
	mutation unassignPackage($packageId: Int!) {
		pkg: unassignPackage(packageId: $packageId)
	}
`;

export default function UnassignAction({ selectedRows = [] }) {
	const { isOpen, open, close } = useDisclosure();
	const { reload } = useContext(TableContext);

	const [Unassign] = useMutation(GQLUnassign, { fetchPolicy: "network-only" });

	const selectedPkg = selectedRows[0];
	const handleSubmit = async () => {
		try {
			await Unassign({ variables: { packageId: selectedPkg.id } });
			await reload();
			close();
		} catch (error) {
			console.error(error);
			await reload();
			close();
		}
	};

	return (
		<>
			<TableIconButton onClick={open} disabled={selectedRows.length !== 1 || selectedRows[0]?.status !== "HIRED"} icon={<AssignmentReturn />} tooltip={`Desasignar`} />
			<Form
				initialValues={{ id: selectedPkg?.id, adminNotes: selectedPkg?.adminNotes }}
				onSubmit={handleSubmit}
				// validate={validate}
				render={({ handleSubmit, values }) => (
					<ModalFrame
						onSubmit={handleSubmit}
						{...{ isOpen, close }}
						title={`Estas seguro que deseas desasignar ${selectedRows.length === 1 ? `el packete ${selectedRows[0]?.title}` : `los ${selectedRows.length} paquetes seleccionados`}?`}></ModalFrame>
				)}
			/>
		</>
	);
}
