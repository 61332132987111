import { gql } from "@apollo/client"

export const getAllBudgets = gql`query($dateFrom: String!, $dateTo: String!) {
    getAllBudgets(dateFrom: $dateFrom, dateTo: $dateTo ) {
        id
        task {
            id
            title
            status
            category {
                titleLng
            }
            subcategory {
                titleLng
            }
            owner {
                id
                firstName
                lastName
                profilePicture
            }
        }
        warrantyMonths
        amount
        availabilities
        comment
        createdAt
        hired
        qualification {
            id
        }
        owner {
            id
            firstName
            lastName
            profilePicture
        }
    }
}`


export const sendNotificationsMutation = gql`mutation sendNotifications($ids: [Int]!, $title: String!, $subtitle: String!) {
    sendCustomNotification(ids: $ids, title: $title, subtitle: $subtitle) 
}`