
import ModalFrame from '../../../components/form/ModalFrame';
import { useDisclosure } from 'react-use-disclosure';
import { Tooltip, IconButton } from '@mui/material';
import { Delete as TrashIcon } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { MUTATION_deleteUser } from '../GQLQueries';
import { TableContext } from "../../../components/customDataGrid";
import { useContext } from 'react';
import TableIconButton from '../../../components/TableIconButton';


export default function DeleteConfirmation({ selectedRows = [] }) {
    const { isOpen, open, close } = useDisclosure()
    const { reload } = useContext(TableContext);

    const [Delete] = useMutation(MUTATION_deleteUser, { fetchPolicy: "network-only" });

    const handleSubmit = async () => {
        await Delete({ variables: { ids: selectedRows.map(i => i.id) } })
        await reload();
        close()
    }

    return (<>
        <TableIconButton onClick={open} disabled={!selectedRows.length} icon={<TrashIcon />} tooltip={`Eliminar Registro${selectedRows.length === 1 ? "" : "s"}`} />
        <ModalFrame onSubmit={handleSubmit} {...{ isOpen, close }} title={
            `Estas seguro que deseas eliminar ${selectedRows.length === 1 ? `al usuario ${selectedRows[0].firstName} ${selectedRows[0].lastName}` : `a los ${selectedRows.length} usuarios seleccionados`}?`
        } >
        </ModalFrame>
    </>
    )
}
