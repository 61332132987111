import { Edit as EditIcon } from "@mui/icons-material";
import { useLazyQuery } from "@apollo/client";
import { useRef } from "react";
import TableIconButton from "../../../components/TableIconButton";
import { getAcquisitionMethod } from "../GQLQueries";
import AcquisitionMethodForm from "./AcquisitionMethodForm";

export default function EditForm({ selectedRows = [], reload }) {
	const formRef = useRef(null);

	const [GetAcquisitionMethod, { data: { method } = {} }] = useLazyQuery(getAcquisitionMethod, { fetchPolicy: "network-only" });

	const onClick = async () => {
		formRef?.current?.open();
		await GetAcquisitionMethod({ variables: { id: selectedRows[0]?.id } });
	};

	return (
		<>
			<TableIconButton icon={<EditIcon />} onClick={onClick} disabled={selectedRows.length !== 1} tooltip="Editar" />
			<AcquisitionMethodForm ref={formRef} initialValues={method} afterSubmit={reload} />
		</>
	);
}
