import { gql } from "@apollo/client"

export const QUERY_getAllMedia = gql`query getAllMedia($dateFrom: String!, $dateTo: String!) {
    media: getAllMedia(types: [professionalMedia,profilePicture,taskMedia], dateFrom: $dateFrom, dateTo:$dateTo ) {
        id
        type
        url
        thumbnail
        createdAt
        updatedAt
    }
}`

export const MUTATION_deleteMedia = gql`mutation deleteImage($professionalMediaIds: [Int], $profilePictureIds: [Int], $taskMediaIds: [Int], $messageIds: [Int]) {
    deleteMedia(professionalMediaIds: $professionalMediaIds, userIds: $profilePictureIds, taskMediaIds: $taskMediaIds, messageIds: $messageIds)
}`