import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
	tab: {
		background: "linear-gradient(90deg, #5048E570 0%, #828DF850 100%)",
		color: "white",
		fontSize: "12px",
		"&.Mui-selected": {
			background: "linear-gradient(90deg, #3832A0 0%, #828DF850 100%)",
			color: "white",
			fontWeight: "bold",
			fontSize: "16px",
		},
	},
}));
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
			style={{
				width: "100%",
			}}>
			{value === index && (
				<Box sx={{ p: 3 }}>
					{children}
					{/* <Typography>{children}</Typography> */}
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		"aria-controls": `vertical-tabpanel-${index}`,
	};
}

export default function SideTabs({ tabs = [] }) {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const classes = useStyles();
	const tabClasses = { root: classes.tab };

	return (
		<div
			style={{
				flexDirection: "row",
				display: "flex",
				height: "90vh",
			}}>
			<Tabs
				orientation="vertical"
				variant="standard"
				indicatorColor="primary"
				value={value}
				onChange={handleChange}
				aria-label="Vertical tabs example"
				sx={{
					borderTopLeftRadius: 10,
					borderBottomLeftRadius: 10,
					width: "20%",
					background: "linear-gradient(90deg, #5048E570 0%, #828DF850 100%)",
				}}>
				{tabs.map((tab, index) => {
					return <Tab classes={tabClasses} label={tab.title} {...a11yProps(index)} />;
				})}
			</Tabs>
			<Box
				sx={{
					borderTop: 2,
					borderRight: 2,
					borderBottom: 2,
					borderTopRightRadius: 10,
					borderBottomRightRadius: 10,
					borderColor: "#828DF850",
					// backgroundColor: "red",
				}}>
				{tabs.map((tab, index) => {
					return (
						<TabPanel value={value} index={index}>
							<div style={styles.panelContainer}>{tab.component}</div>
						</TabPanel>
					);
				})}
			</Box>
		</div>
	);
}

const styles = {
	panelContainer: {
		height: "85vh",
		overflowX: "hidden",
		overflowY: "scroll",
		flexDirection: "column",
		display: "flex",
		border: 0,
		width: "calc(75vw - 220px)",
	},
};
