import { Close } from "@mui/icons-material";
import { TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";

export default function AreaEditor({ area: initialArea, onChange, deleteArea }) {
	const [area, setArea] = useState(initialArea);

	useEffect(() => {
		const { lat, lon, radius, type, enabled } = initialArea;
		if (area.lat === lat && area.lon === lon && area.radius === radius && area.type === type && area.enabled === enabled) return;

		setArea(initialArea);
	}, [initialArea]);

	useEffect(() => {
		onChange(area);
	}, [area]);
	return (
		<div
			onMouseOver={(e) => {
				const isOver = e.type === "mouseover";
				setArea((a) => ({ ...a, over: isOver }));
			}}
			onMouseLeave={(e) => {
				const isOver = e.type === "mouseover";
				setArea((a) => ({ ...a, over: isOver }));
			}}
			style={{
				display: "flex",
				gap: 10,
				padding: 10,
				border: "2px solid " + (area.type === "provider" ? "#FF0000" : "#0000FF"),
				borderRadius: 10,
				alignItems: "center",
				justifyContent: "space-around",
				width: "calc(50% - 15px)",
			}}>
			<Checkbox
				// set color to FF0000
				color={area.type === "provider" ? "error" : "primary"}
				checked={area.enabled}
				onChange={(e) => {
					console.log(e);
					setArea((a) => ({ ...a, enabled: !a.enabled }));
				}}
			/>
			<TextField label="Latitude" value={area.lat} onChange={(e) => setArea((a) => ({ ...a, lat: isNaN(parseFloat(e.target.value)) ? e.target.value : parseFloat(e.target.value) }))} />
			<TextField label="Longitude" value={area.lon} onChange={(e) => setArea((a) => ({ ...a, lon: isNaN(parseFloat(e.target.value)) ? e.target.value : parseFloat(e.target.value) }))} />
			<TextField label="Radius" value={area.radius} onChange={(e) => setArea((a) => ({ ...a, radius: isNaN(parseFloat(e.target.value)) ? e.target.value : parseFloat(e.target.value) }))} />
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "center",
				}}>
				<Checkbox
					color={area.type === "provider" ? "error" : "primary"}
					checked={area.type === "provider"}
					onChange={(e) => setArea((a) => ({ ...a, type: e.target.checked ? "provider" : "task" }))}
				/>
				<span>Area de experto</span>
			</div>
			<Close
				onClick={deleteArea}
				style={{
					backgroundColor: "#00000030",
					borderRadius: 50,
					cursor: "pointer",
					color: "red",
					fontSize: 30,
					right: "50%",
				}}
			/>
		</div>
	);
}
